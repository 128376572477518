import { createContext, Reducer, useContext, useMemo } from "react";
import Stripe from "stripe";
import { useReducerAsync, AsyncActionHandlers } from "use-reducer-async";
import {
  Shop,
  Schedule,
  Reservation,
  Lesson,
  MasterTicket,
  PurchasedTicket,
  CreatePreReservationInput,
  CreateTicketPaymentIntentInput,
  CreateSubscriptionInput,
  Customer,
  ReserveUsingTicketInput,
  CreateSinglePaymentIntentInput,
} from "../DAL/API";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";
import { MasterTicketRepository } from "../DAL/repositories/MasterTicketRepository";
import { ReservationRepository } from "../DAL/repositories/ReservationRepository";
import { ScheduleRepository } from "../DAL/repositories/ScheduleRepository";
import { ShopRepository } from "../DAL/repositories/ShopRepository";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import {
  ExpiredReservationList,
  PurchasingSingleReserveInput,
  PurchasingTicketReserveInput,
  ReservationList,
  SubscribingPreReserveInput,
  UserAccount,
} from "../DAL/schema";
import { PaymentIntent } from "../DAL/stripe/PaymentIntent";
import { Subscription } from "../DAL/stripe/Subscription";

type Transaction = {
  status: TransactionStatus;
  message: string | null | undefined;
};
export enum TransactionStatus {
  entry,
  processing,
  succeed,
  error,
}
export type PaymentAppTransaction = Transaction & {
  paymentIntentId: string | null | undefined;
};
export type ReservationAppTransaction = Transaction & {
  paymentIntentId: string | null | undefined;
};

export type StripeCustomerObject = {
  stripeAccount: Stripe.Customer;
  stripePaymentMethods: Stripe.PaymentMethod[] | null;
};

type StoreReservation = {
  active?: ReservationList;
  expired?: ExpiredReservationList;
};

export type STORE = {
  shop: Shop | null;
  customer: UserAccount | null;
  schedules: Schedule[] | null;
  reservations: StoreReservation | null;
  lessons: Lesson[] | null;
  tickets: MasterTicket[] | null;
  lineId: string | null;
  storeId: string | null;
  reservationTransaction: ReservationAppTransaction | null;
  paymentTransaction: PaymentAppTransaction | null;
};

const initialStore: STORE = {
  shop: null,
  customer: null,
  schedules: null,
  reservations: null,
  lessons: null,
  tickets: null,
  lineId: null,
  storeId: null,
  reservationTransaction: null,
  paymentTransaction: null,
};

export type ASYNCACTION =
  | {
      type: "START_FETCH_SERVICE_CUSTOMER";
      payload: {
        input: {
          shopId: string;
          customerId: string;
        };
        lineAccessToken: string | null;
      };
    }
  | {
      type: "START_FETCH_STRIPE_CUSTOMER";
      payload: {
        input: {
          shopId: string;
          customerId: string;
        };
        lineAccessToken: string | null;
      };
    }
  | {
      type: "APP_DATA_FETCHING";
      payload: {
        shopId: string;
        lineAccessToken: string | null;
      };
    }
  | {
      type: "RESERVATION_FETCHING";
      payload: {
        shopId: string;
        customerId: string;
        lineAccessToken: string | null;
        nextToken?: string
      };
    }
  | {
      type: "EXPIRED_RESERVATION_FETCHING";
      payload: {
        shopId: string;
        customerId: string;
        lineAccessToken: string | null;
        nextToken?: string
      };
    }
  | {
      type: "START_COUPON_TRANSACTION";
      payload: {
        input: CreateTicketPaymentIntentInput;
        lineAccessToken: string | null;
      };
    }
  | {
      type: "START_SUBSCRIPTION_TRANSACTION";
      payload: {
        input: CreateSubscriptionInput;
        lineAccessToken: string | null;
      };
    }
  | {
      type: "START_PRE_RESERVATION_USING_PURCHASED_TICKET";
      payload: {
        preReservationInput: CreatePreReservationInput;
        reserveUsingTicketInput: ReserveUsingTicketInput;
        lineAccessToken: string | null;
      };
    }
  | {
      type: "START_PRE_RESERVATION_SUBSCRIBING";
      payload: {
        input: SubscribingPreReserveInput;
        purchasedTicketIndex: number;
        lineAccessToken: string | null;
      };
    }
  | {
      type: "START_PRE_RESERVATION_PURCHASING_TICKET";
      payload: {
        input: PurchasingTicketReserveInput;
        purchasedTicketIndex: number;
        lineAccessToken: string | null;
      };
    }
  | {
      type: "START_PRE_RESERVATION_SINGLE_PURCHASING";
      payload: {
        input: PurchasingSingleReserveInput;
        lineAccessToken: string | null;
      };
    };

export type ACTIONTYPE =
  | { type: ResourceOperationEvent.FETCH_SHOP; payload: Shop | null }
  | { type: ResourceOperationEvent.FETCH_CUSTOMER; payload: UserAccount }
  | { type: ResourceOperationEvent.FETCH_LESSON; payload: Lesson[] }
  | { type: ResourceOperationEvent.FETCH_SCHEDULE; payload: Schedule[] }
  | { type: ResourceOperationEvent.FETCH_RESERVATION; payload: ReservationList }
  | { type: ResourceOperationEvent.FETCH_EXPIRED_RESERVATION; payload: ExpiredReservationList }
  | { type: ResourceOperationEvent.FETCH_TICKET; payload: MasterTicket[] }
  | { type: ResourceOperationEvent.GET_SHOP; payload: Shop }
  | { type: ResourceOperationEvent.GET_CUSTOMER; payload: UserAccount }
  | { type: ResourceOperationEvent.GET_LESSON; payload: Lesson }
  | { type: ResourceOperationEvent.GET_SCHEDULE; payload: Schedule }
  | { type: ResourceOperationEvent.GET_RESERVATION; payload: Reservation }
  | { type: ResourceOperationEvent.GET_TICKET; payload: MasterTicket }
  | { type: ResourceOperationEvent.CREATE_CUSTOMER; payload: UserAccount }
  | { type: ResourceOperationEvent.CREATE_LESSON; payload: Lesson }
  | { type: ResourceOperationEvent.CREATE_SCHEDULE; payload: Schedule }
  | { type: ResourceOperationEvent.CREATE_RESERVATION; payload: Reservation }
  | { type: ResourceOperationEvent.CREATE_TICKET; payload: MasterTicket }
  | { type: ResourceOperationEvent.UPDATE_SHOP; payload: Shop }
  | { type: ResourceOperationEvent.UPDATE_CUSTOMER; payload: Customer }
  | {
      type: ResourceOperationEvent.UPDATE_STRIPE_CUSTOMER;
      payload: StripeCustomerObject;
    }
  | { type: ResourceOperationEvent.UPDATE_LESSON; payload: Lesson }
  | { type: ResourceOperationEvent.UPDATE_SCHEDULE; payload: Schedule }
  | { type: ResourceOperationEvent.UPDATE_RESERVATION; payload: Reservation }
  | { type: ResourceOperationEvent.UPDATE_TICKET; payload: MasterTicket }
  | { type: ResourceOperationEvent.DELETE_CUSTOMER; payload: UserAccount }
  | { type: ResourceOperationEvent.DELETE_LESSON; payload: Lesson }
  | { type: ResourceOperationEvent.DELETE_SCHEDULE; payload: Schedule }
  | { type: ResourceOperationEvent.DELETE_RESERVATION; payload: Reservation }
  | { type: ResourceOperationEvent.DELETE_TICKET; payload: MasterTicket }
  | { type: ResourceOperationEvent.FETCH_LIFF; payload: string }
  | {
      type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION;
      payload: PaymentAppTransaction;
    }
  | {
      type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION;
      payload: ReservationAppTransaction;
    }
  | { type: ResourceOperationEvent.PURCHASED_TICKET; payload: PurchasedTicket };

const ApplicationStoreContext = createContext(
  {} as {
    store: STORE;
    dispatch: React.Dispatch<ACTIONTYPE | ASYNCACTION>;
  }
);

const reducer = (store: STORE, action: ACTIONTYPE) => {
  switch (action.type) {
    case ResourceOperationEvent.GET_SHOP:
      const {schedules, tickets, ...shop } = action.payload;
      return { ...store, shop: shop,  schedules: schedules?.items.filter(s=>s!==null) as Schedule[], tickets: tickets?.items.filter(t=>t!==null) as MasterTicket[] };
    case ResourceOperationEvent.FETCH_CUSTOMER:
      return {
        ...store,
        customer: action.payload
      };
    case ResourceOperationEvent.UPDATE_CUSTOMER:
      return {
        ...store,
        customer: {
          ...store.customer,
          serviceAccount: action.payload,
        } as UserAccount,
      };
    case ResourceOperationEvent.UPDATE_STRIPE_CUSTOMER:
      return {
        ...store,
        customer: {
          ...store.customer,
          stripeAccount: action.payload.stripeAccount,
          stripePaymentMethods: action.payload.stripePaymentMethods,
        } as UserAccount,
      };
    case ResourceOperationEvent.CREATE_RESERVATION:
      return {
        ...store,
        reservations: {
          ...store.reservations,
          active:{
            data:
            store.reservations !== null
              ? [...store.reservations?.active?.data!, action.payload]
              : [],
          }
        },
      };
    case ResourceOperationEvent.FETCH_LESSON:
      return { ...store, lessons: action.payload };
    case ResourceOperationEvent.FETCH_RESERVATION:

      return {
        ...store,
        reservations: {
          ...store.reservations,
          active:{
            data:
            store.reservations !== null
              ? [...store.reservations?.active?.data??[], ...action.payload.data]
              : action.payload.data,
            nextToken: action.payload.nextToken,
          }
        },
      };
      case ResourceOperationEvent.FETCH_EXPIRED_RESERVATION:

        return {
          ...store,
          reservations: {
            ...store.reservations,
            expired:{
              data:
              store.reservations !== null
                ? [...store.reservations?.expired?.data??[], ...action.payload.data]
                : action.payload.data,
              nextToken: action.payload.nextToken,
            }
          },
        };
    case ResourceOperationEvent.FETCH_TICKET:
      return { ...store, tickets: action.payload };
    case ResourceOperationEvent.FETCH_SCHEDULE:
      return { ...store, schedules: action.payload };
    case ResourceOperationEvent.FETCH_LIFF:
      return { ...store, lineId: action.payload };
    case ResourceOperationEvent.SET_PAYMENT_TRANSACTION:
      return { ...store, paymentTransaction: action.payload };
    case ResourceOperationEvent.SET_RESERVATION_TRANSACTION:
      return { ...store, reservationTransaction: action.payload };
    case ResourceOperationEvent.PURCHASED_TICKET:
      const updatedCustomer = store.customer!;
      if (updatedCustomer.serviceAccount.purchasedTickets !== null) {
        updatedCustomer.serviceAccount.purchasedTickets!.push(action.payload);
      } else {
        updatedCustomer.serviceAccount.purchasedTickets = [action.payload];
      }
      return {
        ...store,
        customer: updatedCustomer,
        paymentTransaction: {
          status: TransactionStatus.succeed,
          paymentIntentId: action.payload.paymentIntentId,
          message: "支払いが正常に行われました。",
        },
      };
    default:
      return store;
  }
};

const asyncActionHandlers: AsyncActionHandlers<
  Reducer<STORE, ACTIONTYPE>,
  ASYNCACTION
> = {
  START_COUPON_TRANSACTION:
    ({ dispatch }) =>
    async (action) => {
      dispatch({
        type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
        payload: {
          status: TransactionStatus.processing,
          paymentIntentId: null,
          message: "しばらくこのままでお待ちください。",
        },
      });
      const stripePayment = PaymentIntent(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      try {
        await stripePayment
          .comfirmTicketPaymentIntent(action.payload.input)
          .then((result) => {
            if (result.isError) {
              dispatch({
                type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
                payload: {
                  status: TransactionStatus.error,
                  paymentIntentId: null,
                  message: result.errMessage,
                },
              });
            } else {
              dispatch({
                type: ResourceOperationEvent.PURCHASED_TICKET,
                payload: result.ticket!,
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
              payload: {
                status: TransactionStatus.error,
                paymentIntentId: null,
                message: err.message,
              },
            });
          });
      } catch (err) {
        dispatch({
          type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
          payload: {
            status: TransactionStatus.error,
            paymentIntentId: null,
            message: (err as Error).message,
          },
        });
      }
    },
  START_SUBSCRIPTION_TRANSACTION:
    ({ dispatch }) =>
    async (action) => {
      dispatch({
        type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
        payload: {
          status: TransactionStatus.processing,
          paymentIntentId: null,
          message: "しばらくこのままでお待ちください。",
        },
      });
      try {
        const stripeSubscriptionClient = Subscription(
          action.payload.input.shopId,
          action.payload.lineAccessToken
        );
        await stripeSubscriptionClient
          .createComfirmedSubscription(action.payload.input)
          .then((result) => {
            if (result.isError) {
              dispatch({
                type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
                payload: {
                  status: TransactionStatus.error,
                  paymentIntentId: null,
                  message: result.errMessage,
                },
              });
            } else {
              dispatch({
                type: ResourceOperationEvent.PURCHASED_TICKET,
                payload: result.ticket!,
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
              payload: {
                status: TransactionStatus.error,
                paymentIntentId: null,
                message: err.message,
              },
            });
          });
      } catch (err) {
        dispatch({
          type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
          payload: {
            status: TransactionStatus.error,
            paymentIntentId: null,
            message: (err as Error).message,
          },
        });
      }
    },
  APP_DATA_FETCHING:
    ({ dispatch }) =>
    async (action) => {
      /*
      const ticketClient = MasterTicketRepository(
        action.payload.shopId,
        action.payload.lineAccessToken
      );
  
      const scheduleClient = ScheduleRepository(
        action.payload.shopId,
        action.payload.lineAccessToken
      );
          */
      const shopClient = ShopRepository(
        action.payload.shopId,
        action.payload.lineAccessToken
      );
      await Promise.all([
        shopClient.get(),
        //scheduleClient.fetch()
      ]).then((results) => {
        dispatch({
          type: ResourceOperationEvent.GET_SHOP,
          payload: results[0],
        });
        /*
        dispatch({
          type: ResourceOperationEvent.FETCH_SCHEDULE,
          payload: results[1],
        });
        */
      });
    },
  RESERVATION_FETCHING:
    ({ dispatch }) =>
    async (action) => {
      const reservationClient = ReservationRepository(
        action.payload.shopId,
        action.payload.lineAccessToken
      );
      const result = await reservationClient.fetch(action.payload.customerId, action.payload.nextToken);

      dispatch({
        type: ResourceOperationEvent.FETCH_RESERVATION,
        payload: result,
      });
    },
  EXPIRED_RESERVATION_FETCHING:
    ({ dispatch }) =>
    async (action) => {
      const reservationClient = ReservationRepository(
        action.payload.shopId,
        action.payload.lineAccessToken
      );
      const result = await reservationClient.fetchExired(action.payload.customerId, action.payload.nextToken);

      dispatch({
        type: ResourceOperationEvent.FETCH_EXPIRED_RESERVATION,
        payload: result,
      });
    },
  START_FETCH_SERVICE_CUSTOMER:
    ({ dispatch }) =>
    async (action) => {
      const customerClient = CustomerRepository(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      await customerClient
        .fetchCustomer(action.payload.input.customerId)
        .then((result) => {
          dispatch({
            type: ResourceOperationEvent.UPDATE_CUSTOMER,
            payload: result,
          });
        });
    },
  START_FETCH_STRIPE_CUSTOMER:
    ({ dispatch }) =>
    async (action) => {
      const customerClient = CustomerRepository(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      await customerClient
        .fetchStripeCustomerInfo(action.payload.input.customerId)
        .then((result) => {
          dispatch({
            type: ResourceOperationEvent.UPDATE_STRIPE_CUSTOMER,
            payload: result,
          });
        });
    },
  START_PRE_RESERVATION_USING_PURCHASED_TICKET:
    ({ dispatch }) =>
    async (action) => {
      dispatch({
        type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
        payload: {
          status: TransactionStatus.entry,
          paymentIntentId: null,
          message: "しばらくこのままでお待ちください。",
        },
      });
      const reservationClient = ReservationRepository(
        action.payload.preReservationInput.shopId,
        action.payload.lineAccessToken
      );
      const customerClient = CustomerRepository(
        action.payload.preReservationInput.shopId,
        action.payload.lineAccessToken
      );
      console.log(action.payload.preReservationInput)
      await reservationClient
        .create(
          action.payload.preReservationInput,
          action.payload.reserveUsingTicketInput
        )
        .then((result) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.processing,
              paymentIntentId: null,
              message: "予約の確定処理中です。",
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.error,
              paymentIntentId: null,
              message: "予約が失敗しました。",
            },
          });
        })
        .finally(async () => {
          await customerClient
            .fetchCustomer(action.payload.preReservationInput.customerId)
            .then((result) => {
              dispatch({
                type: ResourceOperationEvent.UPDATE_CUSTOMER,
                payload: result,
              });
            });
        });
    },
  START_PRE_RESERVATION_PURCHASING_TICKET:
    ({ dispatch }) =>
    async (action) => {
      const stripePaymentClient = PaymentIntent(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      const reservationClient = ReservationRepository(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      const customerClient = CustomerRepository(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );

      const createPaymentIntentInput: CreateTicketPaymentIntentInput = {
        shopId: action.payload.input.shopId,
        systemCustomerId: action.payload.input.systemCustomerId,
        stripeCustomerId: action.payload.input.stripeCustomerId,
        priceId: action.payload.input.priceId,
        quantity: action.payload.input.quantity,
        masterTicketId: action.payload.input.masterTicketId,
        masterTicketName: action.payload.input.masterTicketName,
        paymentMethodId: action.payload.input.paymentMethodId,
      };

      const createPreReservationInput: CreatePreReservationInput = {
        shopId: action.payload.input.shopId,
        scheduleId: action.payload.input.scheduleId,
        customerId: action.payload.input.customerId,
        //consumedTicketId: action.payload.input.masterTicketId,
        lessonStyle: action.payload.input.lessonStyle,
        status: action.payload.input.status,
        expiredAt: action.payload.input.expiredAt
      };

      dispatch({
        type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
        payload: {
          status: TransactionStatus.entry,
          paymentIntentId: null,
          message: "しばらくこのままでお待ちください。",
        },
      });
      await stripePaymentClient
        .comfirmTicketPaymentIntent(createPaymentIntentInput)
        .then((result) => {
          if (result.isError) {
            dispatch({
              type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
              payload: {
                status: TransactionStatus.error,
                paymentIntentId: null,
                message: result.errMessage,
              },
            });
          } else {
            dispatch({
              type: ResourceOperationEvent.PURCHASED_TICKET,
              payload: result.ticket!,
            });
            const reserveUsingTicketInput: ReserveUsingTicketInput = {
              purchasedTicketId: result.ticket?.id!,
              purchasedTicketIndex: action.payload.purchasedTicketIndex,
            };
     
            return reservationClient.create(
              {
                ...createPreReservationInput,
                consumedTicketId: result.ticket?.id,
              },
              reserveUsingTicketInput
            );
          }
        })
        .then((result) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.processing,
              paymentIntentId: null,
              message: "予約の確定処理中です。",
            },
          });
        })
        .finally(async () => {
          await customerClient
            .fetchCustomer(action.payload.input.customerId)
            .then((result) => {
              dispatch({
                type: ResourceOperationEvent.UPDATE_CUSTOMER,
                payload: result,
              });
            });
        })
        .catch((err) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.error,
              paymentIntentId: null,
              message: "予約が失敗しました。",
            },
          });
        });
    },
  START_PRE_RESERVATION_SINGLE_PURCHASING:
    ({ dispatch }) =>
    async (action) => {
      const stripePaymentClient = PaymentIntent(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      const reservationClient = ReservationRepository(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      const customerClient = CustomerRepository(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );

      const createPaymentIntentInput: CreateSinglePaymentIntentInput = {
        shopId: action.payload.input.shopId,
        systemCustomerId: action.payload.input.systemCustomerId,
        stripeCustomerId: action.payload.input.stripeCustomerId,
        price: action.payload.input.price,
        quantity: action.payload.input.quantity,
        paymentMethodId: action.payload.input.paymentMethodId,
        description: action.payload.input.description,
      };

      const createPreReservationInput: CreatePreReservationInput = {
        shopId: action.payload.input.shopId,
        scheduleId: action.payload.input.scheduleId,
        customerId: action.payload.input.customerId,
        lessonStyle: action.payload.input.lessonStyle,
        isTrial: action.payload.input.isTrial,
        status: action.payload.input.status,
        expiredAt: action.payload.input.expiredAt
      };

      dispatch({
        type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
        payload: {
          status: TransactionStatus.entry,
          paymentIntentId: null,
          message: "しばらくこのままでお待ちください。",
        },
      });
      await stripePaymentClient
        .comfirmSinglePaymentIntent(createPaymentIntentInput)
        .then((result) => {
          if (result.isError) {
            dispatch({
              type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
              payload: {
                status: TransactionStatus.error,
                paymentIntentId: null,
                message: result.errMessage,
              },
            });
          } else {
            return reservationClient.create(createPreReservationInput);
          }
        })
        .then((result) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.processing,
              paymentIntentId: null,
              message: "予約の確定処理中です。",
            },
          });
        })
        .finally(async () => {
          await customerClient
            .fetchCustomer(action.payload.input.customerId)
            .then((result) => {
              dispatch({
                type: ResourceOperationEvent.UPDATE_CUSTOMER,
                payload: result,
              });
            });
        })
        .catch((err) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.error,
              paymentIntentId: null,
              message: "予約が失敗しました。",
            },
          });
        });
    },
  START_PRE_RESERVATION_SUBSCRIBING:
    ({ dispatch }) =>
    async (action) => {
      const stripeSubscriptionClient = Subscription(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      const reservationClient = ReservationRepository(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      const customerClient = CustomerRepository(
        action.payload.input.shopId,
        action.payload.lineAccessToken
      );
      const createSubscriptionInput: CreateSubscriptionInput = {
        shopId: action.payload.input.shopId,
        systemCustomerId: action.payload.input.systemCustomerId,
        stripeCustomerId: action.payload.input.stripeCustomerId,
        priceId: action.payload.input.priceId,
        couponId: "",
        masterTicketId: action.payload.input.masterTicketId,
        masterTicketName: action.payload.input.masterTicketName,
        paymentMethodId: action.payload.input.paymentMethodId,
      };
      const createPreReservationInput: CreatePreReservationInput = {
        shopId: action.payload.input.shopId,
        scheduleId: action.payload.input.scheduleId,
        customerId: action.payload.input.customerId,
        consumedTicketId: action.payload.input.masterTicketId,
        lessonStyle: action.payload.input.lessonStyle,
        status: action.payload.input.status,
        expiredAt: action.payload.input.expiredAt
      };
      dispatch({
        type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
        payload: {
          status: TransactionStatus.entry,
          paymentIntentId: null,
          message: "しばらくこのままでお待ちください。",
        },
      });
      await stripeSubscriptionClient
        .createComfirmedSubscription(createSubscriptionInput)
        .then((result) => {
          if (result.isError) {
            dispatch({
              type: ResourceOperationEvent.SET_PAYMENT_TRANSACTION,
              payload: {
                status: TransactionStatus.error,
                paymentIntentId: null,
                message: result.errMessage,
              },
            });
          } else {
            dispatch({
              type: ResourceOperationEvent.PURCHASED_TICKET,
              payload: result.ticket!,
            });
            const reserveUsingTicketInput: ReserveUsingTicketInput = {
              purchasedTicketId: result.ticket?.id!,
              purchasedTicketIndex: action.payload.purchasedTicketIndex,
            };
            return reservationClient.create(
              createPreReservationInput,
              reserveUsingTicketInput
            );
          }
        })
        .then((result) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.processing,
              paymentIntentId: null,
              message: "予約の確定処理中です。",
            },
          });
        })
        .finally(async () => {
          await customerClient
            .fetchCustomer(action.payload.input.customerId)
            .then((result) => {
              dispatch({
                type: ResourceOperationEvent.UPDATE_CUSTOMER,
                payload: result,
              });
            });
        })
        .catch((err) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.error,
              paymentIntentId: null,
              message: `予約が失敗しました。${err.message}`,
            },
          });
        });
    },
};

type Props = {
  children?: React.ReactNode;
};

export const ApplicationStoreProvider: React.FC<Props> = (props) => {
  const [store, dispatch] = useReducerAsync<
    Reducer<STORE, ACTIONTYPE>,
    ASYNCACTION,
    ACTIONTYPE | ASYNCACTION
  >(reducer, initialStore, asyncActionHandlers);
  const value = useMemo(() => ({ store, dispatch }), [dispatch, store]);

  return <ApplicationStoreContext.Provider value={value} {...props} />;
};

export const useApplicationStore = () => {
  const context = useContext(ApplicationStoreContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useApplicationStore must be within a ApplicationStoreProvider."
    );
  }
  return context;
};
