import liff from "@line/liff";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import Links from "@mui/material/Link";
import { Container } from "@mui/system";
import AWSAppSyncClient from "aws-appsync";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ReserveSchedulePanel } from "../components/layout/ReserveSchedulePanel";
import { TransactionMessagePanel } from "../components/layout/TransactionMessagePanel";
import {
  LessonStyle,
  OnUpdateReservationByCustomerIdSubscription,
  ReservationStatus,
  Schedule,
} from "../DAL/API";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { onUpdateReservationByCustomerId } from "../DAL/subscription";

import {
  TransactionStatus,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";

export const ReservationTransactionPage = () => {
  const { shopId, scheduleId } = useParams();
  const { store, dispatch } = useApplicationStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [schedule, setSchedule] = useState<Schedule>();
  const [style, setStyle] = useState<LessonStyle>();

  const appsyncClient = new AWSAppSyncClient({
    url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT!,
    region: process.env.REACT_APP_AWS_REGION!,
    auth: {
      type: "AWS_LAMBDA",
      token: "custom-authorized",
    },
    disableOffline: true,
  });

  const initializeState = () => {
    console.log(store.customer?.serviceAccount.reservations)
    setSchedule(
      store.customer?.serviceAccount.reservations?.items
        .map((r) => r?.schedule!)
        ?.find((s) => s?.shopId === shopId && s?.id === scheduleId)
    );
    setStyle(
      searchParams.get("style") === "STUDIO"
        ? LessonStyle.STUDIO
        : LessonStyle.ONLINE
    );
  };

  useEffect(() => {
    initializeState();
    const updateSub = appsyncClient
      .subscribe({
        query: gql(onUpdateReservationByCustomerId),
        variables: { customerId: store.customer?.serviceAccount.id },
      })
      .subscribe((result) => {
        const data = result.data as OnUpdateReservationByCustomerIdSubscription;
        switch (data.onUpdateReservationByCustomerId?.status) {
          case ReservationStatus.Faild:
            dispatch({
              type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
              payload: {
                status: TransactionStatus.error,
                paymentIntentId: null,
                message: "予約が完了しませんでした。",
              },
            });
            setSearchParams({
              style: searchParams.get("style")!,
              status: "error",
            });
            break;
          case ReservationStatus.Success:
            dispatch({
              type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
              payload: {
                status: TransactionStatus.succeed,
                paymentIntentId: null,
                message: "予約が確定しました。",
              },
            });
            dispatch({
              type: "START_FETCH_SERVICE_CUSTOMER",
              payload: {
                input: {
                  shopId: store.customer?.serviceAccount.shopId!,
                  customerId: store.customer?.serviceAccount.id!,
                },
                lineAccessToken: liff.getAccessToken(),
              },
            });
            setSearchParams({
              style: searchParams.get("style")!,
              status: "succeed",
            });
            break;
          default:
            dispatch({
              type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
              payload: {
                status: TransactionStatus.processing,
                paymentIntentId: null,
                message: "処理中です",
              },
            });
            setSearchParams({
              style: searchParams.get("style")!,
              status: "processing",
            });
            break;
        }
      });

    return () => {
      updateSub.unsubscribe();
    };
  }, [store.customer]);
  return (
    <Container>
      <Stack spacing={2}>
        {store.paymentTransaction ? (
          <Paper variant="outlined">
            <Stack spacing={2} p={2}>
              <TransactionMessagePanel transaction={store.paymentTransaction} />
              {store.paymentTransaction?.status ===
              TransactionStatus.succeed ? (
                <Paper variant="outlined">
                  <Typography variant="body1" p={1.5} lineHeight={1.75}>
                    購入したチケット・加入した月謝制に関してはメニューの「取得済みチケット」または「加入している月謝制」からご確認ください。
                  </Typography>
                </Paper>
              ) : null}
            </Stack>
          </Paper>
        ) : null}
        {store.reservationTransaction ? (
          <Paper variant="outlined">
            <Stack spacing={2} p={2}>
              <TransactionMessagePanel
                transaction={store.reservationTransaction}
              />
              {store.reservationTransaction?.status ===
                TransactionStatus.succeed && schedule ? (
                <ReserveSchedulePanel schedule={schedule} lessonStyle={style!}>
                  {style === LessonStyle.ONLINE ? (
                    <Box mt={3}>
                      <Stack>
                        <Typography variant="subtitle2">
                          Zoomミーティングリンク
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            display: "block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          color="info"
                        >
                          <Links
                            href={schedule?.link!}
                            underline="always"
                            target="_blank"
                          >
                            {schedule?.link}
                          </Links>
                        </Typography>
                      </Stack>
                      <Stack mt={1}>
                        <Typography variant="subtitle2">パスワード</Typography>
                        <Typography variant="caption">
                          {schedule?.pass}
                        </Typography>
                      </Stack>
                    </Box>
                  ) : null}
                </ReserveSchedulePanel>
              ) : null}
            </Stack>
          </Paper>
        ) : null}
        <Stack>
          {store.paymentTransaction?.status === TransactionStatus.error ||
          store.reservationTransaction?.status === TransactionStatus.succeed ||
          store.reservationTransaction?.status === TransactionStatus.error ? (
            <Button
              variant="contained"
              disableElevation
              component={Link}
              to={`/s/${store.shop?.id}/`}
            >
              トップページに戻る
            </Button>
          ) : null}
        </Stack>
      </Stack>
    </Container>
  );
};
