import styled from "@emotion/styled";
import { Box, AppBar, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import { LiffReservationTheme } from "./LiffReservationTheme";
import liff from "@line/liff";
/*
const MyPageAppBar = styled(AppBar)({
  borderColor: LiffReservationTheme.palette.border.main,
  border: 0,
  borderBottom: "1px solid",
});
*/

export const LiffReservationMyPage = () => {
  const { store, dispatch } = useApplicationStore();
  const { shopId, customerId } = useParams();
  useEffect(() => {
    if (store.customer?.serviceAccount === null) {
      CustomerRepository(shopId!, liff.getAccessToken())
        .getAccount(customerId!)
        .then((result) => {
          dispatch({
            type: ResourceOperationEvent.FETCH_CUSTOMER,
            payload: result,
          });
        })
        .catch((err) => {
          ;
        });
    }
  }, [store.customer]);
  return (
    <Box>
      <Outlet />
    </Box>
  );
};
