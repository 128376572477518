import { Box, Button, Paper, Stack, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreateCustomerAccountInput, CreateCustomerInput } from "../../DAL/API";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { CustomerRepository } from "../../DAL/repositories/CustomerRepository";
import { ResourceOperationEvent } from "../../DAL/ResourceEvents";
import liff from "@line/liff";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import UploadIcon from '@mui/icons-material/Upload';



const formSchema = yup.object({
  name: yup.string().required("氏名の入力は必須です。"),
  email: yup
    .string()
    .email("メールアドレスの形式が異なります。")
    .required("メールアドレスの入力は必須です。"),
});

const CustomerForm = () => {
  const { shopId } = useParams();
  const { store, dispatch } = useApplicationStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initalCustomer = {
    shopId: shopId,
    lineId: `line_${store.lineId}`, 
    name: "",
    email: "",
  } as CreateCustomerAccountInput;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCustomerAccountInput>({
    resolver: yupResolver(formSchema),
    defaultValues: initalCustomer,
  });

  const onSubmit: SubmitHandler<CreateCustomerAccountInput> = async (data) => {
    setIsLoading(true)
    CustomerRepository(data.shopId, liff.getAccessToken())
      .create(data)
      .then((customer) => {
        dispatch({
          type: ResourceOperationEvent.FETCH_CUSTOMER,
          payload: customer,
        });
      });
  };
  return (
    <Stack spacing={2}>
      <Paper variant="outlined" elevation={0}>
        <Stack spacing={1} px={2.5} pt={2} pb={4}>
          <TextField
            margin="dense"
            id="name"
            label="氏名"
            fullWidth
            variant="standard"
            {...register("name")}
            error={"name" in errors}
            helperText={errors.name?.message}
            placeholder="氏名"
          />
          <TextField
            margin="dense"
            id="email"
            label="メールアドレス"
            fullWidth
            variant="standard"
            {...register("email")}
            error={"email" in errors}
            helperText={errors.email?.message}
            placeholder="氏名"
          />
        </Stack>
      </Paper>

      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<UploadIcon />}
        variant="contained"
        disableElevation
        onClick={handleSubmit(onSubmit)}
      >
        登録する
      </LoadingButton>
    </Stack>
  );
};

export default CustomerForm;
