import { TextField, TextFieldProps } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from "date-fns/locale/ja";
import { useState } from "react";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

type Props = {
  onAccept(date: Date | null): void;
};
const DateSelector = ({ onAccept }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const onChange = (newDate: Date | null) => {
    setSelectedDate(newDate);
  };

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ja}
        dateFormats={{ monthAndYear: "yyyy年MM月" }}
      >
        <MobileDatePicker
          showToolbar={false}
          toolbarFormat="yyyy年MM月dd日"
          inputFormat="yyyy年MM月dd日"
          mask="____年__月__日"
          leftArrowButtonText="前月を表示"
          rightArrowButtonText="次月を表示"
          toolbarTitle="日付選択"
          value={selectedDate}
          onChange={onChange}
          onAccept={onAccept}
          disablePast
          renderInput={(params) => (
            <TextField {...params} />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateSelector;
