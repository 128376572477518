import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { LessonStyle, Schedule } from "../../DAL/API";
import { useTopPageContext } from "../../provider/TopPageContextProvider";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import LoadingCard from "../parts/card/LoadingCard";
import { ScheduleList } from "../ScheduleList";
import React from "react";
import moment from "moment";

const TopPageScheduleList = React.memo(() => {
  const { store } = useApplicationStore();
  const { topPageState } = useTopPageContext();
  const [filteredSchedules, setFilteredSchedules] = useState<Schedule[] | null>(
    null
  );
  useEffect(() => {
    if (store.schedules !== null && filteredSchedules == null) {
      const now = new Date();
      const todayStart = moment(now).startOf("day");
      const todayEnd = moment(now).endOf("day");

      setFilteredSchedules(
        store.schedules.filter(
          (s) =>
            (moment(s.start).isBetween(todayStart, todayEnd) ||
              moment(s.end).isBetween(todayStart, todayEnd)) &&
            (s.lesson.style === topPageState.sortedLessonType ||
              s.lesson.style === LessonStyle.BOTH)
        )
      );
    } else if (filteredSchedules !== null) {
      const dayStart = moment(topPageState.selectedDate).startOf("day");
      const dayEnd = moment(topPageState.selectedDate).endOf("day");
      setFilteredSchedules(
        store.schedules!.filter(
          (s) =>
            (moment(s.start).isBetween(dayStart, dayEnd) ||
              moment(s.end).isBetween(dayStart, dayEnd)) &&
            (s.lesson.style === topPageState.sortedLessonType ||
              s.lesson.style === LessonStyle.BOTH)
        )
      );
    }
  }, [
    store.schedules,
    topPageState.selectedDate,
    topPageState.sortedLessonType,
  ]);

  return filteredSchedules !== null ? (
    <ScheduleList
      schedules={filteredSchedules!}
      selectedLessonStyle={topPageState.sortedLessonType}
    />
  ) : (
    <Stack spacing={1}>
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
    </Stack>
  );
});

export default TopPageScheduleList;
