import { Box, Stack, Paper, Typography, Container } from "@mui/material";
import { useEffect, useState } from "react";
import MyPageTitlePanel from "../../components/layout/MyPageTitlePanel";
import { TextCard } from "../../components/parts/card";
import { MasterTicketCard } from "../../components/parts/card/MasterTicketCard";
import { PurchasedTicket, TicketType } from "../../DAL/API";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";

export const CustomerPurchasedSubscriptionPage = () => {
  const [isUser, setIsUser] = useState<boolean>(false);
  const { store } = useApplicationStore();
  const [purchasedTickets, setTickets] = useState<(PurchasedTicket | null)[]>();
  useEffect(() => {
    if (typeof store.customer?.serviceAccount.id === "undefined") {
      setIsUser(false);
    } else {
      setIsUser(true);
      if (store.customer.serviceAccount.purchasedTickets?.length! > 0) {
        setTickets(
          store.customer?.serviceAccount.purchasedTickets?.filter(
            (ticket) => ticket?.type === TicketType.SUBSCRIPTION
          )
        );
      }
    }
  }, [store.customer]);
  return (
    <Container>
      <Box>
        <Stack spacing={2}>
          <MyPageTitlePanel
            title="加入している月謝一覧"
            spacing={1.2}
            textAlign="center"
          />
          {purchasedTickets?.length! > 0 ? (
            purchasedTickets?.map((t) => (
              <MasterTicketCard ticket={t!} key={t?.paymentIntentId!} />
            ))
          ) : (
            <Paper elevation={0} variant="outlined">
              <Typography padding={1.5} align="center">
                加入している月謝はありません
              </Typography>
            </Paper>
          )}
        </Stack>
      </Box>
    </Container>
  );
};
