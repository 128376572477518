import {
  Card,
  Typography,
  Divider,
  Stack,
  Box,
  Button,
  Container,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { MasterTicket, PurchasedTicket, TicketHistory } from "../../../DAL/API";
import moment from "moment";
import { PurchasedTicketHistoryPanel } from "../../layout/PurchasedTicketHistory";

type Props = {
  ticket: PurchasedTicket;
};
type State = {
  invalid: boolean;
};
export const PurchasedTicketCard: React.FC<Props> = React.memo(
  ({ ticket, children }) => {
    const [state] = useState<State>({
      invalid: moment(parseInt(ticket?.expireAt!)).endOf("day").unix() <= moment().unix(),
    });
    return (
      <Card
        variant="outlined"
        elevation={0}
        sx={{ margin: "12px 0 32px", padding: "12px" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight={600}>{ticket?.name}</Typography>
          <Chip
            label={state.invalid ? "無効" : "有効"}
            color={state.invalid ? "error" : "success"}
            size="small"
            variant="outlined"
          />
        </Stack>
        <Divider sx={{ margin: "6px 0 12px" }} />
        <Box>
          <Stack spacing={1}>
            {ticket?.expireAt ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle1">残り利用回数:</Typography>
                <Typography variant="subtitle1">
                  {ticket?.currentCount}回
                </Typography>
              </Stack>
            ) : null}
            {ticket?.expireAt ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle1">有効期限:</Typography>
                <Typography variant="subtitle1">
                  {moment(parseInt(ticket?.expireAt)).format("YYYY年MM月DD日")}
                </Typography>
              </Stack>
            ) : null}
            {ticket?.lastUsedAt ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle1">有効期限:</Typography>
                <Typography variant="subtitle1">
                  {moment(parseInt(ticket?.lastUsedAt)).format(
                    "YYYY年MM月DD日"
                  )}
                </Typography>
              </Stack>
            ) : null}
          </Stack>
        </Box>
        <Divider sx={{ margin: "6px 0 12px" }}>
          <Chip label="基本情報" size="small" />
        </Divider>
        <Box>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle2">金額:</Typography>
              <Typography variant="subtitle2">
                {ticket?.price.toLocaleString()}円
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle2">1月あたりの利用回数:</Typography>
              <Typography variant="subtitle2">{ticket?.maxCount}回</Typography>
            </Stack>
            {ticket?.hasDailyLimit ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2">
                  1日あたりの利用可能回数:
                </Typography>
                <Typography variant="subtitle2">
                  {ticket?.limitPerDaily}回
                </Typography>
              </Stack>
            ) : null}
            {ticket?.hasWeeklyLimit ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2">
                  週あたりの利用可能回数:
                </Typography>
                <Typography variant="subtitle2">
                  {ticket?.limitPerWeekly}回
                </Typography>
              </Stack>
            ) : null}
            {ticket?.purchasedAt ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2">購入日:</Typography>
                <Typography variant="subtitle2">
                  {moment(parseInt(ticket?.purchasedAt)).format(
                    "YYYY年MM月DD日"
                  )}
                </Typography>
              </Stack>
            ) : null}
            {ticket.histories ? (
              <Box>
                <Divider sx={{ margin: "6px 0 12px" }}>
                  <Chip label="履歴" size="small" />
                </Divider>
                <PurchasedTicketHistoryPanel
                  ticketHistories={
                    ticket.histories?.filter(
                      (h) => h !== null
                    ) as TicketHistory[]
                  }
                />
              </Box>
            ) : null}
            {children}
          </Stack>
        </Box>
      </Card>
    );
  }
);
