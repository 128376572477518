import liff from "@line/liff";
import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { TransactionMessagePanel } from "../components/layout/TransactionMessagePanel";
import {
  TransactionStatus,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";

export const TicketPurchasePage = () => {
  const { store, dispatch } = useApplicationStore();

  useEffect(() => {
    if (store.paymentTransaction?.status === TransactionStatus.succeed) {
      dispatch({
        type: "START_FETCH_SERVICE_CUSTOMER",
        payload: {
          input: {
            shopId: store.customer?.serviceAccount.shopId!,
            customerId: store.customer?.serviceAccount.id!,
          },
          lineAccessToken: liff.getAccessToken(),
        },
      });
    }
  }, [store.paymentTransaction]);

  return (
    <Container>
      <Stack spacing={2} px={1}>
        {store.paymentTransaction ? (
          <TransactionMessagePanel transaction={store.paymentTransaction} />
        ) : (
          <Paper variant="outlined">
            <Stack
              direction="row"
              spacing={2}
              px={2}
              py={1}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size="1.3em" />
            </Stack>
          </Paper>
        )}
        <Divider />
        {store.paymentTransaction?.status === TransactionStatus.succeed ? (
          <Stack spacing={2}>
            <Paper variant="outlined">
              <Typography variant="body1" p={1.5} lineHeight={1.75}>
                購入したチケット・加入した月謝制に関してはメニューの「取得済みチケット」または「加入している月謝制」からご確認ください。
              </Typography>
            </Paper>

            <Button
              variant="contained"
              disableElevation
              component={Link}
              to={`/s/${store.shop?.id}/`}
            >
              トップページに戻る
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </Container>
  );
};
