import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CustomerIndexPage } from "../pages/mypage/CustomerIndexPage";
import TopPageContextProvider from "../provider/TopPageContextProvider";
import { ReservePage } from "../pages/ReservePage";
import { SchedulePage } from "../pages/SchedulePage";
import { TopPage } from "../pages/TopPage";
import { LiffReservationClient } from "../theme/LiffReservationClient";
import { LiffReservationMyPage } from "../theme/LiffReservationMyPage";
import { CustomerReservationsPage } from "../pages/mypage/CustomerReservationsPage";
import { TicketsPage } from "../pages/TicketsPage";
import { SubscriptionsPage } from "../pages/SubscriptionsPage";
import { CustomerSignupPage } from "../pages/mypage/CustomerSignupPage";
import { CustomerRegisterPage } from "../pages/CustomerRegisterPage";
import { ProtectedRoute } from "./ProtectedRoute";
import { PublicRoute } from "./PublicRoute";
import { CustomerPurchasedTicketsPage } from "../pages/mypage/CustomerPurchasedTicketsPage";
import { MasterTicketComfirmPage } from "../pages/MasterTicketComfirmPage";
import { PaymentComfirmationContextProvider } from "../provider/PaymentComfirmationContextProvider";
import { TicketPurchasePage } from "../pages/TicketPurchasedPage";
import { CustomerPurchasedSubscriptionPage } from "../pages/mypage/CustomerPurchasedSubscriptionPage";
import { ReservationTransactionPage } from "../pages/ReservationTransactionPage";
import { CustomerReservationHistoryPage } from "../pages/mypage/CustomerReservationHistoryPage";

export const AppRouter = () => {
  const prefix = "/s";
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LiffReservationClient />}>
          <Route element={<ProtectedRoute />}>
            <Route
              path={prefix + "/:shopId"}
              element={
                <TopPageContextProvider>
                  <TopPage />
                </TopPageContextProvider>
              }
            />
            <Route
              path={prefix + "/:shopId/tickets/"}
              element={<TicketsPage />}
            />
            <Route
              path={prefix + "/:shopId/subscriptions"}
              element={<SubscriptionsPage />}
            />
            <Route
              path={prefix + "/:shopId/ticket/:masterTicketId/comfirm"}
              element={
                <PaymentComfirmationContextProvider>
                  <MasterTicketComfirmPage />
                </PaymentComfirmationContextProvider>
              }
            />
            <Route
              path={prefix + "/:shopId/subscription/:masterTicketId/comfirm"}
              element={
                <PaymentComfirmationContextProvider>
                  <MasterTicketComfirmPage />
                </PaymentComfirmationContextProvider>
              }
            />
            <Route
              path={prefix + "/:shopId/ticket/:masterTicketId/purchased"}
              element={<TicketPurchasePage />}
            />

            <Route
              path={prefix + "/:shopId/sc/:scheduleId"}
              element={<SchedulePage />}
            />
            <Route
              path={prefix + "/:shopId/sc/:scheduleId/reserve"}
              element={
                <PaymentComfirmationContextProvider>
                  <ReservePage />
                </PaymentComfirmationContextProvider>
              }
            />
            <Route
              path={prefix + "/:shopId/sc/:scheduleId/reserve/transaction"}
              element={<ReservationTransactionPage />}
            />
            {/* mypage */}
            <Route element={<LiffReservationMyPage />}>
              <Route
                path={prefix + "/:shopId/c/:customerId"}
                element={<CustomerIndexPage />}
              />
              <Route
                path={prefix + "/:shopId/c/create"}
                element={<CustomerSignupPage />}
              />
              <Route
                path={prefix + "/:shopId/c/:customerId/reservations"}
                element={<CustomerReservationsPage />}
              />
              <Route
                path={prefix + "/:shopId/c/:customerId/reservations/history"}
                element={<CustomerReservationHistoryPage />}
              />
              <Route
                path={prefix + "/:shopId/c/:customerId/tickets/purchased"}
                element={<CustomerPurchasedTicketsPage />}
              />
              <Route
                path={prefix + "/:shopId/c/:customerId/subscriptions/purchased"}
                element={<CustomerPurchasedSubscriptionPage />}
              />
            </Route>
          </Route>
          <Route element={<PublicRoute />}>
            <Route
              path={prefix + "/:shopId/c/regist"}
              element={<CustomerRegisterPage />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
