import liff from "@line/liff";
import { Paper, Stack, Button } from "@mui/material";
import React, { useEffect } from "react";
import {
  LessonStyle,
  ReservationStatus,
  Schedule,
} from "../../DAL/API";
import {
  PurchasingSingleReserveInput,
  UserAccount,
} from "../../DAL/schema";
import { LoadingCard } from "../parts/card";
import { PaymentMethodComfirmationPanel } from "./PaymentMethodComfirmationPanel";
import { ReserveSchedulePanel } from "./ReserveSchedulePanel";
import { SimplePaymentComfirmationPanel } from "./SimplePaymentComfirmationPanel";
import { UserInfoComfirmationPanel } from "./UserInfoComfirmationPanel";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { usePaymentComfirmationContext } from "../../provider/PaymentComfirmationContextProvider";

type Props = {
  schedule: Schedule;
  type: string | null | undefined;
  style: LessonStyle;
  customer: UserAccount;
  stripePubKey: string;
};

export const ReserveSinglePaymentPanel: React.FC<Props> = React.memo(
  ({ schedule, type, customer, stripePubKey, style }) => {
    const { dispatch } = useApplicationStore();
    const { shopId, masterTicketId } = useParams();
    const navigate = useNavigate();
    const { comfirmationValue } = usePaymentComfirmationContext();

    const formSchema = yup.object({
      systemCustomerId: yup.string().required(),
      stripeCustomerId: yup.string().required(),
      price: yup.number().required(),
      quantity: yup.number().required(),
      paymentMethodId: yup.string().required("使用するクレジットカードを選択してください。"),
      description: yup.string().required(),

      shopId: yup.string().required(),
      scheduleId: yup.string().required(),
      customerId: yup.string().required(),
      status: yup
        .mixed<ReservationStatus>()
        .oneOf(Object.values(ReservationStatus))
        .required(),
      lessonStyle: yup
        .mixed<LessonStyle>()
        .oneOf(Object.values(LessonStyle))
        .required(),
    });

    const input: PurchasingSingleReserveInput = {
      systemCustomerId: customer.serviceAccount.id,
      stripeCustomerId: customer.serviceAccount.stripeId!,
      price:
        type === "SINGLE"
          ? schedule.lesson.singlePrice!
          : schedule.lesson.trialPrice!,
      quantity: 1,
      paymentMethodId: "",
      description: `${type === "SINGLE" ? "【単発】" : "【お試し】"}${
        schedule.lesson.name
      }`,

      shopId: schedule.shopId,
      scheduleId: schedule.id,
      customerId: customer.serviceAccount.id,

      status: ReservationStatus.Entry,
      lessonStyle: style,
      isTrial: type === "TRIAL" ? true : false,
      expiredAt: schedule.expiredAt!
    };

    const {
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<PurchasingSingleReserveInput>({
      resolver: yupResolver(formSchema),
      defaultValues: input,
    });
    const redirectPath = `/s/${shopId}/sc/${schedule.id}/reserve/transaction?style=${style}`;

    useEffect(() => {
      if (comfirmationValue.paymentMethodId !== null) {
        ;
        setValue("paymentMethodId", comfirmationValue.paymentMethodId);
      }
    }, [comfirmationValue]);

    const onSubmit: SubmitHandler<PurchasingSingleReserveInput> = (data) => {
      dispatch({
        type: "START_PRE_RESERVATION_SINGLE_PURCHASING",
        payload: {
          input: data,
          lineAccessToken: liff.getAccessToken(),
        },
      });
      navigate(redirectPath);
    };

    return (
      <Stack>
        <Paper
          variant="outlined"
          sx={{ margin: "12px 0 32px", padding: "12px" }}
        >
          <Stack spacing={3} px={1} py={1.5}>
            <ReserveSchedulePanel schedule={schedule} lessonStyle={style} />
            {type === "SINGLE" ? (
              <SimplePaymentComfirmationPanel
                title="1回コース"
                amount={schedule.lesson.singlePrice!}
              />
            ) : type === "TRIAL" ? (
              <SimplePaymentComfirmationPanel
                title="お試しコース"
                amount={schedule.lesson.trialPrice!}
              />
            ) : null}
            <UserInfoComfirmationPanel user={customer.serviceAccount} />
            {customer !== null && stripePubKey !== null ? (
              <PaymentMethodComfirmationPanel
                user={customer}
                stripePubKey={stripePubKey}
                error={"paymentMethodId" in errors}
                helperText={errors.paymentMethodId?.message}
              />
            ) : (
              <LoadingCard />
            )}
          </Stack>
        </Paper>
        <Button
          variant="contained"
          disableElevation
          sx={{ width: "100%" }}
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          購入して予約する
        </Button>
      </Stack>
    );
  }
);
