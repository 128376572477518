export enum ResourceOperationEvent {
  FETCH_SHOP,
  FETCH_LESSON,
  FETCH_SCHEDULE,
  FETCH_RESERVATION,
  FETCH_EXPIRED_RESERVATION,
  FETCH_TICKET,
  FETCH_CUSTOMER,
  GET_SHOP,
  GET_LESSON,
  GET_SCHEDULE,
  GET_RESERVATION,
  GET_TICKET,
  GET_CUSTOMER,
  CREATE_LESSON,
  CREATE_SCHEDULE,
  CREATE_RESERVATION,
  CREATE_TICKET,
  CREATE_CUSTOMER,
  UPDATE_SHOP,
  UPDATE_LESSON,
  UPDATE_SCHEDULE,
  UPDATE_RESERVATION,
  UPDATE_TICKET,
  UPDATE_CUSTOMER,
  UPDATE_STRIPE_CUSTOMER,
  DELETE_LESSON,
  DELETE_SCHEDULE,
  DELETE_RESERVATION,
  DELETE_TICKET,
  DELETE_CUSTOMER,
  FETCH_LIFF,
  PURCHASED_TICKET,
  SET_PAYMENT_TRANSACTION,
  SET_RESERVATION_TRANSACTION,
}