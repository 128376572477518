import { Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import DocumentMeta from "react-document-meta";
import { useParams } from "react-router-dom";
import { CustomerForm } from "../components/form";
import TopPageScheduleSortPanel from "../components/layout/TopPageScheduleSortPanel";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";
import { ScheduleRepository } from "../DAL/repositories/ScheduleRepository";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import liff from "@line/liff";
import PageTitlePanel from "../components/layout/PageTitlePanel";

export const CustomerRegisterPage: React.FC = ({ children }) => {
  const { store, dispatch } = useApplicationStore();
  const { shopId } = useParams();
  const meta = {
    title: "会員情報登録",
  };

  const description:string = "初めてのお客様はユーザー情報を登録してからご利用いただけます。";

  return (
    <DocumentMeta {...meta}>
      <Container maxWidth="xs">
        <Stack my={2} spacing={2}>
          <PageTitlePanel title={"会員登録"} spacing={1} description={description}/>
          <CustomerForm />
        </Stack>
      </Container>
    </DocumentMeta>
  );
};
