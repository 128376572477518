import AWSAppSyncClient from "aws-appsync";
import gql from "graphql-tag";
import {
  CreateSinglePaymentIntentInput,
  CreateSinglePaymentIntentMutation,
  CreateTicketPaymentIntentInput,
  CreateTicketPaymentIntentMutation,
  CreateTicketPaymentIntentResult,
} from "../API";
import { createSinglePaymentIntent, createTicketPaymentIntent } from "../mutations";

export const PaymentIntent = (
  shopId: string,
  authToken: string | null
) => {
  const comfirmTicketPaymentIntent = (
    input: CreateTicketPaymentIntentInput
  ) => {
    const mutation = gql(createTicketPaymentIntent);
    const appsyncClient = new AWSAppSyncClient({
      url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT!,
      region: process.env.REACT_APP_AWS_REGION!,
      auth: {
        type: "AWS_LAMBDA",
        token: authToken === null ? 'custome_76198d0f-350d-4829-901a-1ad9e899ff4b': `line_${authToken}`,
      },
      disableOffline: true,
    });
    return new Promise<CreateTicketPaymentIntentResult>(async (res, rej) => {
      await appsyncClient
        .mutate<CreateTicketPaymentIntentMutation>({
          variables: { createTicketPaymentIntentInput: input },
          mutation: mutation,
        })
        .then((result) => {
          ;
          res(
            result.data
              ?.createTicketPaymentIntent as CreateTicketPaymentIntentResult
          );
        });
    });
  };

  const comfirmSinglePaymentIntent = (
    input: CreateSinglePaymentIntentInput
  ) => {
    const mutation = gql(createSinglePaymentIntent);
    const appsyncClient = new AWSAppSyncClient({
      url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT!,
      region: process.env.REACT_APP_AWS_REGION!,
      auth: {
        type: "AWS_LAMBDA",
        token: authToken === null ? 'custome_76198d0f-350d-4829-901a-1ad9e899ff4b': `line_${authToken}`,
      },
      disableOffline: true,
    });
    return new Promise<CreateTicketPaymentIntentResult>(async (res, rej) => {
      await appsyncClient
        .mutate<CreateSinglePaymentIntentMutation>({
          variables: { createSinglePaymentIntentInput: input },
          mutation: mutation,
        })
        .then((result) => {
          ;
          res(
            result.data
              ?.createSinglePaymentIntent as CreateTicketPaymentIntentResult
          );
        });
    });
  };
  return { comfirmTicketPaymentIntent, comfirmSinglePaymentIntent};
};
