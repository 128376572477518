import liff from "@line/liff";
import { Container} from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  MasterTicket,
  TicketType,
} from "../DAL/API";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import { LoadingCard } from "../components/parts/card";
import React from "react";
import { ComfirmSubscriptionPanel } from "../components/layout/ComfirmSubscriptionPanel";
import { ComfirmTicketPanel } from "../components/layout/ComfirmTicketPanel";

export const MasterTicketComfirmPage = React.memo(({ children }) => {
  const { store, dispatch } = useApplicationStore();
  const { shopId, masterTicketId } = useParams();
  const [selectedTicket, setSelectedTicket] = useState<MasterTicket>();
  const [stripePubKey, setStripePubKey] = useState<string | null>(null);

  useEffect(() => {
    if (store.tickets === null || store.shop === null) {
      dispatch({
        type: "APP_DATA_FETCHING",
        payload: { shopId: shopId!, lineAccessToken: liff.getAccessToken() },
      });
    }
    if (store.tickets !== null) {
      const ticket = store.tickets.find(
        (s) => s.shopId === shopId && s.id === masterTicketId
      );
      setSelectedTicket(ticket);
    }
    if (store.shop !== null) {
      setStripePubKey(store.shop.stripePubKey!);
    }
  }, [store.tickets, store.shop]);

  return (
    <Container>
      {selectedTicket ? (
        selectedTicket.type === TicketType.SUBSCRIPTION ? (
          <ComfirmSubscriptionPanel
            ticket={selectedTicket}
            customer={store.customer}
            stripePubKey={stripePubKey}
          />
        ) : (
          <ComfirmTicketPanel
            ticket={selectedTicket}
            customer={store.customer}
            stripePubKey={stripePubKey}
          />
        )
      ) : (
        <LoadingCard />
      )}
    </Container>
  );
});
