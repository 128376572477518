import {
  customerByLineId,
  getCustomerAccount,
  getCustomer,
  listSchedules,
} from "../queries";
import {
  CreateCustomerAccountInput,
  CreateCustomerAccountMutation,
  Customer,
  CustomerAccount,
  CustomerByLineIdQuery,
  GetCustomerAccountQuery,
  GetCustomerQuery,
} from "../API";
import { createCustomerAccount, createCustomer } from "../mutations";
import gql from "graphql-tag";
import AWSAppSyncClient from "aws-appsync";
import { UserAccount } from "../schema";
import Stripe from "stripe";

export const CustomerRepository = (
  shopId: string,
  authToken: string | null
) => {
  
  const appsyncClient = new AWSAppSyncClient({
    url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT!,
    region: process.env.REACT_APP_AWS_REGION!,
    auth: {
      type: "AWS_LAMBDA",
      token: authToken === null ? 'custome_76198d0f-350d-4829-901a-1ad9e899ff4b': `line_${authToken}`,
    },
    disableOffline: true,
  });
  const getAccount = (id: string) => {
    const getCustomerQuery = gql(customerByLineId);
    const getCustomerAccountQuery = gql(getCustomerAccount);
    return new Promise<UserAccount>(async (res, rej) => {
      await appsyncClient
        .query<CustomerByLineIdQuery>({
          variables: {
            lineId: `line_${id}`,
            shopId: {
              eq: shopId,
            },
          },
          query: getCustomerQuery,
        })
        .then((customerResult) => {
          console.log(customerResult.data.customerByLineId?.items[0])
          appsyncClient
            .query<GetCustomerAccountQuery>({
              variables: {
                shopId: shopId,
                lineId: customerResult.data.customerByLineId?.items[0]?.id,
              },
              query: getCustomerAccountQuery,
            })
            .then((result) => {
              const methodList = JSON.parse(
                result.data?.getCustomerAccount?.stripePaymentMethodInfo!
              ) as Stripe.ApiList<Stripe.PaymentMethod>;
              const customer = {
                serviceAccount:
                  customerResult.data?.customerByLineId?.items.length! > 0
                    ? (customerResult.data?.customerByLineId
                        ?.items[0] as Customer)
                    : null,
                stripeAccount: JSON.parse(
                  result.data?.getCustomerAccount?.stripeCustomerInfo!
                ) as Stripe.Customer,
                stripePaymentMethods:
                  methodList === null
                    ? null
                    : methodList.data.length > 0
                    ? methodList.data
                    : null,
              } as UserAccount;
              console.log(customer)
              res(customer);
            });
        })
    });
  };

  const fetchCustomer = (id: string) => {
    const getCustomerQuery = gql(getCustomer);
    return appsyncClient
      .query<GetCustomerQuery>({
        variables: { shopId: shopId, id: id },
        query: getCustomerQuery,
      })
      .then((result) => {
        ;
        return result.data.getCustomer as Customer;
      })
      .catch((err) => {
        throw new Error("customer info was not found");
      });
  };

  const fetchStripeCustomerInfo = (customerId: string) =>{
    const getCustomerAccountQuery = gql(getCustomerAccount);
    return appsyncClient
    .query<GetCustomerAccountQuery>({
      variables: {
        shopId: shopId,
        lineId: customerId,
      },
      query: getCustomerAccountQuery,
    })
    .then((result) => {
      ;
      const methodList = JSON.parse(
        result.data?.getCustomerAccount?.stripePaymentMethodInfo!
      ) as Stripe.ApiList<Stripe.PaymentMethod>;
      const customer = {
        stripeAccount: JSON.parse(
          result.data?.getCustomerAccount?.stripeCustomerInfo!
        ) as Stripe.Customer,
        stripePaymentMethods:
          methodList === null
            ? null
            : methodList.data.length > 0
            ? methodList.data
            : null,
      } 
      return customer;
    });
  }

  const create = (formState: CreateCustomerAccountInput) => {
    const mutation = gql(createCustomerAccount);
    const appsyncClient = new AWSAppSyncClient({
      url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT!,
      region: process.env.REACT_APP_AWS_REGION!,
      auth: {
        type: "AWS_LAMBDA",
        token: authToken === null ? 'custome_76198d0f-350d-4829-901a-1ad9e899ff4b': `line_${authToken}`,
      },
      disableOffline: true,
    });
    return new Promise<UserAccount>(async (res, rej) => {
      await appsyncClient
        .mutate<CreateCustomerAccountMutation>({
          variables: { createCustomerAccountInput: formState },
          mutation: mutation,
        })
        .then((result) => {
          ;
          //const stringObj = JSON.parse(result.data?.createCustomerAccount?.stripeCustomerInfo!)
          const customer = {
            serviceAccount: result.data?.createCustomerAccount
              ?.serviceCustomerInfo as Customer,
            stripeAccount: JSON.parse(
              result.data?.createCustomerAccount?.stripeCustomerInfo!
            ) as Stripe.Customer,
            stripePaymentMethods: null,
          } as UserAccount;
          res(customer);
        });
    });
  };
  return { create, getAccount, fetchCustomer, fetchStripeCustomerInfo };
};
