import {
  Card,
  Typography,
  Divider,
  Stack,
  Box,
  Button,
  Container,
} from "@mui/material";
import React from "react";
import { MasterTicket, PurchasedTicket } from "../../../DAL/API";
import moment from "moment";

type Props = {
  ticket: PurchasedTicket | MasterTicket;
};
export const MasterTicketCard: React.FC<Props> = React.memo(
  ({ ticket, children }) => {
    return (
      <Card
        variant="outlined"
        elevation={0}
        sx={{ margin: "12px 0 32px", padding: "12px" }}
      >
        <Typography fontWeight={600}>{ticket?.name}</Typography>
        <Divider sx={{ margin: "6px 0 12px" }} />
        <Box>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography>金額:</Typography>
              <Typography>{ticket?.price.toLocaleString()}円</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>1月あたりの利用回数:</Typography>
              <Typography>{ticket?.maxCount}回</Typography>
            </Stack>
            {ticket?.hasDailyLimit ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography>1日あたりの利用可能回数:</Typography>
                <Typography>{ticket?.limitPerDaily}回</Typography>
              </Stack>
            ) : null}
            {ticket?.hasWeeklyLimit ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography>週あたりの利用可能回数:</Typography>
                <Typography>{ticket?.limitPerWeekly}回</Typography>
              </Stack>
            ) : null}

            {children}
          </Stack>
        </Box>
      </Card>
    );
  }
);
