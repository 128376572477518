import { Button, Card, Chip, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import { PurchaseCardProps } from "./types";

const FirstPurchaseCard = React.memo<PurchaseCardProps>(
  ({ ticket, shopId, lessonId, chipTxt, btnTxt, style }) => {
    return (
      <Card variant="outlined" elevation={0}>
        <Grid container p={1}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Chip label={chipTxt} />
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Stack key={ticket.id}>
                <Grid container justifyContent="space-between" mt={2} mb={1}>
                  <Grid item>
                    <Typography>{ticket.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>¥{ticket.price.toLocaleString()}</Typography>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  disableElevation
                  component={Link}
                  to={`/s/${shopId}/sc/${lessonId}/reserve?type=${ticket.type}&ticket_id=${ticket.id}&style=${style}`}
                >
                  {btnTxt}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    );
  }
);

export default FirstPurchaseCard;
