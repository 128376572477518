import { Stack, Box, Typography, Divider, TextField } from "@mui/material";
import React from "react";
import { Customer } from "../../DAL/API";

type Props = {
  user: Customer | undefined | null
}
export const UserInfoComfirmationPanel = React.memo<Props>(({user}) => {
  return (
    <Stack spacing={1}>
      <Box>
        <Typography variant="h5" fontWeight={600}>
          お客さま情報
        </Typography>
        <Divider sx={{ margin: "6px 0 0" }} />
      </Box>
      <Stack spacing={2}>
        <TextField
          margin="dense"
          disabled
          id="name"
          label="氏名"
          fullWidth
          variant="standard"
          placeholder="氏名"
          value={user?.name}
        />
        <TextField
          margin="dense"
          disabled
          id="email"
          label="メールアドレス"
          fullWidth
          variant="standard"
          placeholder="example@feelfree2yoga.net"
          value={user?.email}
        />
        <Typography variant="caption">
          お客様情報を変更する際は、マイページのアカウント情報編集から変更してください。
        </Typography>
      </Stack>
    </Stack>
  );
});


