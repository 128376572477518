import { useEffect, useState } from "react";
import { TextCard } from "../../components/parts/card";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";

export const CustomerSignupPage = () => {
  const [isUser, setIsUser] = useState<boolean>(false);
  const { store } = useApplicationStore();
  useEffect(() => {
    if (typeof store.customer?.serviceAccount.id === "undefined") {
      setIsUser(false);
    } else {
      setIsUser(true);
    }
  }, [store.customer]);
  return <>新規アカウント登録</> 
};
