export const onUpdateReservation = /* GraphQL */ `
  subscription OnUpdateReservation($shopId: String) {
    onUpdateReservation(shopId: $shopId) {
      shopId
      id
      scheduleId
      customerId
      lessonStyle
      status
    }
  }
`;
export const onUpdateReservationByCustomerId = /* GraphQL */ `
  subscription OnUpdateReservationByCustomerId($customerId: ID!) {
    onUpdateReservationByCustomerId(customerId: $customerId) {
      shopId
      id
      scheduleId
      customerId
      status
    }
  }
`;