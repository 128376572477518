import { Card, Grid, Chip, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { SinglePurchaseCardProps } from "./types";

const SinglePurchaseCard = React.memo<SinglePurchaseCardProps>(
  ({ shopId, lessonId, chipTxt, btnTxt,name, price, type, style, ...props }) => {
    return (
      <Card variant="outlined" elevation={0}>
        <Grid container p={1}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Chip label={chipTxt} />
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Grid container justifyContent="space-between" mt={2} mb={1}>
                <Grid item>
                  <Typography>{name}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    ¥{price.toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                disableElevation
                component={Link}
                to={`/s/${shopId}/sc/${lessonId}/reserve?type=${type}&style=${style}`}
              >
                {btnTxt}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    );
  }
);

export default SinglePurchaseCard;
