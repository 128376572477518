import "./App.css";

import { Amplify } from "aws-amplify";
import { SnackbarProvider } from "notistack";
import {
  ApplicationStoreProvider,
  useApplicationStore,
} from "./provider/ApplicationStoreProvider";
import { AppRouter } from "./route";
import { ApplicationPreferenceProvider } from "./provider/ApplicationPreferenceProvider";
import { Authenticator } from "@aws-amplify/ui-react";
import { PasswordTwoTone } from "@mui/icons-material";

try {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
      region: process.env.REACT_APP_AWS_REGION, // REQUIRED - Amazon Cognito Region
      userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: process.env.REACT_APP_AUTH_USER_WEB_CLIENT_ID, //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_AWS_S3_BUCKET,
        region: process.env.REACT_APP_AWS_REGION,
      },
    },
    aws_appsync_graphqlEndpoint:
      process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_AWS_REGION,
    aws_appsync_authenticationType:
      process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
    graphql_endpoint_iam_region: process.env.REACT_APP_AWS_REGION,
  });
} catch (err) {}

//pro
/*
Amplify.configure({
  Auth: {
    identityPoolId: "ap-northeast-1:a6464f8e-7eb0-4b13-9acf-ab1ce6e050c7", //REQUIRED - Amazon Cognito Identity Pool ID
    region: "ap-northeast-1", // REQUIRED - Amazon Cognito Region
    userPoolId: "ap-northeast-1_8v3RSbQTa", //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: "5cnsd8q0lq5jmtvavcsm2ucq1g", //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: "feelfree2yogaf42876dbaec8413f844c51c50677ea2a162501-prod",
      region: "ap-northeast-1",
    },
  },
  aws_appsync_graphqlEndpoint:
    "https://kxlxfvrzznebldmk62gjc2sroq.appsync-api.ap-northeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-northeast-1",
  aws_appsync_authenticationType: "AWS_IAM",
    API:{
    graphql_headers:{
      'Authorization':'eyJraWQiOiI2YWE4YWQwN2NkMmFhYWRjYzY1NmY3ZTIxMzljY2U4YjhjNGE2YzgxYzI5MDQyZjQ4MTY4MDY3MmZkMDNjOTY5IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2FjY2Vzcy5saW5lLm1lIiwic3ViIjoiVTc5YWU4ZTVkNjI4N2ZkYzhhMzgxOWFhMTQ2YTVkNTczIiwiYXVkIjoiMTY1NjUwODMxNiIsImV4cCI6MTY1NzgxMTAyMywiaWF0IjoxNjU3ODA3NDIzLCJhbXIiOlsibGluZXNzbyJdLCJuYW1lIjoiS2F6dW5vcmkgS2l0YXp1bWUiLCJwaWN0dXJlIjoiaHR0cHM6Ly9wcm9maWxlLmxpbmUtc2Nkbi5uZXQvMGg1NEwzdzhRdmFodHhTa2RFaWo4VlRFMFBaSFlHWkd4VENTVWllMTFOTnloVkwzcFBUbjRrTGxaQ1ppSlVmU3hJVEg0bmV3UWVOQ2dQIn0.jo9nAmfCuNph6merK6sp-yQLkWHqd--m2qoUtLIErVzz_RTyr4TLFANVog0MzMgHJv0CK4DLvjlRrU2s5NcMjg'
    }
  }
});
*/

function App() {
  return (
    <ApplicationStoreProvider>
      <ApplicationPreferenceProvider>
        <AppRouter />
        {/*<SnackbarProvider maxSnack={4}>
          <AppRouter />
  </SnackbarProvider>*/}
      </ApplicationPreferenceProvider>
    </ApplicationStoreProvider>
  );
}

export default App;
