/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchAddLessonMasterTickets = /* GraphQL */ `
  mutation BatchAddLessonMasterTickets(
    $lessonMasterTicketInputs: [LessonMasterTicketInput]
  ) {
    batchAddLessonMasterTickets(
      lessonMasterTicketInputs: $lessonMasterTicketInputs
    ) {
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      masterTicketId
      masterTicket {
        id
        shopId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        isPrivate
        privateScopedCustomerId
        lessons {
          nextToken
        }
        isAvailable
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const batchDeleteLessonMasterTickets = /* GraphQL */ `
  mutation BatchDeleteLessonMasterTickets($ids: [ID]) {
    batchDeleteLessonMasterTickets(ids: $ids) {
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      masterTicketId
      masterTicket {
        id
        shopId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        isPrivate
        privateScopedCustomerId
        lessons {
          nextToken
        }
        isAvailable
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const batchAddSchedules = /* GraphQL */ `
  mutation BatchAddSchedules($batchAddScheduleInput: BatchAddScheduleInput) {
    batchAddSchedules(batchAddScheduleInput: $batchAddScheduleInput) {
      shopId
      data {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        expiredAt
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        reservations {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPreReservation = /* GraphQL */ `
  mutation CreatePreReservation(
    $preReservationInput: CreatePreReservationInput
    $reserveUsingTicketInput: ReserveUsingTicketInput
  ) {
    createPreReservation(
      preReservationInput: $preReservationInput
      reserveUsingTicketInput: $reserveUsingTicketInput
    ) {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTicketWithStripe = /* GraphQL */ `
  mutation CreateTicketWithStripe($ticketInput: CreateTicketWithStripeInput) {
    createTicketWithStripe(ticketInput: $ticketInput) {
      stripeProductId
      stripePriceId
    }
  }
`;
export const createCustomerAccount = /* GraphQL */ `
  mutation CreateCustomerAccount(
    $createCustomerAccountInput: CreateCustomerAccountInput
  ) {
    createCustomerAccount(
      createCustomerAccountInput: $createCustomerAccountInput
    ) {
      serviceCustomerInfo {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      stripeCustomerInfo
      stripePaymentMethodInfo
    }
  }
`;
export const createSetupIntent = /* GraphQL */ `
  mutation CreateSetupIntent($createSetupIntentInput: CreateSetupIntentInput) {
    createSetupIntent(createSetupIntentInput: $createSetupIntentInput) {
      clientSecret
    }
  }
`;
export const createTicketPaymentIntent = /* GraphQL */ `
  mutation CreateTicketPaymentIntent(
    $createTicketPaymentIntentInput: CreateTicketPaymentIntentInput
  ) {
    createTicketPaymentIntent(
      createTicketPaymentIntentInput: $createTicketPaymentIntentInput
    ) {
      isError
      errMessage
      paymentIntentData
      ticket {
        shopId
        id
        masterTicketId
        paymentIntentId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        currentCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        lastUsedAt
        lastOnlineUsedAt
        purchasedAt
        expireAt
        isAvailable
        histories {
          title
          operation
          createdAt
        }
      }
    }
  }
`;
export const createSinglePaymentIntent = /* GraphQL */ `
  mutation CreateSinglePaymentIntent(
    $createSinglePaymentIntentInput: CreateSinglePaymentIntentInput
  ) {
    createSinglePaymentIntent(
      createSinglePaymentIntentInput: $createSinglePaymentIntentInput
    ) {
      isError
      errMessage
      paymentIntentData
      ticket {
        shopId
        id
        masterTicketId
        paymentIntentId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        currentCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        lastUsedAt
        lastOnlineUsedAt
        purchasedAt
        expireAt
        isAvailable
        histories {
          title
          operation
          createdAt
        }
      }
    }
  }
`;
export const createSubscription = /* GraphQL */ `
  mutation CreateSubscription(
    $createSubscriptionInput: CreateSubscriptionInput
  ) {
    createSubscription(createSubscriptionInput: $createSubscriptionInput) {
      isError
      errMessage
      paymentIntentData
      ticket {
        shopId
        id
        masterTicketId
        paymentIntentId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        currentCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        lastUsedAt
        lastOnlineUsedAt
        purchasedAt
        expireAt
        isAvailable
        histories {
          title
          operation
          createdAt
        }
      }
    }
  }
`;
export const consumePurchasedTicket = /* GraphQL */ `
  mutation ConsumePurchasedTicket(
    $consumePurchasedTicketInput: ConsumePurchasedTicketInput
  ) {
    consumePurchasedTicket(
      consumePurchasedTicketInput: $consumePurchasedTicketInput
    ) {
      shopId
      customerId
      purchasedTicketId
    }
  }
`;
export const recoverPurchasedTicket = /* GraphQL */ `
  mutation RecoverPurchasedTicket(
    $recoverPurchasedTicketInput: RecoverPurchasedTicketInput
  ) {
    recoverPurchasedTicket(
      recoverPurchasedTicketInput: $recoverPurchasedTicketInput
    ) {
      shopId
      customerId
      purchasedTicketId
    }
  }
`;
export const setZoomAuthToken = /* GraphQL */ `
  mutation SetZoomAuthToken($setZoomAuthTokenInput: SetZoomAuthTokenInput) {
    setZoomAuthToken(setZoomAuthTokenInput: $setZoomAuthTokenInput) {
      accountEmail
      accessToken
      tokenType
      refreshToken
      expiresIn
      scope
      updatedAt
      dailyCreateMeetingThrottling
    }
  }
`;
export const createZoomMeetings = /* GraphQL */ `
  mutation CreateZoomMeetings($createZoomMeetingInput: CreateZoomMeetingInput) {
    createZoomMeetings(createZoomMeetingInput: $createZoomMeetingInput) {
      zoomInfo {
        scheduleId
        zoomMeetingId
        start_url
        join_url
        password
      }
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      address
      email
      tel
      profile
      icon
      stripePubKey
      stripeSecKey
      mode
      stripe {
        dev {
          pubKey
          secKey
        }
        prod {
          pubKey
          secKey
        }
      }
      zoom {
        accountEmail
        accessToken
        tokenType
        refreshToken
        expiresIn
        scope
        updatedAt
        dailyCreateMeetingThrottling
      }
      dayoff
      staffs {
        shopId
        id
        name
      }
      schedules {
        items {
          shopId
          id
          lessonId
          start
          end
          expiredAt
          zoomMeetingId
          link
          pass
          isPublished
          studio_capacity
          online_capacity
          acceptableStudioNumber
          acceptableOnlineNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          shopId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          isPrivate
          privateScopedCustomerId
          isAvailable
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        nextToken
      }
      customers {
        items {
          shopId
          id
          stripeId
          name
          lineId
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      address
      email
      tel
      profile
      icon
      stripePubKey
      stripeSecKey
      mode
      stripe {
        dev {
          pubKey
          secKey
        }
        prod {
          pubKey
          secKey
        }
      }
      zoom {
        accountEmail
        accessToken
        tokenType
        refreshToken
        expiresIn
        scope
        updatedAt
        dailyCreateMeetingThrottling
      }
      dayoff
      staffs {
        shopId
        id
        name
      }
      schedules {
        items {
          shopId
          id
          lessonId
          start
          end
          expiredAt
          zoomMeetingId
          link
          pass
          isPublished
          studio_capacity
          online_capacity
          acceptableStudioNumber
          acceptableOnlineNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          shopId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          isPrivate
          privateScopedCustomerId
          isAvailable
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        nextToken
      }
      customers {
        items {
          shopId
          id
          stripeId
          name
          lineId
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      address
      email
      tel
      profile
      icon
      stripePubKey
      stripeSecKey
      mode
      stripe {
        dev {
          pubKey
          secKey
        }
        prod {
          pubKey
          secKey
        }
      }
      zoom {
        accountEmail
        accessToken
        tokenType
        refreshToken
        expiresIn
        scope
        updatedAt
        dailyCreateMeetingThrottling
      }
      dayoff
      staffs {
        shopId
        id
        name
      }
      schedules {
        items {
          shopId
          id
          lessonId
          start
          end
          expiredAt
          zoomMeetingId
          link
          pass
          isPublished
          studio_capacity
          online_capacity
          acceptableStudioNumber
          acceptableOnlineNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          shopId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          isPrivate
          privateScopedCustomerId
          isAvailable
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        nextToken
      }
      customers {
        items {
          shopId
          id
          stripeId
          name
          lineId
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      shopId
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      expiredAt
      zoomMeetingId
      link
      pass
      isPublished
      studio_capacity
      online_capacity
      acceptableStudioNumber
      acceptableOnlineNumber
      reservations {
        items {
          shopId
          id
          scheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          expiredAt
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      shopId
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      expiredAt
      zoomMeetingId
      link
      pass
      isPublished
      studio_capacity
      online_capacity
      acceptableStudioNumber
      acceptableOnlineNumber
      reservations {
        items {
          shopId
          id
          scheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          expiredAt
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      shopId
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      expiredAt
      zoomMeetingId
      link
      pass
      isPublished
      studio_capacity
      online_capacity
      acceptableStudioNumber
      acceptableOnlineNumber
      reservations {
        items {
          shopId
          id
          scheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          expiredAt
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createExpiredSchedule = /* GraphQL */ `
  mutation CreateExpiredSchedule(
    $input: CreateExpiredScheduleInput!
    $condition: ModelExpiredScheduleConditionInput
  ) {
    createExpiredSchedule(input: $input, condition: $condition) {
      shopId
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      zoomMeetingId
      link
      pass
      isPublished
      studio_capacity
      online_capacity
      acceptableStudioNumber
      acceptableOnlineNumber
      expiredReservations {
        items {
          shopId
          id
          expiredScheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateExpiredSchedule = /* GraphQL */ `
  mutation UpdateExpiredSchedule(
    $input: UpdateExpiredScheduleInput!
    $condition: ModelExpiredScheduleConditionInput
  ) {
    updateExpiredSchedule(input: $input, condition: $condition) {
      shopId
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      zoomMeetingId
      link
      pass
      isPublished
      studio_capacity
      online_capacity
      acceptableStudioNumber
      acceptableOnlineNumber
      expiredReservations {
        items {
          shopId
          id
          expiredScheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteExpiredSchedule = /* GraphQL */ `
  mutation DeleteExpiredSchedule(
    $input: DeleteExpiredScheduleInput!
    $condition: ModelExpiredScheduleConditionInput
  ) {
    deleteExpiredSchedule(input: $input, condition: $condition) {
      shopId
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      zoomMeetingId
      link
      pass
      isPublished
      studio_capacity
      online_capacity
      acceptableStudioNumber
      acceptableOnlineNumber
      expiredReservations {
        items {
          shopId
          id
          expiredScheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      shopId
      id
      scheduleId
      schedule {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        expiredAt
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        reservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      lessonStyle
      status
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      isTrial
      consumedTicketId
      expiredAt
      createdAt
      updatedAt
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      shopId
      id
      scheduleId
      schedule {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        expiredAt
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        reservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      lessonStyle
      status
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      isTrial
      consumedTicketId
      expiredAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      shopId
      id
      scheduleId
      schedule {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        expiredAt
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        reservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      lessonStyle
      status
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      isTrial
      consumedTicketId
      expiredAt
      createdAt
      updatedAt
    }
  }
`;
export const createReservationTransaction = /* GraphQL */ `
  mutation CreateReservationTransaction(
    $input: CreateReservationTransactionInput!
    $condition: ModelReservationTransactionConditionInput
  ) {
    createReservationTransaction(input: $input, condition: $condition) {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReservationTransaction = /* GraphQL */ `
  mutation UpdateReservationTransaction(
    $input: UpdateReservationTransactionInput!
    $condition: ModelReservationTransactionConditionInput
  ) {
    updateReservationTransaction(input: $input, condition: $condition) {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReservationTransaction = /* GraphQL */ `
  mutation DeleteReservationTransaction(
    $input: DeleteReservationTransactionInput!
    $condition: ModelReservationTransactionConditionInput
  ) {
    deleteReservationTransaction(input: $input, condition: $condition) {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createExpiredReservation = /* GraphQL */ `
  mutation CreateExpiredReservation(
    $input: CreateExpiredReservationInput!
    $condition: ModelExpiredReservationConditionInput
  ) {
    createExpiredReservation(input: $input, condition: $condition) {
      shopId
      id
      expiredScheduleId
      expiredSchedule {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      lessonStyle
      status
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      isTrial
      consumedTicketId
      createdAt
      updatedAt
    }
  }
`;
export const updateExpiredReservation = /* GraphQL */ `
  mutation UpdateExpiredReservation(
    $input: UpdateExpiredReservationInput!
    $condition: ModelExpiredReservationConditionInput
  ) {
    updateExpiredReservation(input: $input, condition: $condition) {
      shopId
      id
      expiredScheduleId
      expiredSchedule {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      lessonStyle
      status
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      isTrial
      consumedTicketId
      createdAt
      updatedAt
    }
  }
`;
export const deleteExpiredReservation = /* GraphQL */ `
  mutation DeleteExpiredReservation(
    $input: DeleteExpiredReservationInput!
    $condition: ModelExpiredReservationConditionInput
  ) {
    deleteExpiredReservation(input: $input, condition: $condition) {
      shopId
      id
      expiredScheduleId
      expiredSchedule {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      lessonStyle
      status
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      isTrial
      consumedTicketId
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      shopId
      id
      stripeId
      name
      lineId
      email
      purchasedTickets {
        shopId
        id
        masterTicketId
        paymentIntentId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        currentCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        lastUsedAt
        lastOnlineUsedAt
        purchasedAt
        expireAt
        isAvailable
        histories {
          title
          operation
          createdAt
        }
      }
      reservations {
        items {
          shopId
          id
          scheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          expiredAt
          createdAt
          updatedAt
        }
        nextToken
      }
      expiredReservations {
        items {
          shopId
          id
          expiredScheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      shopId
      id
      stripeId
      name
      lineId
      email
      purchasedTickets {
        shopId
        id
        masterTicketId
        paymentIntentId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        currentCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        lastUsedAt
        lastOnlineUsedAt
        purchasedAt
        expireAt
        isAvailable
        histories {
          title
          operation
          createdAt
        }
      }
      reservations {
        items {
          shopId
          id
          scheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          expiredAt
          createdAt
          updatedAt
        }
        nextToken
      }
      expiredReservations {
        items {
          shopId
          id
          expiredScheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      shopId
      id
      stripeId
      name
      lineId
      email
      purchasedTickets {
        shopId
        id
        masterTicketId
        paymentIntentId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        currentCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        lastUsedAt
        lastOnlineUsedAt
        purchasedAt
        expireAt
        isAvailable
        histories {
          title
          operation
          createdAt
        }
      }
      reservations {
        items {
          shopId
          id
          scheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          expiredAt
          createdAt
          updatedAt
        }
        nextToken
      }
      expiredReservations {
        items {
          shopId
          id
          expiredScheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMasterTicket = /* GraphQL */ `
  mutation CreateMasterTicket(
    $input: CreateMasterTicketInput!
    $condition: ModelMasterTicketConditionInput
  ) {
    createMasterTicket(input: $input, condition: $condition) {
      id
      shopId
      stripeProductId
      stripePriceId
      name
      description
      maxCount
      price
      type
      deadline {
        type
        num
      }
      hasDailyLimit
      limitPerDaily
      hasOnlineDailyLimit
      onlineLimitPerDaily
      hasWeeklyLimit
      limitPerWeekly
      hasOnlineWeeklyLimit
      onlineLimitPerWeekly
      isPrivate
      privateScopedCustomerId
      lessons {
        items {
          id
          lessonId
          masterTicketId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isAvailable
      createdAt
      updatedAt
    }
  }
`;
export const updateMasterTicket = /* GraphQL */ `
  mutation UpdateMasterTicket(
    $input: UpdateMasterTicketInput!
    $condition: ModelMasterTicketConditionInput
  ) {
    updateMasterTicket(input: $input, condition: $condition) {
      id
      shopId
      stripeProductId
      stripePriceId
      name
      description
      maxCount
      price
      type
      deadline {
        type
        num
      }
      hasDailyLimit
      limitPerDaily
      hasOnlineDailyLimit
      onlineLimitPerDaily
      hasWeeklyLimit
      limitPerWeekly
      hasOnlineWeeklyLimit
      onlineLimitPerWeekly
      isPrivate
      privateScopedCustomerId
      lessons {
        items {
          id
          lessonId
          masterTicketId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isAvailable
      createdAt
      updatedAt
    }
  }
`;
export const deleteMasterTicket = /* GraphQL */ `
  mutation DeleteMasterTicket(
    $input: DeleteMasterTicketInput!
    $condition: ModelMasterTicketConditionInput
  ) {
    deleteMasterTicket(input: $input, condition: $condition) {
      id
      shopId
      stripeProductId
      stripePriceId
      name
      description
      maxCount
      price
      type
      deadline {
        type
        num
      }
      hasDailyLimit
      limitPerDaily
      hasOnlineDailyLimit
      onlineLimitPerDaily
      hasWeeklyLimit
      limitPerWeekly
      hasOnlineWeeklyLimit
      onlineLimitPerWeekly
      isPrivate
      privateScopedCustomerId
      lessons {
        items {
          id
          lessonId
          masterTicketId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isAvailable
      createdAt
      updatedAt
    }
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson(
    $input: CreateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    createLesson(input: $input, condition: $condition) {
      id
      shopId
      name
      thumbnail
      description
      duration
      style
      canSingle
      singlePrice
      hasTrial
      trialPrice
      hasTicket
      masterTickets {
        items {
          id
          lessonId
          masterTicketId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      canPublish
      studio_capacity
      online_capacity
      requiredTime {
        timeUnit
        value
      }
      staff {
        shopId
        id
        name
      }
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson(
    $input: UpdateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    updateLesson(input: $input, condition: $condition) {
      id
      shopId
      name
      thumbnail
      description
      duration
      style
      canSingle
      singlePrice
      hasTrial
      trialPrice
      hasTicket
      masterTickets {
        items {
          id
          lessonId
          masterTicketId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      canPublish
      studio_capacity
      online_capacity
      requiredTime {
        timeUnit
        value
      }
      staff {
        shopId
        id
        name
      }
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson(
    $input: DeleteLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    deleteLesson(input: $input, condition: $condition) {
      id
      shopId
      name
      thumbnail
      description
      duration
      style
      canSingle
      singlePrice
      hasTrial
      trialPrice
      hasTicket
      masterTickets {
        items {
          id
          lessonId
          masterTicketId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      canPublish
      studio_capacity
      online_capacity
      requiredTime {
        timeUnit
        value
      }
      staff {
        shopId
        id
        name
      }
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const createLessonMasterTickets = /* GraphQL */ `
  mutation CreateLessonMasterTickets(
    $input: CreateLessonMasterTicketsInput!
    $condition: ModelLessonMasterTicketsConditionInput
  ) {
    createLessonMasterTickets(input: $input, condition: $condition) {
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      masterTicketId
      masterTicket {
        id
        shopId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        isPrivate
        privateScopedCustomerId
        lessons {
          nextToken
        }
        isAvailable
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLessonMasterTickets = /* GraphQL */ `
  mutation UpdateLessonMasterTickets(
    $input: UpdateLessonMasterTicketsInput!
    $condition: ModelLessonMasterTicketsConditionInput
  ) {
    updateLessonMasterTickets(input: $input, condition: $condition) {
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      masterTicketId
      masterTicket {
        id
        shopId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        isPrivate
        privateScopedCustomerId
        lessons {
          nextToken
        }
        isAvailable
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLessonMasterTickets = /* GraphQL */ `
  mutation DeleteLessonMasterTickets(
    $input: DeleteLessonMasterTicketsInput!
    $condition: ModelLessonMasterTicketsConditionInput
  ) {
    deleteLessonMasterTickets(input: $input, condition: $condition) {
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      masterTicketId
      masterTicket {
        id
        shopId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        isPrivate
        privateScopedCustomerId
        lessons {
          nextToken
        }
        isAvailable
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
