import liff from "@line/liff";
import { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ScheduleDetail } from "../components/ScheduleDetail";
import { LessonStyle, Schedule } from "../DAL/API";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";
import { ScheduleRepository } from "../DAL/repositories/ScheduleRepository";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";

export const SchedulePage: React.FC = ({ children }) => {

  const {store, dispatch} = useApplicationStore();
  const { shopId, scheduleId } = useParams();
  const [searchParams] = useSearchParams();
  const [schedule, setSchedule] = useState<Schedule>();
  const customerDataClient = CustomerRepository(shopId!, liff.getAccessToken())
  const [lessonStyle, setLessonStyle] = useState<LessonStyle>();

  useEffect(() => {
    if (store.schedules === null) {
      dispatch({
        type: "APP_DATA_FETCHING",
        payload: { shopId: shopId!, lineAccessToken: liff.getAccessToken()},
      });
    } else {
      setSchedule(
        store.schedules.find((s) => s.shopId === shopId && s.id === scheduleId)
      );
      const day = new Date(store.schedules.find((s) => s.shopId === shopId && s.id === scheduleId)?.end!)
      const num = new Date(day.toISOString()).setDate(day.getDate()+30)
      console.log(new Date(num))
    }
    setLessonStyle(searchParams.get("style") === "STUDIO" ? LessonStyle.STUDIO : LessonStyle.ONLINE)
  }, [store.schedules]);
  return  schedule ? <ScheduleDetail schedule={schedule} style={lessonStyle!}/> : null;
};
