import { formatDate } from "@fullcalendar/react";
import { Stack, Box, Typography, Divider, Paper } from "@mui/material";
import React from "react";
import { MasterTicket } from "../../DAL/API";
import { MasterTicketCard } from "../parts/card/MasterTicketCard";

type Props = {
  ticket: MasterTicket | undefined | null;
};
export const SubscriptionInfoComfirmationPanel: React.FC<Props> = React.memo(
  ({ ticket }) => {
    const today = new Date()
    return (
      <Stack spacing={1}>
        <Box>
          <Typography variant="h5" fontWeight={600}>
            月謝制申込内容確認
          </Typography>
          <Divider sx={{ margin: "6px 0 0" }} />
        </Box>
        <MasterTicketCard ticket={ticket!}>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography>請求サイクル:</Typography>
              <Typography>毎月</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>初回請求日:</Typography>
              <Typography>
                {formatDate(today, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  locale: "ja",
                })}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>次回請求日:</Typography>
              <Typography>
                {formatDate( new Date().setMonth(today.getMonth()+1), {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  locale: "ja",
                })}
              </Typography>
            </Stack>
          </Stack>
        </MasterTicketCard>
      </Stack>
    );
  }
);
