import { formatDate } from "@fullcalendar/react";
import { Card, Stack, Chip, Typography, Divider, Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  LessonStyle,
  PurchasedTicket,
  Schedule,
  TicketType,
} from "../../../DAL/API";
import { useApplicationStore } from "../../../provider/ApplicationStoreProvider";

type Props = {
  ticket: PurchasedTicket;
  schedule: Schedule;
  style: LessonStyle;
};
export const AvailablePurchasedTicketCard: React.FC<Props> = React.memo(
  ({ ticket, schedule, style }) => {
    const { store } = useApplicationStore();
    const [isAvailable, setIsAvailable] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>();

    const isExpired = useCallback(() => {
      if (ticket?.expireAt === null || ticket.expireAt === undefined)
        return false;
      const limit = new Date(parseInt(ticket?.expireAt)).getTime();
      return limit + 24 * 60 * 60 * 1000 <= new Date(schedule.end).getTime();
      //return limit + 24 * 60 * 60 * 1000 <= Date.now();
    }, [schedule.end, ticket.expireAt]);

    const checkUserLastUsage = useCallback(() => {
      const customerReservations = store.customer?.serviceAccount.reservations?.items
      const customerPurchasedTicketIds = store.customer?.serviceAccount.purchasedTickets?.map(pt=>pt?.id)
      if (ticket.hasDailyLimit) {
        const reservations =
          customerReservations?.filter(
            (reservation) => {
              const day = new Date(schedule.start);
              const start = new Date(
                day.getFullYear(),
                day.getMonth(),
                day.getDate(),
                0,
                0
              ).getTime();
              const end = new Date(
                day.getFullYear(),
                day.getMonth(),
                day.getDate(),
                23,
                59,
                59,
                999
              ).getTime();
              const schedule_end = new Date(
                reservation?.schedule?.end!
              ).getTime();
              return (
                start <= schedule_end &&
                schedule_end <= end &&
                reservation?.lessonStyle === LessonStyle.STUDIO &&
                customerPurchasedTicketIds?.includes(reservation.consumedTicketId!) 
              );
            }
          );

        if (reservations?.length! >= ticket?.limitPerDaily!) {
          setIsAvailable(false);
          setErrorMessage("同日の予約件数上限です。");
        }
      }
      if (ticket.hasOnlineDailyLimit) {
        const reservations =
          customerReservations?.filter(
            (reservation) => {
              const day = new Date(schedule.start);
              const start = new Date(
                day.getFullYear(),
                day.getMonth(),
                day.getDate(),
                0,
                0
              ).getTime();
              const end = new Date(
                day.getFullYear(),
                day.getMonth(),
                day.getDate(),
                23,
                59,
                59,
                999
              ).getTime();
              const schedule_end = new Date(
                reservation?.schedule?.end!
              ).getTime();
              return (
                start <= schedule_end &&
                schedule_end <= end &&
                reservation?.lessonStyle === LessonStyle.ONLINE &&
                customerPurchasedTicketIds?.includes(reservation.consumedTicketId!) 
              );
            }
          );
        if (reservations?.length! >= ticket?.limitPerDaily!) {
          setIsAvailable(false);
          setErrorMessage("の予約件数上限です。");
        }
      }
      if (ticket.hasWeeklyLimit) {
        const reservations =
          customerReservations?.filter(
            (reservation) => {
              const day = new Date(schedule.start);
              const start = new Date(
                day.getFullYear(),
                day.getMonth(),
                day.getDate() - 3,
                0,
                0
              ).getTime();
              const end = new Date(
                day.getFullYear(),
                day.getMonth(),
                day.getDate() + 3,
                23,
                59,
                59,
                999
              ).getTime();
              const schedule_end = new Date(
                reservation?.schedule?.end!
              ).getTime();
              return (
                start <= schedule_end &&
                schedule_end <= end &&
                reservation?.lessonStyle === LessonStyle.STUDIO &&
                customerPurchasedTicketIds?.includes(reservation.consumedTicketId!) 
              );
            }
          );
        if (reservations?.length! >= ticket?.limitPerWeekly!) {
          setIsAvailable(false);
          setErrorMessage("週間予約件数上限です。");
        }
      }
      if (ticket.hasOnlineWeeklyLimit) {
        const reservations =
          customerReservations?.filter(
            (reservation) => {
              const day = new Date(schedule.start);
              const start = new Date(
                day.getFullYear(),
                day.getMonth(),
                day.getDate() - 3,
                0,
                0
              ).getTime();
              const end = new Date(
                day.getFullYear(),
                day.getMonth(),
                day.getDate() + 3,
                23,
                59,
                59,
                999
              ).getTime();
              const schedule_end = new Date(
                reservation?.schedule?.end!
              ).getTime();
              return (
                start <= schedule_end &&
                schedule_end <= end &&
                reservation?.lessonStyle === LessonStyle.ONLINE &&
                customerPurchasedTicketIds?.includes(reservation.consumedTicketId!)
              );
            }
          );
        if (reservations?.length! >= ticket?.onlineLimitPerWeekly!) {
          setIsAvailable(false);
          setErrorMessage("週間予約件数上限です");
        }
      }

    }, [schedule, store.customer, ticket]);

    useEffect(() => {
      if (ticket.currentCount === 0) {
        setIsAvailable(false);
        setErrorMessage("残り利用回数が０回です。");
      }
      if (isExpired()) {
        setIsAvailable(false);
        setErrorMessage("利用期限切れです。");
      }
      checkUserLastUsage();
      
    }, [checkUserLastUsage, isExpired, ticket]);
    return (
      <Card variant="outlined" elevation={0} key={ticket?.masterTicketId}>
        <Stack p={2} spacing={1}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Chip
              label={
                ticket?.type === TicketType.COUPON
                  ? "購入済み回数券で予約"
                  : "月謝制加入者として予約"
              }
            />
          </Stack>
          <Stack direction="row" justifyContent="flex-start">
            <Typography variant="h4">{ticket?.name}</Typography>
          </Stack>
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Typography>残り利用回数</Typography>
            <Typography>{ticket?.currentCount}回</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>利用期限</Typography>
            <Typography>
              {ticket?.expireAt !== null
                ? formatDate(new Date(parseInt(ticket?.expireAt!)), {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    locale: "ja",
                  })
                : "なし"}
            </Typography>
          </Stack>
          <Button
            variant="contained"
            disableElevation
            component={Link}
            disabled={!isAvailable}
            to={`/s/${ticket.shopId}/sc/${
              schedule.id
            }/reserve?type=${`PURCHASED_${ticket?.type}`}&ticket_id=${
              ticket?.id
            }&style=${style}`}
          >
            {isAvailable ? "予約する" : errorMessage}
          </Button>
        </Stack>
      </Card>
    );
  }
);
