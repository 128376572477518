import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import {
  CreateReservationInput,
  Reservation,
  UpdateReservationInput,
  DeleteReservationInput,
  CreateReservationMutation,
  CreatePreReservationInput,
  ReservationTransaction,
  CreatePreReservationMutation,
  ReserveUsingTicketInput,
  ListReservationsQuery,
  ListExpiredReservationsQuery,
  ExpiredReservation,
} from "../API";
import {
  createPreReservation,
  createReservation,
  deleteReservation,
  updateReservation,
} from "../mutations";
import AWSAppSyncClient from "aws-appsync";
import gql from "graphql-tag";
import { listExpiredReservations, listReservations } from "../queries";
import { ExpiredReservationList, ReservationList } from "../schema";

export const ReservationRepository = (
  shopId: string,
  authToken: string | null
) => {
  const fetch = (customerId: string, nextToken?: string) => {
    return new Promise<ReservationList>(async (res, rej) => {
      const result = (await API.graphql({
        query: listReservations,
        variables: {
          filter: {
            customerId: {
              eq: customerId,
            },
          },
          limit: 5,
          nextToken: nextToken
        },
        authMode: "AWS_LAMBDA",
        authToken:
          authToken === null
            ? "custome_76198d0f-350d-4829-901a-1ad9e899ff4b"
            : `line_${authToken}`,
      })) as GraphQLResult<ListReservationsQuery>;

      if (result.errors) {
        rej(result.errors);
      } else {
        res({
          data: result.data?.listReservations?.items as Reservation[],
          nextToken: result.data?.listReservations?.nextToken!,
        });
      }
    });
  };

  const fetchExired = (customerId: string, nextToken?: string) => {
    return new Promise<ExpiredReservationList>(async (res, rej) => {
      const result = (await API.graphql({
        query: listExpiredReservations,
        variables: {
          filter: {
            customerId: {
              eq: customerId,
            },
          },
          limit: 5,
          nextToken: nextToken
        },
        authMode: "AWS_LAMBDA",
        authToken:
          authToken === null
            ? "custome_76198d0f-350d-4829-901a-1ad9e899ff4b"
            : `line_${authToken}`,
      })) as GraphQLResult<ListExpiredReservationsQuery>;

      if (result.errors) {
        rej(result.errors);
      } else {
        res({
          data: result.data?.listExpiredReservations!.items as ExpiredReservation[],
          nextToken: result.data?.listExpiredReservations?.nextToken!,
        });
      }
    });
  };

  const create = (
    preReservationInput: CreatePreReservationInput,
    reserveUsingTicketInput?: ReserveUsingTicketInput
  ) => {
    const mutation = gql(createPreReservation);
    const appsyncClient = new AWSAppSyncClient({
      url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT!,
      region: process.env.REACT_APP_AWS_REGION!,
      auth: {
        type: "AWS_LAMBDA",
        token:
          authToken === null
            ? "custome_76198d0f-350d-4829-901a-1ad9e899ff4b"
            : `line_${authToken}`,
      },
      disableOffline: true,
    });

    return new Promise<ReservationTransaction>(async (res, rej) => {
      const inputs = reserveUsingTicketInput
        ? {
            preReservationInput: preReservationInput,
            reserveUsingTicketInput: reserveUsingTicketInput,
          }
        : { preReservationInput: preReservationInput };
      await appsyncClient
        .mutate<CreatePreReservationMutation>({
          variables: inputs,
          mutation: mutation,
        })
        .then((result) => {
          res(result.data?.createPreReservation!);
        });
    });
  };

  const update = (input: UpdateReservationInput) => {
    return new Promise<void>(async (res, rej) => {
      try {
        await API.graphql(
          graphqlOperation(updateReservation, { input: input })
        );
        res();
      } catch (err) {
        rej(new Error("update error"));
      }
    });
  };

  const remove = (input: DeleteReservationInput) => {
    return new Promise<void>(async (res, rej) => {
      try {
        (await API.graphql(
          graphqlOperation(deleteReservation, { input: input })
        )) as GraphQLResult<DeleteReservationInput>;
        res();
      } catch (err) {
        rej(err);
      }
    });
  };

  return {
    fetch,
    fetchExired,
    create,
    update,
    remove,
  };
};
