import { GetShopQuery, Shop } from "../API";
import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { getShop } from "../queries";
export const ShopRepository = (shopId: string, authToken:string | null) => {
  const get = () => {
    return new Promise<Shop>(async (res, rej) => {
      const result = (await API.graphql({
        query: getShop,
        variables: { id: shopId },
        authMode: "AWS_LAMBDA",
        authToken: authToken === null ? 'custome_76198d0f-350d-4829-901a-1ad9e899ff4b': `line_${authToken}`,
      })) as GraphQLResult<GetShopQuery>;
      if (result.data?.getShop) {
        const shop = result.data?.getShop as Shop
        console.log(shop)
        res(shop);
      } else {
        rej(new Error("empty"));
      }
    });
  };

  return {
    get,
  };
};
