import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { LessonStyle, Schedule } from "../../DAL/API";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type Props = {
  style: LessonStyle;
  schedule: Schedule;
  disabled: boolean;
};

export const ScheduleLessonCapacityPanel = React.memo<Props>(
  ({ schedule, style, disabled }) => {
    const reservationStatus = useMemo(() => {
      const capacity =
        style === LessonStyle.STUDIO
          ? schedule.studio_capacity!
          : schedule.online_capacity!;
      const acceptableNumber =
        style === LessonStyle.STUDIO
          ? schedule.acceptableStudioNumber!
          : schedule.acceptableOnlineNumber!;
      const ratio = acceptableNumber / capacity;
      if(disabled){
        return (
          <Stack direction="row" alignItems="center" spacing={0.4}>
            <ErrorOutlineIcon fontSize="small" color="error" />
            <Typography variant="subtitle1">{ratio > 0 ? "受付時間外":"満員"}</Typography>
          </Stack>
        );
      }
      if (ratio > 0) {
        return (
          <Stack direction="row" alignItems="center" spacing={0.4}>
            <CheckCircleOutlineIcon fontSize="small" color="success" />
            <Typography variant="subtitle1">予約可</Typography>
          </Stack>
        );
      } else {
        return (
          <Stack direction="row" alignItems="center" spacing={0.4}>
            <ErrorOutlineIcon fontSize="small" color="error" />
            <Typography variant="subtitle1">満員</Typography>
          </Stack>
        );
      }
    }, [schedule, style]);
    return (
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {reservationStatus}
        </Stack>
      </Box>
    );
  }
);
