import { Typography, Card, Stack, Box, Chip, CardProps } from "@mui/material";
import React, { useMemo } from "react";
import { LessonStyle, Schedule } from "../../../DAL/API";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";

type Props = CardProps & {
  schedule: Schedule;
  selectedLessonStyle: LessonStyle;
};

export const ScheduleCard = React.memo<Props>(
  ({ schedule, selectedLessonStyle, children, ...props }) => {
    return (
      <Card sx={{ width: "100%" }} variant="outlined" {...props}>
        <Stack spacing={2} justifyContent="center" px={1.75} pt={2} pb={1.5}>
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Chip
                label={
                  selectedLessonStyle === LessonStyle.STUDIO
                    ? "スタジオ"
                    : "オンライン"
                }
              />
              <Stack direction="row" alignItems="start" spacing={0.2}>
                <SelfImprovementIcon fontSize="small" />
                <Typography variant="subtitle1">
                  {schedule.lesson?.staff?.name}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box>
            <Stack>
              <Typography variant="h3">{schedule.lesson?.name}</Typography>
            </Stack>
          </Box>
          {children}
        </Stack>
      </Card>
    );
  }
);
