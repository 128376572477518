import {
  Box,
  Divider,
  Paper,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React from "react";
type TitlePanelProps = StackProps &
  TypographyProps & {
    title: string;
    description?: string;
  };
const PageTitlePanel = React.memo<TitlePanelProps>(
  ({ title, description, spacing, textAlign }) => {
    return (
      <Paper variant="outlined" elevation={0}>
        <Stack spacing={spacing} p={1}>
          <Box px={.5}>
            <Typography variant="h4" textAlign={textAlign}>
              {title}
            </Typography>
          </Box>
          <Divider variant="middle"/>
          {description ? (
            <Box px={.5}>
              <Typography variant="body2">{description}</Typography>
            </Box>
          ) : null}
        </Stack>
      </Paper>
    );
  }
);

export default PageTitlePanel;
