import { Stack, Box, Typography, Divider, Paper } from "@mui/material";
import React from "react";
type Props = {
  title: string;
  amount: number;
};
export const SimplePaymentComfirmationPanel: React.FC<Props> = React.memo(
  ({ title, amount }) => {
    return (
      <Stack spacing={1}>
        <Box>
          <Typography variant="h5" fontWeight={600}>
            支払金額
          </Typography>
          <Divider sx={{ margin: "6px 0 0" }} />
        </Box>
        <Paper variant="outlined">
          <Stack p={1.5} spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">{title}</Typography>
              <Typography variant="h5">{amount.toLocaleString()}円</Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    );
  }
);
