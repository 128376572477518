import liff from "@line/liff/dist/lib";
import {
  Stack,
  Box,
  Typography,
  Divider,
  Button,
  List,
  ListItemButton,
  ListItem,
  ListItemIcon,
  Checkbox,
  styled,
} from "@mui/material";
import { DeadlineType, MasterTicket } from "../../DAL/API";
import { SetupFrom } from "../form/SetupFrom";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddIcon from "@mui/icons-material/Add";
import { Stripe } from "stripe";
import { UserAccount } from "../../DAL/schema";
import React, { useCallback, useEffect, useState } from "react";
import { PaymentComfirmationActionType, usePaymentComfirmationContext } from "../../provider/PaymentComfirmationContextProvider";

type Props = {
  user: UserAccount | null;
  stripePubKey: string | undefined;
  error: boolean
  helperText?: string;
};
const CardListItemIcon = styled(ListItemIcon)({
  minWidth:"auto"
});


export const PaymentMethodComfirmationPanel: React.FC<Props> = React.memo(({
  user,
  stripePubKey,
  error,
  helperText
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [selectedPaymentMethod, setPaymentMethod] =
    useState<Stripe.PaymentMethod>();
  const {dispatchComfirmationValue} = usePaymentComfirmationContext();
  const clickHandler = useCallback(() => {
    setShowForm(true);
    setPaymentMethod(undefined)
  }, []);

  const radioHandler  = (paymentMethod:Stripe.PaymentMethod) =>{
    if(paymentMethod.id === selectedPaymentMethod?.id){
      setPaymentMethod(undefined)
      dispatchComfirmationValue({
        type: PaymentComfirmationActionType.REMOVE_PAYMENT_METHOD,
        payload: null
      })
    }else{
      setPaymentMethod(paymentMethod);
      dispatchComfirmationValue({
        type: PaymentComfirmationActionType.SET_PAYMENT_METHOD,
        payload: paymentMethod.id
      })
    }
    setShowForm(false);
  }

  useEffect(() => {
    if (user?.stripePaymentMethods === null) {
      setShowForm(true);
    }else{
      setShowForm(false);
    }
  }, [user?.stripePaymentMethods]);
  return (
    <Stack spacing={1}>
      <Box>
        <Typography variant="h5" fontWeight={600}>
          支払方法
        </Typography>
        <Divider sx={{ margin: "6px 0 0" }} />
      </Box>
      <Box>
        {error?<Typography variant="error">{helperText}</Typography>:null}
        {user?.stripePaymentMethods !== null ? (
          <Stack spacing={1}>
            <List>
              {user?.stripePaymentMethods.map((item, index) => (
                <ListItem
                  key={`payment_method-${index + 1}`}
                  sx={{ width: "100%", padding: 0 }}
                >
                  <ListItemButton
                    onClick={(e) => radioHandler(item)}
                    selected={selectedPaymentMethod?.id === item.id}    
                  >
                    <CardListItemIcon>
                      <Checkbox
                        edge="start"
                        onChange={(e) => radioHandler(item)}
                        checked={selectedPaymentMethod?.id === item.id}
                      />
                    </CardListItemIcon>
                    <Stack
                      direction="row"
                      p={1}
                      alignItems="center"
                      justifyContent="space-around"
                      width="100%"
                    >
                      <Stack direction="row" alignItems="center">
                        <CreditCardIcon />
                        <Typography
                          ml={1}
                          variant="body1"
                        >{`${item.card?.brand}`}</Typography>
                      </Stack>
                      <Box>
                        <Typography variant="body1">{`有効期限:${item.card?.exp_year}年${item.card?.exp_month}月`}</Typography>
                        <Typography variant="body1">{`下4桁:${item.card?.last4}`}</Typography>
                      </Box>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            {showForm ? (
              <SetupFrom
                shopId={user?.serviceAccount.shopId!}
                stripePubKey={stripePubKey!}
                stripeCustomerId={user?.serviceAccount.stripeId!}
                authToken={liff.getAccessToken()}
              />
            ) : (
              <Stack>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={clickHandler}
                >
                  別のカードを使用する
                </Button>
              </Stack>
            )}
          </Stack>
        ) : (
          <SetupFrom
            shopId={user?.serviceAccount.shopId!}
            stripePubKey={stripePubKey!}
            stripeCustomerId={user?.serviceAccount.stripeId!}
            authToken={liff.getAccessToken()}
          />
        )}
      </Box>
    </Stack>
  );
});
