import AWSAppSyncClient from "aws-appsync";
import gql from "graphql-tag";
import {
  CreateSetupIntentInput,
  CreateSetupIntentMutation,
  CreateSetupIntentResult,
} from "../API";
import { createSetupIntent } from "../mutations";

export const SetupIntent = (
  shopId: string,
  authToken: string | null
) => {
  const publishSetupIntent = (input: CreateSetupIntentInput) => {
    const mutation = gql(createSetupIntent);
    const appsyncClient = new AWSAppSyncClient({
      url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT!,
      region: process.env.REACT_APP_AWS_REGION!,
      auth: {
        type: "AWS_LAMBDA",
        token: authToken === null ? 'custome_76198d0f-350d-4829-901a-1ad9e899ff4b': `line_${authToken}`,
      },
      disableOffline: true,
    });
    return new Promise<CreateSetupIntentResult>(async (res, rej) => {
      await appsyncClient
        .mutate<CreateSetupIntentMutation>({
          variables: { createSetupIntentInput: input },
          mutation: mutation,
        })
        .then((result) => {
          ;
          res(result.data!.createSetupIntent as CreateSetupIntentResult);
        });
    });
  };
  return { publishSetupIntent };
};
