import { formatDate, formatRange } from "@fullcalendar/react";
import liff from "@line/liff";
import {
  Paper,
  Stack,
  Box,
  Typography,
  Divider,
  Card,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  LessonStyle,
  MasterTicket,
  ReservationStatus,
  Schedule,
} from "../../DAL/API";
import { PurchasingTicketReserveInput, SubscribingPreReserveInput, UserAccount } from "../../DAL/schema";
import { LoadingCard } from "../parts/card";
import { PaymentMethodComfirmationPanel } from "./PaymentMethodComfirmationPanel";
import { ReserveSchedulePanel } from "./ReserveSchedulePanel";
import { SimplePaymentComfirmationPanel } from "./SimplePaymentComfirmationPanel";

import { TicketInfoComfirmationPanel } from "./TicketInfoComfirmationPanel";
import { UserInfoComfirmationPanel } from "./UserInfoComfirmationPanel";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { usePaymentComfirmationContext } from "../../provider/PaymentComfirmationContextProvider";
import { SubscriptionInfoComfirmationPanel } from "./SubscriptionInfoComfirmationPanel";

type Props = {
  schedule: Schedule;
  type: string | null | undefined;
  style: LessonStyle;
  ticket: MasterTicket;
  nextTicketIndex: number;
  customer: UserAccount;
  stripePubKey: string;
};

export const ReserveSubscribingTicketPanel: React.FC<Props> = React.memo(
  ({ schedule, ticket, type, customer, stripePubKey, style, nextTicketIndex}) => {
    const { dispatch } = useApplicationStore();
    const { shopId} = useParams();
    const navigate = useNavigate();
    const { comfirmationValue } = usePaymentComfirmationContext();

    const formSchema = yup.object({
      stripeCustomerId: yup.string().required(),
      priceId: yup.string().required("決済連動の準備が完了していません。"),
      couponId: yup.string(),
      masterTicketId: yup.string().required(),
      masterTicketName: yup.string().required(),
      paymentMethodId: yup.string().required("クレジットカードを選択してください。"),

      shopId: yup.string().required(),
      scheduleId: yup.string().required(),
      customerId: yup.string().required(),
      status: yup
        .mixed<ReservationStatus>()
        .oneOf(Object.values(ReservationStatus))
        .required(),
      lessonStyle: yup
        .mixed<LessonStyle>()
        .oneOf(Object.values(LessonStyle))
        .required(),
    });

    const input:SubscribingPreReserveInput = {
      systemCustomerId: customer.serviceAccount.id,
      stripeCustomerId: customer.serviceAccount.stripeId!,
      priceId: ticket.stripePriceId!,
      couponId: "",
      masterTicketId: ticket.id,
      masterTicketName: ticket.name,
      paymentMethodId: "",
      shopId: schedule.shopId,
      scheduleId: schedule.id,
      customerId: customer.serviceAccount.id,
      status: ReservationStatus.Entry,
      lessonStyle: style,
      expiredAt: schedule.expiredAt!
    };

    const {
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<SubscribingPreReserveInput>({
      resolver: yupResolver(formSchema),
      defaultValues: input,
    });
    const redirectPath = `/s/${shopId}/sc/${schedule.id}/reserve/transaction?style=${style}`;


    useEffect(() => {
  
      if(comfirmationValue.paymentMethodId !== null){
        
        setValue("paymentMethodId",comfirmationValue.paymentMethodId)
      }else{
        setValue("paymentMethodId","")
      }
  
    }, [comfirmationValue]);

    const onSubmit: SubmitHandler<SubscribingPreReserveInput> = (data) => {   
      dispatch({
        type: 'START_PRE_RESERVATION_SUBSCRIBING',
        payload: {
          input: data,
          purchasedTicketIndex: nextTicketIndex,
          lineAccessToken: liff.getAccessToken()
        },
      });
      navigate(redirectPath)
    };
  
    return (
      <Stack>
        <Paper
          variant="outlined"
          sx={{ margin: "12px 0 32px", padding: "12px" }}
        >
          <Stack spacing={3} px={1} py={1.5}>
            <ReserveSchedulePanel schedule={schedule} lessonStyle={style} />
            {type === "SINGLE" ? (
              <SimplePaymentComfirmationPanel
                title="1回コース"
                amount={schedule.lesson.singlePrice!}
              />
            ) : type === "TRIAL" ? (
              <SimplePaymentComfirmationPanel
                title="お試しコース"
                amount={schedule.lesson.trialPrice!}
              />
            ) : type === "SUBSCRIPTION" ? (
              <SubscriptionInfoComfirmationPanel ticket={ticket} />
            ) : type === "COUPON" ? (
              <TicketInfoComfirmationPanel ticket={ticket} />
            ) : null}
            <UserInfoComfirmationPanel user={customer.serviceAccount} />
            {customer !== null && stripePubKey !== null ? (
              <PaymentMethodComfirmationPanel
                user={customer}
                stripePubKey={stripePubKey}
                error={"paymentMethodId" in errors}
                helperText={errors.paymentMethodId?.message}
              />
            ) : (
              <LoadingCard />
            )}
          </Stack>
        </Paper>
        <Button
          variant="contained"
          disableElevation
          sx={{ width: "100%" }}
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          加入して予約する
        </Button>

      </Stack>
    );
  }
);
