import { Chip, Divider, Stack, Typography } from "@mui/material";
import { TicketHistory, TicketOperation } from "../../DAL/API";
import moment from "moment";

type Props = {
  ticketHistories?: TicketHistory[] | null;
};
export const PurchasedTicketHistoryPanel: React.FC<Props> = ({
  ticketHistories,
}) => {
  if (ticketHistories) {
    if (ticketHistories === null) return null;
    return (
      <Stack>
        {ticketHistories.map((history,index) => (

          <Stack key={history?.createdAt} spacing={1} p={1} bgcolor={index%2!==0?"background":"rgba(127,127,127,0.2)"}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Chip
                label={
                  history?.operation === TicketOperation.Consume
                    ? "チケット使用"
                    : "キャンセル"
                }
                size="small"
              />
              <Typography variant="caption">
                更新日時:
                {moment.unix(history?.createdAt!).format("YYYY/MM/DD")}
              </Typography>
            </Stack>
            <Typography variant="subtitle1">
              {history?.title}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  } else {
    return null;
  }
};
