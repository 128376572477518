import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { Navigate, Outlet, RouteProps, useParams } from "react-router-dom";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";

export const PublicRoute: React.FC<RouteProps> = () => {
  const { shopId } = useParams();
  const redirect_path = `/s/${shopId}/`;
  const { store } = useApplicationStore();
  useEffect(() => {
    ;
  }, []);
  return store.customer === null || store.customer?.serviceAccount === null ? <Outlet /> : <Navigate to={redirect_path} />;
};
