import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import React from "react";
import {
  MasterTicket,
  Schedule,
  TicketType,
} from "../../../DAL/API";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SinglePurchaseCard from "./SinglePurchaseCard";
import FirstPurchaseCard from "./FirstPurchaseCard";

type TicketProps = {
  scheduleId: string;
  tickets: (MasterTicket | null | undefined)[];
  type: TicketType;
  style: string;
};
export const PurchasingReservationAnnounceCard: React.FC<TicketProps> =
  React.memo(({ tickets, scheduleId, type, style }) => {
    return (
      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {type === TicketType.COUPON
            ? "回数券を購入して予約する"
            : "月謝コースに申込して予約する"}
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            {tickets.map((mt) => (
              <FirstPurchaseCard
                shopId={mt?.shopId!}
                lessonId={scheduleId}
                ticket={mt!}
                chipTxt={type === TicketType.COUPON ? "回数券" : "月謝制"}
                btnTxt={
                  type === TicketType.COUPON
                    ? "回数券を購入して予約"
                    : "月謝制に申込して予約"
                }
                key={mt?.id}
                style={style}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  });

type SingleProps = {
  schedule: Schedule;
  style: string;
  type: string;
};
export const SingleReservationAnnounceCard: React.FC<SingleProps> = React.memo(
  ({ schedule, style, type }) => {
    return (
      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {type === "SINGLE"
            ? "一回コースの料金で予約する"
            : "お試しプランで予約する"}
        </AccordionSummary>
        <AccordionDetails>
          <SinglePurchaseCard
            shopId={schedule.shopId}
            lessonId={schedule.id}
            price={
              type === "SINGLE"
                ? schedule.lesson.singlePrice!
                : schedule.lesson.trialPrice!
            }
            name={type === "SINGLE" ? "１回コース料金" : "お試しプラン"}
            chipTxt={type === "SINGLE" ? "一回のみの予約" : "トライアル"}
            btnTxt={
              type === "SINGLE" ? "一回コースで予約" : "お試しプランで予約する"
            }
            type={type}
            style={style}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
);
