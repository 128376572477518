/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomerAccount = /* GraphQL */ `
  query GetCustomerAccount($shopId: String, $lineId: String) {
    getCustomerAccount(shopId: $shopId, lineId: $lineId) {
      serviceCustomerInfo {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          items {
            shopId
            id
            scheduleId
            schedule {
              shopId
              id
              lessonId
              lesson {
                id
                name
              }
              start
              end
              link
              isPublished
              studio_capacity
              online_capacity
              acceptableStudioNumber
              acceptableOnlineNumber
              createdAt
              updatedAt
            }
            customerId
            customer {
              shopId
              id
              stripeId
              name
              lineId
              email
              purchasedTickets {
                shopId
                id
                masterTicketId
                paymentIntentId
                stripeProductId
                stripePriceId
                name
                description
                maxCount
                currentCount
                price
                type
                deadline {
                  type
                  num
                }
                hasDailyLimit
                limitPerDaily
                hasOnlineDailyLimit
                onlineLimitPerDaily
                hasWeeklyLimit
                limitPerWeekly
                hasOnlineWeeklyLimit
                onlineLimitPerWeekly
                lastUsedAt
                lastOnlineUsedAt
                purchasedAt
                expireAt
                isAvailable
              }
              createdAt
              updatedAt
            }
            lessonStyle
            status
            transactionId
            transaction {
              reservationId
              id
              customerId
              createdAt
              updatedAt
            }
            isTrial
            consumedTicketId
            expiredAt
            createdAt
            updatedAt
          }
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      stripeCustomerInfo
      stripePaymentMethodInfo
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      address
      email
      tel
      profile
      icon
      stripePubKey
      mode
      stripe {
        dev {
          pubKey
        }
        prod {
          pubKey
        }
      }
      dayoff
      staffs {
        shopId
        id
        name
      }
      schedules {
        items {
          shopId
          id
          lessonId
          lesson {
            id
            shopId
            name
            thumbnail
            description
            duration
            masterTickets {
              items {
                id
                lessonId
                masterTicketId
                masterTicket {
                  id
                  name
                }
              }
              nextToken
            }
            style
            canSingle
            singlePrice
            hasTrial
            trialPrice
            hasTicket
            canPublish
            studio_capacity
            online_capacity
            color
            order
            cancelTimeoutLimit
            reserveTimeoutLimit
            createdAt
            updatedAt
          }
          start
          end
          expiredAt
          zoomMeetingId
          link
          pass
          isPublished
          studio_capacity
          online_capacity
          acceptableStudioNumber
          acceptableOnlineNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          shopId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          isPrivate
          privateScopedCustomerId
          isAvailable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $id: ID
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShops(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        address
        email
        tel
        profile
        icon
        stripePubKey
        stripeSecKey
        mode
        zoom {
          accountEmail
          accessToken
          tokenType
          refreshToken
          expiresIn
          scope
          updatedAt
          dailyCreateMeetingThrottling
        }
        dayoff
        staffs {
          shopId
          id
          name
        }
        schedules {
          nextToken
        }
        tickets {
          nextToken
        }
        lessons {
          nextToken
        }
        customers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($shopId: ID!, $id: ID!) {
    getSchedule(shopId: $shopId, id: $id) {
      shopId
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      expiredAt
      zoomMeetingId
      link
      pass
      isPublished
      studio_capacity
      online_capacity
      acceptableStudioNumber
      acceptableOnlineNumber
      reservations {
        items {
          shopId
          id
          scheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          expiredAt
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchedules(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        expiredAt
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        reservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExpiredSchedule = /* GraphQL */ `
  query GetExpiredSchedule($shopId: ID!, $id: ID!) {
    getExpiredSchedule(shopId: $shopId, id: $id) {
      shopId
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      zoomMeetingId
      link
      pass
      isPublished
      studio_capacity
      online_capacity
      acceptableStudioNumber
      acceptableOnlineNumber
      expiredReservations {
        items {
          shopId
          id
          expiredScheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listExpiredSchedules = /* GraphQL */ `
  query ListExpiredSchedules(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelExpiredScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExpiredSchedules(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($shopId: ID!, $id: ID!) {
    getReservation(shopId: $shopId, id: $id) {
      shopId
      id
      scheduleId
      schedule {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        expiredAt
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        reservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      lessonStyle
      status
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      isTrial
      consumedTicketId
      expiredAt
      createdAt
      updatedAt
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReservations(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        scheduleId
        schedule {
          shopId
          id
          lessonId
          start
          end
          expiredAt
          zoomMeetingId
          link
          pass
          isPublished
          studio_capacity
          online_capacity
          acceptableStudioNumber
          acceptableOnlineNumber
          createdAt
          updatedAt
        }
        customerId
        customer {
          shopId
          id
          stripeId
          name
          lineId
          email
          createdAt
          updatedAt
        }
        lessonStyle
        status
        transactionId
        transaction {
          reservationId
          id
          customerId
          createdAt
          updatedAt
        }
        isTrial
        consumedTicketId
        expiredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reservationsByExpiredAt = /* GraphQL */ `
  query ReservationsByExpiredAt(
    $expiredAt: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reservationsByExpiredAt(
      expiredAt: $expiredAt
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shopId
        id
        scheduleId
        schedule {
          shopId
          id
          lessonId
          start
          end
          expiredAt
          zoomMeetingId
          link
          pass
          isPublished
          studio_capacity
          online_capacity
          acceptableStudioNumber
          acceptableOnlineNumber
          createdAt
          updatedAt
        }
        customerId
        customer {
          shopId
          id
          stripeId
          name
          lineId
          email
          createdAt
          updatedAt
        }
        lessonStyle
        status
        transactionId
        transaction {
          reservationId
          id
          customerId
          createdAt
          updatedAt
        }
        isTrial
        consumedTicketId
        expiredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReservationTransaction = /* GraphQL */ `
  query GetReservationTransaction($reservationId: ID!, $id: ID!) {
    getReservationTransaction(reservationId: $reservationId, id: $id) {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReservationTransactions = /* GraphQL */ `
  query ListReservationTransactions(
    $reservationId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelReservationTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReservationTransactions(
      reservationId: $reservationId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExpiredReservation = /* GraphQL */ `
  query GetExpiredReservation($shopId: ID!, $id: ID!) {
    getExpiredReservation(shopId: $shopId, id: $id) {
      shopId
      id
      expiredScheduleId
      expiredSchedule {
        shopId
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        zoomMeetingId
        link
        pass
        isPublished
        studio_capacity
        online_capacity
        acceptableStudioNumber
        acceptableOnlineNumber
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      lessonStyle
      status
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      isTrial
      consumedTicketId
      createdAt
      updatedAt
    }
  }
`;
export const listExpiredReservations = /* GraphQL */ `
  query ListExpiredReservations(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelExpiredReservationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExpiredReservations(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        expiredScheduleId
        expiredSchedule {
          shopId
          id
          lessonId
          start
          end
          zoomMeetingId
          link
          pass
          isPublished
          studio_capacity
          online_capacity
          acceptableStudioNumber
          acceptableOnlineNumber
          createdAt
          updatedAt
        }
        customerId
        customer {
          shopId
          id
          stripeId
          name
          lineId
          email
          createdAt
          updatedAt
        }
        lessonStyle
        status
        transactionId
        transaction {
          reservationId
          id
          customerId
          createdAt
          updatedAt
        }
        isTrial
        consumedTicketId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($shopId: ID!, $id: ID!) {
    getCustomer(shopId: $shopId, id: $id) {
      shopId
      id
      stripeId
      name
      lineId
      email
      purchasedTickets {
        shopId
        id
        masterTicketId
        paymentIntentId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        currentCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        lastUsedAt
        lastOnlineUsedAt
        purchasedAt
        expireAt
        isAvailable
        histories {
          title
          operation
          createdAt
        }
      }
      reservations {
        items {
          shopId
          id
          scheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          expiredAt
          createdAt
          updatedAt
        }
        nextToken
      }
      expiredReservations {
        items {
          shopId
          id
          expiredScheduleId
          customerId
          lessonStyle
          status
          transactionId
          isTrial
          consumedTicketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
        }
        reservations {
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customerByLineId = /* GraphQL */ `
  query CustomerByLineId(
    $lineId: ID!
    $shopId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByLineId(
      lineId: $lineId
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shopId
        id
        stripeId
        name
        lineId
        email
        purchasedTickets {
          shopId
          id
          masterTicketId
          paymentIntentId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          currentCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          lastUsedAt
          lastOnlineUsedAt
          purchasedAt
          expireAt
          isAvailable
          histories {
            title
            operation
            createdAt
          }
        }
        reservations {
          items {
            shopId
            id
            scheduleId
            schedule {
              shopId
              id
              lessonId
              lesson {
                id
                name
              }
              start
              end
              link
              isPublished
              studio_capacity
              online_capacity
              acceptableStudioNumber
              acceptableOnlineNumber
              createdAt
              updatedAt
            }
            customerId
            customer {
              shopId
              id
              stripeId
              name
              lineId
              email
              purchasedTickets {
                shopId
                id
                masterTicketId
                paymentIntentId
                stripeProductId
                stripePriceId
                name
                description
                maxCount
                currentCount
                price
                type
                deadline {
                  type
                  num
                }
                hasDailyLimit
                limitPerDaily
                hasOnlineDailyLimit
                onlineLimitPerDaily
                hasWeeklyLimit
                limitPerWeekly
                hasOnlineWeeklyLimit
                onlineLimitPerWeekly
                lastUsedAt
                lastOnlineUsedAt
                purchasedAt
                expireAt
                isAvailable
              }
              createdAt
              updatedAt
            }
            lessonStyle
            status
            transactionId
            transaction {
              reservationId
              id
              customerId
              createdAt
              updatedAt
            }
            isTrial
            consumedTicketId
            expiredAt
            createdAt
            updatedAt
          }
          nextToken
        }
        expiredReservations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMasterTicket = /* GraphQL */ `
  query GetMasterTicket($id: ID!) {
    getMasterTicket(id: $id) {
      id
      shopId
      stripeProductId
      stripePriceId
      name
      description
      maxCount
      price
      type
      deadline {
        type
        num
      }
      hasDailyLimit
      limitPerDaily
      hasOnlineDailyLimit
      onlineLimitPerDaily
      hasWeeklyLimit
      limitPerWeekly
      hasOnlineWeeklyLimit
      onlineLimitPerWeekly
      isPrivate
      privateScopedCustomerId
      lessons {
        items {
          id
          lessonId
          masterTicketId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isAvailable
      createdAt
      updatedAt
    }
  }
`;
export const listMasterTickets = /* GraphQL */ `
  query ListMasterTickets(
    $id: ID
    $filter: ModelMasterTicketFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMasterTickets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        shopId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        isPrivate
        privateScopedCustomerId
        lessons {
          nextToken
        }
        isAvailable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLesson = /* GraphQL */ `
  query GetLesson($id: ID!) {
    getLesson(id: $id) {
      id
      shopId
      name
      thumbnail
      description
      duration
      style
      canSingle
      singlePrice
      hasTrial
      trialPrice
      hasTicket
      masterTickets {
        items {
          id
          lessonId
          masterTicketId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      canPublish
      studio_capacity
      online_capacity
      requiredTime {
        timeUnit
        value
      }
      staff {
        shopId
        id
        name
      }
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const listLessons = /* GraphQL */ `
  query ListLessons(
    $id: ID
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLessons(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLessonMasterTickets = /* GraphQL */ `
  query GetLessonMasterTickets($id: ID!) {
    getLessonMasterTickets(id: $id) {
      id
      lessonId
      lesson {
        id
        shopId
        name
        thumbnail
        description
        duration
        style
        canSingle
        singlePrice
        hasTrial
        trialPrice
        hasTicket
        masterTickets {
          nextToken
        }
        canPublish
        studio_capacity
        online_capacity
        requiredTime {
          timeUnit
          value
        }
        staff {
          shopId
          id
          name
        }
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      masterTicketId
      masterTicket {
        id
        shopId
        stripeProductId
        stripePriceId
        name
        description
        maxCount
        price
        type
        deadline {
          type
          num
        }
        hasDailyLimit
        limitPerDaily
        hasOnlineDailyLimit
        onlineLimitPerDaily
        hasWeeklyLimit
        limitPerWeekly
        hasOnlineWeeklyLimit
        onlineLimitPerWeekly
        isPrivate
        privateScopedCustomerId
        lessons {
          nextToken
        }
        isAvailable
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLessonMasterTickets = /* GraphQL */ `
  query ListLessonMasterTickets(
    $filter: ModelLessonMasterTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonMasterTickets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonId
        lesson {
          id
          shopId
          name
          thumbnail
          description
          duration
          style
          canSingle
          singlePrice
          hasTrial
          trialPrice
          hasTicket
          canPublish
          studio_capacity
          online_capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        masterTicketId
        masterTicket {
          id
          shopId
          stripeProductId
          stripePriceId
          name
          description
          maxCount
          price
          type
          hasDailyLimit
          limitPerDaily
          hasOnlineDailyLimit
          onlineLimitPerDaily
          hasWeeklyLimit
          limitPerWeekly
          hasOnlineWeeklyLimit
          onlineLimitPerWeekly
          isPrivate
          privateScopedCustomerId
          isAvailable
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
