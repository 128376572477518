import { createContext, useContext, useMemo, useReducer } from "react";
import { MasterTicket, TicketType } from "../DAL/API";
export type MasterTicketComfirmationValue = {
  shopId: string;
  stripeCustomerId: string;
  priceId: string | null;
  quantity: number;
  ticketId: string;
  ticketName: string;
  ticketType: TicketType | null;
  paymentMethodId: string | null;
};

const initialValue: MasterTicketComfirmationValue = {
  shopId: "",
  stripeCustomerId: "",
  priceId: null,
  quantity: 1,
  ticketId: "",
  ticketName: "",
  ticketType: null,
  paymentMethodId: null,
};
export enum PaymentComfirmationActionType {
  SET_TICKET,
  SET_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
}
type PaymentComfirmationAction =
  | {
      type: PaymentComfirmationActionType.SET_TICKET;
      payload: MasterTicket;
    }
  | {
      type: PaymentComfirmationActionType.SET_PAYMENT_METHOD;
      payload: string;
    }
  | {
      type: PaymentComfirmationActionType.REMOVE_PAYMENT_METHOD;
      payload: null;
    };

type MasterTicketComfirmationContext = {
  comfirmationValue: MasterTicketComfirmationValue;
  dispatchComfirmationValue: React.Dispatch<PaymentComfirmationAction>;
};

const PaymentComfirmationContext =
  createContext<MasterTicketComfirmationContext>(
    {} as MasterTicketComfirmationContext
  );

const reducer = (
  state: MasterTicketComfirmationValue,
  action: PaymentComfirmationAction
) => {
  switch (action.type) {
    case PaymentComfirmationActionType.SET_TICKET:
      return {
        ...state,
        shopId: action.payload.shopId,
        ticketId: action.payload.id,
        ticketName: action.payload.name,
      };
    case PaymentComfirmationActionType.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethodId: action.payload,
      };
    case PaymentComfirmationActionType.REMOVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethodId: null,
      };
    default:
      return state;
  }
};

type Props = {
  children?: React.ReactNode;
};
export const PaymentComfirmationContextProvider: React.FC<Props> = (props) => {
  const [comfirmationValue, dispatchComfirmationValue] = useReducer(
    reducer,
    initialValue
  );
  const value = useMemo(
    () => ({
      comfirmationValue,
      dispatchComfirmationValue,
    }),
    [comfirmationValue]
  );
  return <PaymentComfirmationContext.Provider value={value} {...props} />;
};

export const usePaymentComfirmationContext = () => {
  const context = useContext(PaymentComfirmationContext);
  if (typeof context === "undefined")
    throw new Error(
      "usePaymentComfirmationContext must be within a PaymentComfirmationContextProvider."
    );
  return context;
};
