import {
  Paper,
  Stack,
  Button,
} from "@mui/material";
import React from "react";
import {
  CreatePreReservationInput,
  LessonStyle,
  PurchasedTicket,
  ReservationStatus,
  Schedule,
} from "../../DAL/API";
import { UserAccount } from "../../DAL/schema";
import { ReserveSchedulePanel } from "./ReserveSchedulePanel";
import { UserInfoComfirmationPanel } from "./UserInfoComfirmationPanel";
import { ComfirmPurchasedTicketPanel } from "./ComfirmPurchasedTicketPanel";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { useNavigate, useParams } from "react-router-dom";
import liff from "@line/liff/dist/lib";

type Props = {
  schedule: Schedule;
  ticket: PurchasedTicket;
  ticketIndex: number;
  customer: UserAccount;
  style: LessonStyle;
};

export const UsingPurchasedTicketReservePanel: React.FC<Props> = React.memo(
  ({ schedule, customer, ticket, style, ticketIndex }) => {
    const formSchema = yup.object({
      shopId: yup.string().required(),
      scheduleId: yup.string().required(),
      customerId: yup.string().required(),

      status: yup
        .mixed<ReservationStatus>()
        .oneOf(Object.values(ReservationStatus))
        .required(),
      lessonStyle: yup
        .mixed<LessonStyle>()
        .oneOf(Object.values(LessonStyle))
        .required(),
    });

    const reservationValue = {
      shopId: schedule.shopId,
      scheduleId: schedule.id,
      customerId: customer.serviceAccount.id,
      consumedTicketId: ticket.id,
      status: ReservationStatus.Entry,
      lessonStyle: style,
      expiredAt: schedule.expiredAt!
    } as CreatePreReservationInput;
    const { dispatch } = useApplicationStore();
    const { shopId, scheduleId } = useParams();
    const {
      handleSubmit,
      formState: { errors },
    } = useForm<CreatePreReservationInput>({
      resolver: yupResolver(formSchema),
      defaultValues: reservationValue,
    });
    const navigate = useNavigate();
    const redirectPath = `/s/${shopId}/sc/${scheduleId}/reserve/transaction?style=${style}`;

    const onSubmit: SubmitHandler<CreatePreReservationInput> = (data) => {
      
      dispatch({
        type: "START_PRE_RESERVATION_USING_PURCHASED_TICKET",
        payload: {
          preReservationInput: data,
          reserveUsingTicketInput: {
            purchasedTicketId: ticket.id,
            purchasedTicketIndex: ticketIndex,
          },
          lineAccessToken: liff.getAccessToken()
        },
      });
      navigate(redirectPath);
    };

    return (
      <Stack>
        <Paper
          variant="outlined"
          sx={{ margin: "12px 0 32px", padding: "12px" }}
        >
          <Stack spacing={3} px={1} py={1.5}>
            <ReserveSchedulePanel schedule={schedule} lessonStyle={style} />
            <ComfirmPurchasedTicketPanel ticket={ticket} type={ticket.type} />
            <UserInfoComfirmationPanel user={customer.serviceAccount} />
          </Stack>
        </Paper>
        <Button
          variant="contained"
          disableElevation
          sx={{ width: "100%" }}
          color="primary"
          disabled={ticket.currentCount === 0}
          onClick={handleSubmit(onSubmit)}
        >
          予約する
        </Button>
      </Stack>
    );
  }
);
