import {
  Box,
  Button,
  Container,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CustomerForm } from "../../components/form";
import MyPageTitlePanel from "../../components/layout/MyPageTitlePanel";
import { TextCard } from "../../components/parts/card";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";

export const CustomerIndexPage = () => {
  const [isUser, setIsUser] = useState<boolean>(false);
  const { store, dispatch } = useApplicationStore();
  useEffect(() => {
    if (typeof store.customer?.serviceAccount === null) {
      setIsUser(false);
    } else {
      setIsUser(true);
    }
  }, [dispatch, store.customer?.serviceAccount, store.shop?.id]);
  return (
    <Container>
      <Box>
        {isUser ? (
          <Stack spacing={2}>
            <MyPageTitlePanel
              title={`${store.customer?.serviceAccount.name}さんのマイページ`}
              spacing={1.2}
              textAlign="center"
            />
            <Button
              variant="outlined"
              component={Link}
              to={`/s/${store.customer?.serviceAccount.shopId}/c/${store.customer?.serviceAccount.id}/reservations`}
            >
              予約一覧を見る
            </Button>
            <Button
            variant="outlined"
              component={Link}
              to={`/s/${store.customer?.serviceAccount.shopId}/c/${store.customer?.serviceAccount.id}/reservations/history`}
            >
              受講履歴を見る
            </Button>
          </Stack>
        ) : (
          <Stack spacing={2} py={2}>
            <TextCard
              sx={{ width: "100%" }}
              variant="outlined"
              txt="ユーザー登録されていません。"
            />
            <CustomerForm />
          </Stack>
        )}
      </Box>
    </Container>
  );
};
