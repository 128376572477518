import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { LessonStyle, Schedule } from "../../DAL/API";
import { ScheduleLessonCapacityPanel } from "../layout/ScheduleLessonCapacityPanel";
import { ScheduleTimePanel } from "../layout/ScheduleTimePanel";
import { TextCard } from "../parts/card";
import { ScheduleCard } from "../parts/card/ScheduleCard";

type Prop = {
  schedules: Schedule[];
  selectedLessonStyle: LessonStyle;
};

export const ScheduleList = React.memo<Prop>(({
  schedules,
  selectedLessonStyle,
}) => {
  const isReservable = useCallback((schedule: Schedule)=>{
    const start = new Date(schedule.start).getTime()
    const limit = new Date(start - schedule.lesson.reserveTimeoutLimit!).getTime()
    const acceptableNumber =  (selectedLessonStyle === LessonStyle.STUDIO ? schedule.acceptableStudioNumber: schedule.acceptableOnlineNumber) ?? 0
    return limit - Date.now() > 0 && acceptableNumber > 0
  },[selectedLessonStyle])
  return (
    <Stack direction="column" spacing={1}>
      {schedules.length > 0 ? (
        schedules.sort((a,b)=> (new Date(a.start)).getTime() > (new Date(b.start)).getTime() ? 1 : -1 ).map((schedule) => (
          <Button
            disabled={!isReservable(schedule)}
            component={Link}
            to={`/s/${schedule.shopId}/sc/${schedule.id}?style=${selectedLessonStyle}`}
            key={schedule.id}
            sx={{ padding: 0, "&.Mui-disabled > div":{
              backgroundColor: "rgba(0, 0, 0, 0.12)"
            } }}
          >
            <ScheduleCard schedule={schedule} selectedLessonStyle={selectedLessonStyle}>
              <ScheduleTimePanel
                startTime={schedule.start}
                endTime={schedule.end}
              />
              <ScheduleLessonCapacityPanel
                schedule={schedule}
                style={selectedLessonStyle}
                disabled={!isReservable(schedule)}
              />
            </ScheduleCard>
          </Button>
        ))
      ) : (
        <TextCard
          sx={{ width: "100%" }}
          variant="outlined"
          txt="開催予定のレッスンはありません。"
        />
      )}
    </Stack>
  );
});

