import { Paper, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import { useCallback } from "react";
import {
  useTopPageContext,
  TopPageOperation,
} from "../../provider/TopPageContextProvider";
import DateSelector from "../parts/DateSelector";

const TopPageDateSelectorPanel = React.memo(() => {
  const { topPageDispatch } = useTopPageContext();

  const onAccept = useCallback((selectedDate: Date | null) => {
    console.log(selectedDate)
    topPageDispatch({
      type: TopPageOperation.SELECT_DATE,
      payload: selectedDate!,
    });
  }, []);
  return (
    <Paper variant="outlined">
      <Grid container alignItems="center" justifyContent="space-around" p={1}>
        <Grid item>
          <Typography variant="h5">予約日を指定する</Typography>
        </Grid>
        <Grid item xs={6}>
          <DateSelector onAccept={onAccept} />
        </Grid>
      </Grid>
    </Paper>
  );
});

export default TopPageDateSelectorPanel;
