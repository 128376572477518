import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import MyPageTitlePanel from "../components/layout/MyPageTitlePanel";
import { MasterTicket, TicketType } from "../DAL/API";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import liff from "@line/liff";
import { MasterTicketCard } from "../components/parts/card/MasterTicketCard";

export const TicketsPage = () => {
  const { store, dispatch } = useApplicationStore();
  const { shopId } = useParams();
  const [tickets, setTickets] = useState<MasterTicket[]>();
  const navigate = useNavigate();

  const _getData = useCallback(() => {
    if (store.tickets === null) {
      dispatch({
        type: "APP_DATA_FETCHING",
        payload: { shopId: shopId!, lineAccessToken: liff.getAccessToken()},
      });
    } else {
      const purchasedIds = store.customer?.serviceAccount.purchasedTickets?.map(
        (ticket) => ticket?.masterTicketId
      );
      const notPurchasedTickets = store.tickets.filter(
        (t) => /*!purchasedIds?.includes(t.id) &&*/ t.type === TicketType.COUPON
      );
      const notPrivateCoupon =
      notPurchasedTickets?.filter((mt) => !mt.isPrivate) ?? [];
      const privateCoupon =
      notPurchasedTickets?.filter(
          (mt) =>
            mt?.privateScopedCustomerId?.includes(
              store.customer?.serviceAccount.id!
            ) && mt.isPrivate
        ) ?? [];

      setTickets([...notPrivateCoupon, ...privateCoupon] ?? []);
    }
  }, [dispatch, navigate, shopId, store.tickets]);

  useEffect(() => {
    _getData();
  }, [_getData, store.tickets]);
  return (
    <Box>
      <Container>
        <Stack spacing={2}>
          <MyPageTitlePanel
            title={`未購入チケット一覧`}
            spacing={1.2}
            textAlign="center"
          />
          {tickets?.length! > 0 ? (
            tickets?.map((ticket) => (
              <MasterTicketCard ticket={ticket} key={ticket.id}>
                <Button
                  variant="contained"
                  disableElevation
                  component={Link}
                  to={`/s/${shopId}/ticket/${ticket.id}/comfirm`}
                >
                  購入する
                </Button>
              </MasterTicketCard>
            ))
          ) : (
            <Paper elevation={0} variant="outlined">
              <Typography padding={1.5} align="center">
                未購入のチケットはありません
              </Typography>
            </Paper>
          )}
        </Stack>
      </Container>
    </Box>
  );
};
