import liff from "@line/liff";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  LessonStyle,
  MasterTicket,
  PurchasedTicket,
  Schedule,
} from "../DAL/API";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import { ReservePurchasingTicketPanel } from "../components/layout/ReservePurchasingTicketPanel";
import { UsingPurchasedTicketReservePanel } from "../components/layout/UsingPurchasedTicketReservePanel";
import { ReserveSubscribingTicketPanel } from "../components/layout/ReserveSubscribingTicketPanel";
import { ReserveSinglePaymentPanel } from "../components/layout/ReserveSinglePaymentPanel";

export const ReservePage: React.FC = ({ children }) => {
  const { store, dispatch } = useApplicationStore();
  const { shopId, scheduleId } = useParams();
  const [searchParams] = useSearchParams();
  const [schedule, setSchedule] = useState<Schedule>();
  const [selectedTicket, setSelectedTicket] = useState<
    MasterTicket | PurchasedTicket
  >();
  const [selectedLessonStyle, setLessonStyle] = useState<LessonStyle>();
  const [stripePubKey, setStripePubKey] = useState<string | null>(null);
  const [usingPurchasedTicket, setUsingTicket] = useState<boolean>(false);
  const [ticketIndex, setTicketIndex] = useState<number>();

  useEffect(() => {
    if (store.schedules === null || store.tickets === null) {
      dispatch({
        type: "APP_DATA_FETCHING",
        payload: { shopId: shopId!, lineAccessToken: liff.getAccessToken() },
      });
    } else {
      initializeState();
    }
  }, [store.schedules, store.tickets]);

  const initializeState = () => {
    setStripePubKey(store.shop?.stripePubKey!);
    const _schedule = store.schedules?.find(
      (s) => s.shopId === shopId && s.id === scheduleId
    );
    setSchedule(_schedule);
    const usingTicket =
      searchParams.get("type") === "PURCHASED_COUPON" ||
      searchParams.get("type") === "PURCHASED_SUBSCRIPTION";
    setUsingTicket(usingTicket);
    setLessonStyle(
      searchParams.get("style") === "STUDIO"
        ? LessonStyle.STUDIO
        : searchParams.get("style") === "ONLINE"
        ? LessonStyle.ONLINE
        : undefined
    );

    const _index = store.customer?.serviceAccount?.purchasedTickets
      ? store.customer?.serviceAccount?.purchasedTickets.findIndex(
          (mt) => mt?.id === searchParams.get("ticket_id")
        )
      : store.customer?.serviceAccount?.purchasedTickets?.length ?? 0;
    setTicketIndex(_index < 0 ? store.customer?.serviceAccount?.purchasedTickets?.length ?? 0 : _index);
    if (usingTicket) {
      setSelectedTicket(
        store.customer?.serviceAccount?.purchasedTickets![_index]!
      );
    } else {
      setSelectedTicket(
        store.tickets?.find((mt) => mt?.id === searchParams.get("ticket_id"))!
      );
    }
  };

  return schedule ? (
    <Container>
      {usingPurchasedTicket ? (
        <UsingPurchasedTicketReservePanel
          customer={store.customer!}
          schedule={schedule}
          style={selectedLessonStyle!}
          ticket={selectedTicket as PurchasedTicket}
          ticketIndex={ticketIndex!}
        />
      ) : searchParams.get("type") === "SUBSCRIPTION" ? (
        <ReserveSubscribingTicketPanel
          customer={store.customer!}
          ticket={selectedTicket as MasterTicket}
          nextTicketIndex={ticketIndex!}
          type={searchParams.get("type")}
          schedule={schedule}
          style={selectedLessonStyle!}
          stripePubKey={stripePubKey!}
        />
      ) : searchParams.get("type") === "COUPON" ? (
        <ReservePurchasingTicketPanel
          customer={store.customer!}
          ticket={selectedTicket as MasterTicket}
          nextTicketIndex={ticketIndex!}
          type={searchParams.get("type")}
          schedule={schedule}
          style={selectedLessonStyle!}
          stripePubKey={stripePubKey!}
        />
      ) : (
        <ReserveSinglePaymentPanel
          schedule={schedule}
          type={searchParams.get("type")}
          customer={store.customer!}
          stripePubKey={stripePubKey!}
          style={selectedLessonStyle!}
        />
      )}
    </Container>
  ) : null;
};
