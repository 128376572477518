import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Stack } from "@mui/material";
import {
  TopPageDateSelectorPanel,
  TopPageScheduleList,
  TopPageSelectedDateLable,
} from "../components/layout";
import liff from "@line/liff";
import DocumentMeta from "react-document-meta";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import TopPageScheduleSortPanel from "../components/layout/TopPageScheduleSortPanel";

export const TopPage: React.FC = ({ children }) => {
  const { store, dispatch } = useApplicationStore();
  const { shopId } = useParams();
  const meta = {
    title: "予約ページ",
  };

  useEffect(() => {
    if (store.schedules === null) {
      dispatch({
        type: "APP_DATA_FETCHING",
        payload: { shopId: shopId!, lineAccessToken: liff.getAccessToken()},
      });
    }
  }, [dispatch, shopId, store.schedules, store.lineId]);

  return (
    <DocumentMeta {...meta}>
      <Container maxWidth="xs">
        <Stack spacing={2} py={0}>
          <TopPageDateSelectorPanel />
          <TopPageSelectedDateLable />
          <TopPageScheduleSortPanel />
          <TopPageScheduleList />
        </Stack>
      </Container>
    </DocumentMeta>
  );
};
