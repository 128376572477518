import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  RouteProps,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import liff from "@line/liff";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";
import {
  Backdrop,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

export const ProtectedRoute: React.FC<RouteProps> = () => {
  const { shopId } = useParams();
  const { store, dispatch } = useApplicationStore();
  const navigate = useNavigate();
  const redirect_path = `/s/${shopId}/c/regist`;
  useEffect(() => {
    if (store.customer === null) {
      liff
        .init({ liffId: liff.id! })
        .then(async () => {
          if (liff.isLoggedIn() && liff.isInClient()) {
            const customerDataClient = CustomerRepository(
              shopId!,
              liff.getAccessToken()
            );
            liff
              .getProfile()
              .then((result) => {
                dispatch({
                  type: ResourceOperationEvent.FETCH_LIFF,
                  payload: result.userId,
                });
                return new Promise<string>((res) => res(result.userId));
              })
              .then((lid) => customerDataClient.getAccount(lid))
              .then((customer) => {
                if (customer.serviceAccount === null) {
                  navigate(redirect_path);
                } else {
                  dispatch({
                    type: ResourceOperationEvent.FETCH_CUSTOMER,
                    payload: customer,
                  });
                }
              });
          } else {

            if (process.env.NODE_ENV === "development") {
              dispatch({
                type: ResourceOperationEvent.FETCH_LIFF,
                payload: "1234asb",
              });
              CustomerRepository(shopId!, null)
                .getAccount("1234asb")
                .then((customer) => {
                  if (customer.serviceAccount === null) {
                    navigate(redirect_path);
                  } else {
                    dispatch({
                      type: ResourceOperationEvent.FETCH_CUSTOMER,
                      payload: customer,
                    });
                  }
                });
            }
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [store.customer]);

  return store.customer !== null ? (
    store.customer?.serviceAccount === null ? (
      <Navigate to={redirect_path} />
    ) : (
      <Outlet />
    )
  ) : (
    <Backdrop open={true}>
      <Card elevation={0}>
        <Stack
          spacing={2}
          pt={2}
          px={2}
          pb={1}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
          <Typography variant="body2">初期化中</Typography>
        </Stack>
      </Card>
    </Backdrop>
  );
};
