import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { SetupIntent } from "../../DAL/stripe/SetupIntent";
import { Box, Skeleton } from "@mui/material";
import { useApplicationPreference } from "../../provider/ApplicationPreferenceProvider";
import { CardFrom } from "./CardFrom";
type Props = {
  shopId: string;
  stripePubKey: string;
  stripeCustomerId: string;
  authToken: string | null;
};
export const SetupFrom = (props: Props) => {
  const [clientSecret, setClientSecret] = useState<string>();
  const stripePromise = loadStripe(props.stripePubKey);
  const { preference } = useApplicationPreference();

  useEffect(() => {
    if (!clientSecret) {
      SetupIntent(props.shopId, props.authToken)
        .publishSetupIntent({
          shopId: props.shopId,
          stripeCustomerId: props.stripeCustomerId,
        })
        .then((result) => {
          ;
          setClientSecret(result.clientSecret);
        });
    }
  }, [clientSecret]);
  const appearance: Appearance = {
    theme: preference.mode === "dark" ? "night" : "flat",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    locale: "ja",
    appearance,
  };

  return (
    <Box>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CardFrom />
        </Elements>
      ) : (
        <Skeleton />
      )}
    </Box>
  );
};
