import {
  Box,
  Button,
  Container,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import MyPageTitlePanel from "../../components/layout/MyPageTitlePanel";
import { ScheduleDatePanel } from "../../components/layout/ScheduleDatePanel";
import { ScheduleTimePanel } from "../../components/layout/ScheduleTimePanel";
import { ScheduleCard } from "../../components/parts/card/ScheduleCard";
import { LessonStyle } from "../../DAL/API";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import Links from "@mui/material/Link";
import { useSearchParams } from "react-router-dom";
import liff from "@line/liff";
import LoadingCard from "../../components/parts/card/LoadingCard";

export const CustomerReservationsPage = () => {
  const { store, dispatch } = useApplicationStore();

  const onClickReadMoreHandler = useCallback(() => {
    dispatch({
      type: "RESERVATION_FETCHING",
      payload: {
        shopId: store.shop?.id!,
        customerId: store.customer?.serviceAccount.id!,
        lineAccessToken: liff.getAccessToken(),
        nextToken: store.reservations?.active?.nextToken,
      },
    });
  }, [dispatch, store.reservations?.active?.nextToken]);

  useEffect(() => {
    console.log(store.reservations?.active)
    if (!store.reservations?.active) {
      dispatch({
        type: "RESERVATION_FETCHING",
        payload: {
          shopId: store.shop?.id!,
          customerId: store.customer?.serviceAccount.id!,
          lineAccessToken: liff.getAccessToken(),
          nextToken: store.reservations?.active?.nextToken,
        },
      });
    }
  }, [dispatch]);
  return (
    <Container>
      <Box>
        <Stack spacing={2}>
          <MyPageTitlePanel
            title={`予約履歴`}
            spacing={1.2}
            textAlign="center"
          />
          {store.reservations !== null ? (
            store.reservations?.active?.data.length! > 0 ? (
              <Stack spacing={2} p={1}>
                {store.reservations?.active?.data
                  ?.sort((a, b) =>
                    (a!.schedule?.start??1) < (b!.schedule?.start??1) ? 1 : -1
                  )
                  .map((reservation) => (
                    <ScheduleCard
                      key={reservation?.id}
                      schedule={reservation?.schedule!}
                      selectedLessonStyle={reservation?.lessonStyle!}
                    >
                      <ScheduleDatePanel
                        start={reservation?.schedule?.start!}
                      />
                      <ScheduleTimePanel
                        startTime={reservation?.schedule?.start!}
                        endTime={reservation?.schedule?.end!}
                      />
                      {reservation?.lessonStyle !== LessonStyle.STUDIO ? (
                        <Box mt={3}>
                          <Stack>
                            <Typography variant="subtitle2">
                              Zoomミーティングリンク
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                display: "block",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              color="info"
                            >
                              <Links
                                href={reservation?.schedule?.link!}
                                underline="always"
                                target="_blank"
                              >
                                {reservation?.schedule?.link}
                              </Links>
                            </Typography>
                          </Stack>
                          <Stack mt={1}>
                            <Typography variant="subtitle2">
                              パスワード
                            </Typography>
                            <Typography variant="caption">
                              {reservation?.schedule?.pass}
                            </Typography>
                          </Stack>
                        </Box>
                      ) : null}
                    </ScheduleCard>
                  ))}
                {store.reservations?.active?.nextToken !== null ? (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={onClickReadMoreHandler}
                  >
                    さらに表示する
                  </Button>
                ) : null}
              </Stack>
            ) : (
              <Typography padding={1.5} align="center">
                予約はありません。
              </Typography>
            )
          ) : (
            <Stack spacing={1}>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </Stack>
          )}
        </Stack>
      </Box>
    </Container>
  );
};
