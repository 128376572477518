import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import MyPageTitlePanel from "../components/layout/MyPageTitlePanel";
import { MasterTicket, TicketType } from "../DAL/API";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import liff from "@line/liff";

export const SubscriptionsPage = () => {
  const { store, dispatch } = useApplicationStore();
  const { shopId } = useParams();
  const [tickets, setTickets] = useState<MasterTicket[]>();
  const navigate = useNavigate();

  const _getData = useCallback(() => {
    if (store.tickets === null) {
      dispatch({
        type: "APP_DATA_FETCHING",
        payload: { shopId: shopId!, lineAccessToken: liff.getAccessToken()},
      });
    } else {
      const purchasedIds = store.customer?.serviceAccount.purchasedTickets?.map(
        (ticket) => ticket?.masterTicketId
      );
      const notPurchasedTickets = store.tickets.filter(
        (t) =>
          !purchasedIds?.includes(t.id) && t.type === TicketType.SUBSCRIPTION
      );
      const notPrivateSubscription =
        notPurchasedTickets?.filter((mt) => !mt.isPrivate) ?? [];
      const privateSubscription =
        notPurchasedTickets?.filter(
          (mt) =>
            mt?.privateScopedCustomerId?.includes(
              store.customer?.serviceAccount.id!
            ) && mt.isPrivate
        ) ?? [];

      setTickets([...notPrivateSubscription, ...privateSubscription] ?? []);
    }
  }, [dispatch, navigate, shopId, store.tickets]);

  useEffect(() => {
    _getData();
  }, [_getData, store.tickets]);
  return (
    <Box>
      <Container>
        <Stack spacing={2}>
          <MyPageTitlePanel
            title={`月謝一覧`}
            spacing={1.2}
            textAlign="center"
          />
          {tickets?.length! > 0 ? (
            tickets?.map((ticket) => (
              <Card
                variant="outlined"
                elevation={0}
                sx={{ margin: "12px 0 32px", padding: "12px" }}
                key={ticket.id}
              >
                <Typography fontWeight={600}>{ticket?.name}</Typography>
                <Divider sx={{ margin: "6px 0 12px" }} />
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography>金額</Typography>
                      <Typography>
                        {ticket?.price.toLocaleString()}円
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography>1月あたりの利用回数:</Typography>
                      <Typography>{ticket?.maxCount}回</Typography>
                    </Stack>
                    {ticket?.hasDailyLimit ? (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography>1日あたりの利用可能回数</Typography>
                        <Typography>{ticket?.limitPerDaily}回</Typography>
                      </Stack>
                    ) : null}
                    {ticket?.hasWeeklyLimit ? (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography>週あたりの利用可能回数</Typography>
                        <Typography>{ticket?.limitPerWeekly}回</Typography>
                      </Stack>
                    ) : null}
                    <Stack direction="row" justifyContent="space-between">
                      <Typography>自動請求日</Typography>
                      <Typography>毎月初日</Typography>
                    </Stack>
                  </Stack>
                  <Button
                    variant="contained"
                    component={Link}
                    to={`/s/${shopId}/subscription/${ticket.id}/comfirm`}
                  >
                    加入する
                  </Button>
                </Stack>
              </Card>
            ))
          ) : (
            <Paper elevation={0} variant="outlined">
              <Typography padding={1.5} align="center">
                未加入の月謝はありません
              </Typography>
            </Paper>
          )}
        </Stack>
      </Container>
    </Box>
  );
};
