import { formatDate, formatRange } from "@fullcalendar/react";
import {
  Typography,
  Divider,
  Container,
  Button,
  Card,
  Chip,
  Paper,
  Box,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  LessonStyle,
  MasterTicket,
  PurchasedTicket,
  Schedule,
  TicketType,
} from "../../DAL/API";
import { ScheduleInfoPanel } from "../layout/ScheduleInfoPanel";
import {
  PurchasingReservationAnnounceCard,
  SingleReservationAnnounceCard,
} from "../parts/card/ReservationAnnounceCard";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { AvailablePurchasedTicketCard } from "../parts/card/AvailablePurchasedCard";

type Prop = {
  schedule: Schedule;
  style: LessonStyle;
};

export const ScheduleDetail: React.FC<Prop> = React.memo(
  ({ schedule, style }) => {
    const [coupons, setCoupons] =
      useState<(MasterTicket | null | undefined)[]>();
    const [subscriptions, setSubscriptions] =
      useState<(MasterTicket | null | undefined)[]>();
    const [availableCouponTicket, setCouponTicket] = useState<
      (PurchasedTicket | null | undefined)[]
    >([]);
    const [availableSubscribedTicket, setSubscribedTicket] = useState<
      (PurchasedTicket | null | undefined)[]
    >([]);
    const { store } = useApplicationStore();

    useEffect(() => {
      console.log(schedule)
      if (schedule.lesson.hasTicket) {
        const tickets = schedule.lesson.masterTickets?.items.map(
          (lmt) => lmt?.masterTicket
        );
 
        const notPrivateCoupon = tickets?.filter(
          (mt) => mt?.type === TicketType.COUPON && !mt.isPrivate
        ) ?? [];
        const privateCoupon = tickets?.filter(
          (mt) =>
            mt?.privateScopedCustomerId?.includes(
              store.customer?.serviceAccount.id!
            ) && mt?.type === TicketType.COUPON && mt.isPrivate
        ) ?? [];

        const notPrivateSubscription = tickets?.filter(
          (mt) => mt?.type === TicketType.SUBSCRIPTION && !mt.isPrivate
        ) ?? [];
        const privateSubscription= tickets?.filter(
          (mt) =>
            mt?.privateScopedCustomerId?.includes(
              store.customer?.serviceAccount.id!
            )&&mt?.type === TicketType.SUBSCRIPTION  && mt.isPrivate
        ) ?? [];

        setCoupons(
          [...notPrivateCoupon, ...privateCoupon] ?? []
        );
        setSubscriptions(
          [...notPrivateSubscription, ...privateSubscription] ?? []
        );
        if (store.customer?.serviceAccount.purchasedTickets?.length! > 0) {
          const ticketIds = tickets?.map((t) => t?.id) ?? [];
          const purchasedTicketIds =
            store.customer?.serviceAccount.purchasedTickets?.map(
              (pt) => pt?.masterTicketId
            ) ?? [];
          const availableIds = new Set(
            [...ticketIds, ...purchasedTicketIds].filter(
              (item) =>
                ticketIds.includes(item) && purchasedTicketIds.includes(item)
            )
          );
          console.log(tickets)
          console.log(ticketIds)
          console.log(availableIds)
          setCouponTicket(
            store.customer?.serviceAccount.purchasedTickets?.filter(
              (pt) =>
                availableIds.has(pt?.masterTicketId) &&
                pt?.type === TicketType.COUPON
            ) ?? []
          );

          setSubscribedTicket(
            store.customer?.serviceAccount.purchasedTickets?.filter(
              (pt) =>
                availableIds.has(pt?.masterTicketId) &&
                pt?.type === TicketType.SUBSCRIPTION
            ) ?? []
          );
        }
      }
    }, []);

    return (
      <Container maxWidth="sm">
        <Box>
          <Stack spacing={2}>
            <ScheduleInfoPanel schedule={schedule} lessonStyle={style} />
            <Paper variant="outlined">
              <Stack direction="column" spacing={4} px={1.5} py={3}>
                {availableCouponTicket?.length! > 0 ? (
                  <Stack spacing={1.75}>
                    <Typography
                      variant="h4"
                      textAlign="center"
                      fontWeight="600"
                    >
                      購入済チケットで予約する
                    </Typography>
                    {availableCouponTicket?.map((pt) => (
                      <AvailablePurchasedTicketCard
                        key={pt?.id}
                        ticket={pt!}
                        schedule={schedule}
                        style={style}
                      />
                    ))}
                  </Stack>
                ) : null}
                {availableSubscribedTicket?.length! > 0 ? (
                  <Stack spacing={1.75}>
                    <Typography
                      variant="h4"
                      textAlign="center"
                      fontWeight="600"
                    >
                      月謝制加入者として予約
                    </Typography>
                    {availableSubscribedTicket?.map((pt) => (
                      <AvailablePurchasedTicketCard
                        key={pt?.id}
                        ticket={pt!}
                        schedule={schedule}
                        style={style}
                      />
                    ))}
                  </Stack>
                ) : null}
                <Stack spacing={1.75}>
                  {subscriptions?.length! > 0 &&
                  availableSubscribedTicket?.length === 0 ? (
                    <PurchasingReservationAnnounceCard
                      scheduleId={schedule.id}
                      tickets={subscriptions!}
                      type={TicketType.SUBSCRIPTION}
                      style={style}
                    />
                  ) : null}
                  {coupons?.length! > 0 &&
                  availableCouponTicket?.length === 0 ? (
                    <PurchasingReservationAnnounceCard
                      scheduleId={schedule.id}
                      tickets={coupons!}
                      type={TicketType.COUPON}
                      style={style}
                    />
                  ) : null}
                  {schedule.lesson.canSingle &&
                  (availableSubscribedTicket?.length! === 0 ||
                    availableCouponTicket?.length === 0) ? (
                    <SingleReservationAnnounceCard
                      schedule={schedule}
                      style={style}
                      type={"SINGLE"}
                    />
                  ) : null}
                  {schedule.lesson.hasTrial &&
                  (availableSubscribedTicket?.length! === 0 ||
                    !availableCouponTicket) ? (
                    <SingleReservationAnnounceCard
                      schedule={schedule}
                      style={style}
                      type={"TRIAL"}
                    />
                  ) : null}
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </Box>
      </Container>
    );
  }
);
