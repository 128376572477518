import AWSAppSyncClient from "aws-appsync";
import gql from "graphql-tag";
import {
  CreateSubscriptionInput,
  CreateSubscriptionMutation,
  CreateSubscriptionResult,

} from "../API";
import { createSubscription } from "../mutations";

export const Subscription = (
  shopId: string,
  authToken: string | null
) => {
  const createComfirmedSubscription = (input: CreateSubscriptionInput) => {
    const mutation = gql(createSubscription);
    const appsyncClient = new AWSAppSyncClient({
      url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT!,
      region: process.env.REACT_APP_AWS_REGION!,
      auth: {
        type: "AWS_LAMBDA",
        token: authToken === null ? 'custome_76198d0f-350d-4829-901a-1ad9e899ff4b': `line_${authToken}`,
      },
      disableOffline: true,
    });
    return new Promise<CreateSubscriptionResult>(async (res, rej) => {
      await appsyncClient
        .mutate<CreateSubscriptionMutation>({
          variables: { createSubscriptionInput: input },
          mutation: mutation,
        })
        .then((result) => {
          ;
          res(
            result.data
              ?.createSubscription as CreateSubscriptionResult
          );
        }).catch((error)=>{
          const r:CreateSubscriptionResult = {
            __typename: "CreateSubscriptionResult",
            isError: true,
            errMessage: error.message,
            paymentIntentData: null,
            ticket: null
          }
          res(r)
        });
    });
  };
  return { createComfirmedSubscription };
};
