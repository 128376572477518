/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type LessonMasterTicketInput = {
  id?: string | null,
  lessonId: string,
  masterTicketId: string,
  owner?: string | null,
};

export type LessonMasterTickets = {
  __typename: "LessonMasterTickets",
  id: string,
  lessonId: string,
  lesson?: Lesson | null,
  masterTicketId: string,
  masterTicket?: MasterTicket | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Lesson = {
  __typename: "Lesson",
  id: string,
  shopId: string,
  name: string,
  thumbnail?: string | null,
  description?: string | null,
  duration?: number | null,
  style: LessonStyle,
  canSingle: boolean,
  singlePrice?: number | null,
  hasTrial: boolean,
  trialPrice?: number | null,
  hasTicket: boolean,
  masterTickets?: ModelLessonMasterTicketsConnection | null,
  canPublish: boolean,
  studio_capacity?: number | null,
  online_capacity?: number | null,
  requiredTime?: LessonRequiredTime | null,
  staff: Staff,
  color?: string | null,
  order?: number | null,
  cancelTimeoutLimit?: number | null,
  reserveTimeoutLimit?: number | null,
  createdAt: string,
  updatedAt: string,
};

export enum LessonStyle {
  STUDIO = "STUDIO",
  ONLINE = "ONLINE",
  BOTH = "BOTH",
}


export type ModelLessonMasterTicketsConnection = {
  __typename: "ModelLessonMasterTicketsConnection",
  items:  Array<LessonMasterTickets | null >,
  nextToken?: string | null,
};

export type LessonRequiredTime = {
  __typename: "LessonRequiredTime",
  timeUnit: TimeUnit,
  value: number,
};

export enum TimeUnit {
  hour = "hour",
  minutes = "minutes",
  second = "second",
}


export type Staff = {
  __typename: "Staff",
  shopId: string,
  id: string,
  name: string,
};

export type MasterTicket = {
  __typename: "MasterTicket",
  id: string,
  shopId: string,
  stripeProductId?: string | null,
  stripePriceId?: string | null,
  name: string,
  description?: string | null,
  maxCount: number,
  price: number,
  type: TicketType,
  deadline?: Deadline | null,
  hasDailyLimit?: boolean | null,
  limitPerDaily?: number | null,
  hasOnlineDailyLimit?: boolean | null,
  onlineLimitPerDaily?: number | null,
  hasWeeklyLimit?: boolean | null,
  limitPerWeekly?: number | null,
  hasOnlineWeeklyLimit?: boolean | null,
  onlineLimitPerWeekly?: number | null,
  isPrivate?: boolean | null,
  privateScopedCustomerId?: Array< string | null > | null,
  lessons?: ModelLessonMasterTicketsConnection | null,
  isAvailable: boolean,
  createdAt: string,
  updatedAt: string,
};

export enum TicketType {
  COUPON = "COUPON",
  SUBSCRIPTION = "SUBSCRIPTION",
}


export type Deadline = {
  __typename: "Deadline",
  type: DeadlineType,
  num: number,
};

export enum DeadlineType {
  DAYLY = "DAYLY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}


export type BatchAddScheduleInput = {
  shopId: string,
  data?: Array< ScheduleInput | null > | null,
};

export type ScheduleInput = {
  shopId: string,
  id?: string | null,
  lessonId: string,
  start: string,
  end: string,
  expiredAt: string,
  zoomMeetingId?: string | null,
  link?: string | null,
  pass?: string | null,
  isPublished: boolean,
  studio_capacity?: number | null,
  acceptableStudioNumber?: number | null,
  online_capacity?: number | null,
  acceptableOnlineNumber?: number | null,
};

export type BatchAddSchedulesResult = {
  __typename: "BatchAddSchedulesResult",
  shopId: string,
  data?:  Array<Schedule | null > | null,
};

export type Schedule = {
  __typename: "Schedule",
  shopId: string,
  id: string,
  lessonId: string,
  lesson: Lesson,
  start: string,
  end: string,
  expiredAt?: string | null,
  zoomMeetingId?: string | null,
  link?: string | null,
  pass?: string | null,
  isPublished: boolean,
  studio_capacity?: number | null,
  online_capacity?: number | null,
  acceptableStudioNumber?: number | null,
  acceptableOnlineNumber?: number | null,
  reservations?: ModelReservationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelReservationConnection = {
  __typename: "ModelReservationConnection",
  items:  Array<Reservation | null >,
  nextToken?: string | null,
};

export type Reservation = {
  __typename: "Reservation",
  shopId: string,
  id: string,
  scheduleId: string,
  schedule?: Schedule | null,
  customerId: string,
  customer: Customer,
  lessonStyle?: LessonStyle | null,
  status?: ReservationStatus | null,
  transactionId: string,
  transaction?: ReservationTransaction | null,
  isTrial?: boolean | null,
  consumedTicketId?: string | null,
  expiredAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Customer = {
  __typename: "Customer",
  shopId: string,
  id: string,
  stripeId?: string | null,
  name?: string | null,
  lineId?: string | null,
  email?: string | null,
  purchasedTickets?:  Array<PurchasedTicket | null > | null,
  reservations?: ModelReservationConnection | null,
  expiredReservations?: ModelExpiredReservationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type PurchasedTicket = {
  __typename: "PurchasedTicket",
  shopId: string,
  id: string,
  masterTicketId: string,
  paymentIntentId?: string | null,
  stripeProductId?: string | null,
  stripePriceId?: string | null,
  name: string,
  description?: string | null,
  maxCount: number,
  currentCount: number,
  price: number,
  type: TicketType,
  deadline?: Deadline | null,
  hasDailyLimit?: boolean | null,
  limitPerDaily?: number | null,
  hasOnlineDailyLimit?: boolean | null,
  onlineLimitPerDaily?: number | null,
  hasWeeklyLimit?: boolean | null,
  limitPerWeekly?: number | null,
  hasOnlineWeeklyLimit?: boolean | null,
  onlineLimitPerWeekly?: number | null,
  lastUsedAt?: string | null,
  lastOnlineUsedAt?: string | null,
  purchasedAt?: string | null,
  expireAt?: string | null,
  isAvailable?: boolean | null,
  histories?:  Array<TicketHistory | null > | null,
};

export type TicketHistory = {
  __typename: "TicketHistory",
  title?: string | null,
  operation?: TicketOperation | null,
  createdAt?: number | null,
};

export type Log = {
  __typename: "Log",
  createdAt?: number | null,
};

export enum TicketOperation {
  Consume = "Consume",
  Cancel = "Cancel",
  AdminOperation = "AdminOperation",
}


export type ModelExpiredReservationConnection = {
  __typename: "ModelExpiredReservationConnection",
  items:  Array<ExpiredReservation | null >,
  nextToken?: string | null,
};

export type ExpiredReservation = {
  __typename: "ExpiredReservation",
  shopId: string,
  id: string,
  expiredScheduleId: string,
  expiredSchedule: ExpiredSchedule,
  customerId: string,
  customer: Customer,
  lessonStyle?: LessonStyle | null,
  status?: ReservationStatus | null,
  transactionId: string,
  transaction?: ReservationTransaction | null,
  isTrial?: boolean | null,
  consumedTicketId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ExpiredSchedule = {
  __typename: "ExpiredSchedule",
  shopId: string,
  id: string,
  lessonId: string,
  lesson: Lesson,
  start: string,
  end: string,
  zoomMeetingId?: string | null,
  link?: string | null,
  pass?: string | null,
  isPublished: boolean,
  studio_capacity?: number | null,
  online_capacity?: number | null,
  acceptableStudioNumber?: number | null,
  acceptableOnlineNumber?: number | null,
  expiredReservations?: ModelExpiredReservationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum ReservationStatus {
  Entry = "Entry",
  Success = "Success",
  Faild = "Faild",
  Canceled = "Canceled",
}


export type ReservationTransaction = {
  __typename: "ReservationTransaction",
  reservationId: string,
  id: string,
  customerId: string,
  details?:  Array<TransactionContent | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type TransactionContent = {
  __typename: "TransactionContent",
  id: string,
  process: ReservationProcess,
  status: TransactionStatus,
  message?: string | null,
  createdAt: string,
};

export enum ReservationProcess {
  Entry = "Entry",
  Payment = "Payment",
  CapacityConfirmation = "CapacityConfirmation",
}


export enum TransactionStatus {
  Processing = "Processing",
  Pending = "Pending",
  Success = "Success",
  Faild = "Faild",
}


export type CreatePreReservationInput = {
  shopId: string,
  id?: string | null,
  scheduleId: string,
  customerId: string,
  consumedTicketId?: string | null,
  lessonStyle: LessonStyle,
  status?: ReservationStatus | null,
  isTrial?: boolean | null,
  expiredAt: string,
  description?: string | null,
};

export type ReserveUsingTicketInput = {
  purchasedTicketId: string,
  purchasedTicketIndex: number,
};

export type CreateTicketWithStripeInput = {
  id?: string | null,
  shopId: string,
  name?: string | null,
  description?: string | null,
  maxCount: number,
  price: number,
  type: TicketType,
  deadline: DeadlineInput,
  isAvailable?: boolean | null,
};

export type DeadlineInput = {
  type: DeadlineType,
  num: number,
};

export type CreateTicketOnStripeResult = {
  __typename: "CreateTicketOnStripeResult",
  stripeProductId?: string | null,
  stripePriceId?: string | null,
};

export type CreateCustomerAccountInput = {
  shopId: string,
  lineId: string,
  name: string,
  email: string,
};

export type CustomerAccount = {
  __typename: "CustomerAccount",
  serviceCustomerInfo?: Customer | null,
  stripeCustomerInfo?: string | null,
  stripePaymentMethodInfo?: string | null,
};

export type StripeAccount = {
  __typename: "StripeAccount",
  stripeCustomerInfo?: string | null,
  stripePaymentMethodInfo?: string | null,
};

export type CreateSetupIntentInput = {
  shopId: string,
  stripeCustomerId: string,
};

export type CreateSetupIntentResult = {
  __typename: "CreateSetupIntentResult",
  clientSecret: string,
};

export type CreateTicketPaymentIntentInput = {
  shopId: string,
  systemCustomerId: string,
  stripeCustomerId: string,
  priceId: string,
  quantity: number,
  masterTicketId: string,
  masterTicketName: string,
  paymentMethodId: string,
};

export type CreateTicketPaymentIntentResult = {
  __typename: "CreateTicketPaymentIntentResult",
  isError: boolean,
  errMessage?: string | null,
  paymentIntentData?: string | null,
  ticket?: PurchasedTicket | null,
};

export type CreateSinglePaymentIntentInput = {
  shopId: string,
  systemCustomerId: string,
  stripeCustomerId: string,
  price: number,
  quantity: number,
  paymentMethodId: string,
  description: string,
};

export type CreateSubscriptionInput = {
  shopId: string,
  systemCustomerId: string,
  stripeCustomerId: string,
  priceId: string,
  couponId?: string | null,
  masterTicketId: string,
  masterTicketName: string,
  paymentMethodId: string,
};

export type CreateSubscriptionResult = {
  __typename: "CreateSubscriptionResult",
  isError: boolean,
  errMessage?: string | null,
  paymentIntentData?: string | null,
  ticket?: PurchasedTicket | null,
};

export type ConsumePurchasedTicketInput = {
  shopId: string,
  customerId: string,
  index: number,
  purchasedTicketId: string,
  description?: string | null,
};

export type UpdatePurchasedTicketCountResult = {
  __typename: "UpdatePurchasedTicketCountResult",
  shopId: string,
  customerId: string,
  purchasedTicketId: string,
};

export type RecoverPurchasedTicketInput = {
  shopId: string,
  customerId: string,
  index: number,
  purchasedTicketId: string,
  description?: string | null,
};

export type SetZoomAuthTokenInput = {
  shopId: string,
  accountEmail: string,
  code: string,
};

export type SetZoomAuthTokenResult = {
  __typename: "SetZoomAuthTokenResult",
  accountEmail?: string | null,
  accessToken?: string | null,
  tokenType?: string | null,
  refreshToken?: string | null,
  expiresIn?: number | null,
  scope?: string | null,
  updatedAt?: string | null,
  dailyCreateMeetingThrottling?: number | null,
};

export type CreateZoomMeetingInput = {
  shopId: string,
  accountEmail: string,
  inputs: Array< ZoomMeetingInput >,
};

export type ZoomMeetingInput = {
  scheduleId?: string | null,
  topic?: string | null,
  agenda?: string | null,
  default_password?: boolean | null,
  duration?: number | null,
  password?: string | null,
  timezone?: string | null,
  start_time?: string | null,
};

export type CreateZoomMeetingsResult = {
  __typename: "CreateZoomMeetingsResult",
  zoomInfo:  Array<ZoomMeetingInfo >,
};

export type ZoomMeetingInfo = {
  __typename: "ZoomMeetingInfo",
  scheduleId?: string | null,
  zoomMeetingId: string,
  start_url: string,
  join_url: string,
  password: string,
};

export type CreateShopInput = {
  id?: string | null,
  name: string,
  address?: string | null,
  email?: string | null,
  tel?: string | null,
  profile?: string | null,
  icon?: string | null,
  stripePubKey?: string | null,
  stripeSecKey?: string | null,
  mode?: Mode | null,
  stripe?: StripeInput | null,
  zoom?: ZoomInput | null,
  dayoff?: Array< number | null > | null,
  staffs?: Array< StaffInput | null > | null,
};

export enum Mode {
  dev = "dev",
  prod = "prod",
}


export type StripeInput = {
  dev?: StripeKeyInput | null,
  prod?: StripeKeyInput | null,
};

export type StripeKeyInput = {
  pubKey?: string | null,
  secKey?: string | null,
};

export type ZoomInput = {
  accountEmail?: string | null,
  accessToken?: string | null,
  tokenType?: string | null,
  refreshToken?: string | null,
  expiresIn?: number | null,
  scope?: string | null,
  updatedAt?: string | null,
  dailyCreateMeetingThrottling?: number | null,
};

export type StaffInput = {
  shopId: string,
  id?: string | null,
  name: string,
};

export type ModelShopConditionInput = {
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  stripePubKey?: ModelStringInput | null,
  stripeSecKey?: ModelStringInput | null,
  mode?: ModelModeInput | null,
  dayoff?: ModelIntInput | null,
  and?: Array< ModelShopConditionInput | null > | null,
  or?: Array< ModelShopConditionInput | null > | null,
  not?: ModelShopConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelModeInput = {
  eq?: Mode | null,
  ne?: Mode | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Shop = {
  __typename: "Shop",
  id: string,
  name: string,
  address?: string | null,
  email?: string | null,
  tel?: string | null,
  profile?: string | null,
  icon?: string | null,
  stripePubKey?: string | null,
  stripeSecKey?: string | null,
  mode?: Mode | null,
  stripe?: Stripe | null,
  zoom?: Zoom | null,
  dayoff?: Array< number | null > | null,
  staffs?:  Array<Staff | null > | null,
  schedules?: ModelScheduleConnection | null,
  tickets?: ModelMasterTicketConnection | null,
  lessons?: ModelLessonConnection | null,
  customers?: ModelCustomerConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Stripe = {
  __typename: "Stripe",
  dev?: StripeKey | null,
  prod?: StripeKey | null,
};

export type StripeKey = {
  __typename: "StripeKey",
  pubKey?: string | null,
  secKey?: string | null,
};

export type Zoom = {
  __typename: "Zoom",
  accountEmail?: string | null,
  accessToken?: string | null,
  tokenType?: string | null,
  refreshToken?: string | null,
  expiresIn?: number | null,
  scope?: string | null,
  updatedAt?: string | null,
  dailyCreateMeetingThrottling?: number | null,
};

export type ModelScheduleConnection = {
  __typename: "ModelScheduleConnection",
  items:  Array<Schedule | null >,
  nextToken?: string | null,
};

export type ModelMasterTicketConnection = {
  __typename: "ModelMasterTicketConnection",
  items:  Array<MasterTicket | null >,
  nextToken?: string | null,
};

export type ModelLessonConnection = {
  __typename: "ModelLessonConnection",
  items:  Array<Lesson | null >,
  nextToken?: string | null,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
};

export type UpdateShopInput = {
  id: string,
  name?: string | null,
  address?: string | null,
  email?: string | null,
  tel?: string | null,
  profile?: string | null,
  icon?: string | null,
  stripePubKey?: string | null,
  stripeSecKey?: string | null,
  mode?: Mode | null,
  stripe?: StripeInput | null,
  zoom?: ZoomInput | null,
  dayoff?: Array< number | null > | null,
  staffs?: Array< StaffInput | null > | null,
};

export type DeleteShopInput = {
  id: string,
};

export type CreateScheduleInput = {
  shopId: string,
  id?: string | null,
  lessonId: string,
  start: string,
  end: string,
  expiredAt?: string | null,
  zoomMeetingId?: string | null,
  link?: string | null,
  pass?: string | null,
  isPublished: boolean,
  studio_capacity?: number | null,
  online_capacity?: number | null,
  acceptableStudioNumber?: number | null,
  acceptableOnlineNumber?: number | null,
};

export type ModelScheduleConditionInput = {
  lessonId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  expiredAt?: ModelStringInput | null,
  zoomMeetingId?: ModelStringInput | null,
  link?: ModelStringInput | null,
  pass?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  studio_capacity?: ModelIntInput | null,
  online_capacity?: ModelIntInput | null,
  acceptableStudioNumber?: ModelIntInput | null,
  acceptableOnlineNumber?: ModelIntInput | null,
  and?: Array< ModelScheduleConditionInput | null > | null,
  or?: Array< ModelScheduleConditionInput | null > | null,
  not?: ModelScheduleConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateScheduleInput = {
  shopId: string,
  id: string,
  lessonId?: string | null,
  start?: string | null,
  end?: string | null,
  expiredAt?: string | null,
  zoomMeetingId?: string | null,
  link?: string | null,
  pass?: string | null,
  isPublished?: boolean | null,
  studio_capacity?: number | null,
  online_capacity?: number | null,
  acceptableStudioNumber?: number | null,
  acceptableOnlineNumber?: number | null,
};

export type DeleteScheduleInput = {
  shopId: string,
  id: string,
};

export type CreateExpiredScheduleInput = {
  shopId: string,
  id?: string | null,
  lessonId: string,
  start: string,
  end: string,
  zoomMeetingId?: string | null,
  link?: string | null,
  pass?: string | null,
  isPublished: boolean,
  studio_capacity?: number | null,
  online_capacity?: number | null,
  acceptableStudioNumber?: number | null,
  acceptableOnlineNumber?: number | null,
};

export type ModelExpiredScheduleConditionInput = {
  lessonId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  zoomMeetingId?: ModelStringInput | null,
  link?: ModelStringInput | null,
  pass?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  studio_capacity?: ModelIntInput | null,
  online_capacity?: ModelIntInput | null,
  acceptableStudioNumber?: ModelIntInput | null,
  acceptableOnlineNumber?: ModelIntInput | null,
  and?: Array< ModelExpiredScheduleConditionInput | null > | null,
  or?: Array< ModelExpiredScheduleConditionInput | null > | null,
  not?: ModelExpiredScheduleConditionInput | null,
};

export type UpdateExpiredScheduleInput = {
  shopId: string,
  id: string,
  lessonId?: string | null,
  start?: string | null,
  end?: string | null,
  zoomMeetingId?: string | null,
  link?: string | null,
  pass?: string | null,
  isPublished?: boolean | null,
  studio_capacity?: number | null,
  online_capacity?: number | null,
  acceptableStudioNumber?: number | null,
  acceptableOnlineNumber?: number | null,
};

export type DeleteExpiredScheduleInput = {
  shopId: string,
  id: string,
};

export type CreateReservationInput = {
  shopId: string,
  id?: string | null,
  scheduleId: string,
  customerId: string,
  lessonStyle?: LessonStyle | null,
  status?: ReservationStatus | null,
  transactionId: string,
  isTrial?: boolean | null,
  consumedTicketId?: string | null,
  expiredAt?: string | null,
};

export type ModelReservationConditionInput = {
  scheduleId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  lessonStyle?: ModelLessonStyleInput | null,
  status?: ModelReservationStatusInput | null,
  transactionId?: ModelIDInput | null,
  isTrial?: ModelBooleanInput | null,
  consumedTicketId?: ModelIDInput | null,
  expiredAt?: ModelStringInput | null,
  and?: Array< ModelReservationConditionInput | null > | null,
  or?: Array< ModelReservationConditionInput | null > | null,
  not?: ModelReservationConditionInput | null,
};

export type ModelLessonStyleInput = {
  eq?: LessonStyle | null,
  ne?: LessonStyle | null,
};

export type ModelReservationStatusInput = {
  eq?: ReservationStatus | null,
  ne?: ReservationStatus | null,
};

export type UpdateReservationInput = {
  shopId: string,
  id: string,
  scheduleId?: string | null,
  customerId?: string | null,
  lessonStyle?: LessonStyle | null,
  status?: ReservationStatus | null,
  transactionId?: string | null,
  isTrial?: boolean | null,
  consumedTicketId?: string | null,
  expiredAt?: string | null,
};

export type DeleteReservationInput = {
  shopId: string,
  id: string,
};

export type CreateReservationTransactionInput = {
  reservationId: string,
  id?: string | null,
  customerId: string,
  details?: Array< TransactionContentInput | null > | null,
};

export type TransactionContentInput = {
  id?: string | null,
  process: ReservationProcess,
  status: TransactionStatus,
  message?: string | null,
  createdAt?: string | null,
};

export type ModelReservationTransactionConditionInput = {
  customerId?: ModelIDInput | null,
  and?: Array< ModelReservationTransactionConditionInput | null > | null,
  or?: Array< ModelReservationTransactionConditionInput | null > | null,
  not?: ModelReservationTransactionConditionInput | null,
};

export type UpdateReservationTransactionInput = {
  reservationId: string,
  id: string,
  customerId?: string | null,
  details?: Array< TransactionContentInput | null > | null,
};

export type DeleteReservationTransactionInput = {
  reservationId: string,
  id: string,
};

export type CreateExpiredReservationInput = {
  shopId: string,
  id?: string | null,
  expiredScheduleId: string,
  customerId: string,
  lessonStyle?: LessonStyle | null,
  status?: ReservationStatus | null,
  transactionId: string,
  isTrial?: boolean | null,
  consumedTicketId?: string | null,
};

export type ModelExpiredReservationConditionInput = {
  expiredScheduleId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  lessonStyle?: ModelLessonStyleInput | null,
  status?: ModelReservationStatusInput | null,
  transactionId?: ModelIDInput | null,
  isTrial?: ModelBooleanInput | null,
  consumedTicketId?: ModelIDInput | null,
  and?: Array< ModelExpiredReservationConditionInput | null > | null,
  or?: Array< ModelExpiredReservationConditionInput | null > | null,
  not?: ModelExpiredReservationConditionInput | null,
};

export type UpdateExpiredReservationInput = {
  shopId: string,
  id: string,
  expiredScheduleId?: string | null,
  customerId?: string | null,
  lessonStyle?: LessonStyle | null,
  status?: ReservationStatus | null,
  transactionId?: string | null,
  isTrial?: boolean | null,
  consumedTicketId?: string | null,
};

export type DeleteExpiredReservationInput = {
  shopId: string,
  id: string,
};

export type CreateCustomerInput = {
  shopId: string,
  id?: string | null,
  stripeId?: string | null,
  name?: string | null,
  lineId?: string | null,
  email?: string | null,
  purchasedTickets?: Array< PurchasedTicketInput | null > | null,
};

export type PurchasedTicketInput = {
  shopId: string,
  id?: string | null,
  masterTicketId: string,
  paymentIntentId?: string | null,
  stripeProductId?: string | null,
  stripePriceId?: string | null,
  name: string,
  description?: string | null,
  maxCount: number,
  currentCount: number,
  price: number,
  type: TicketType,
  deadline?: DeadlineInput | null,
  hasDailyLimit?: boolean | null,
  limitPerDaily?: number | null,
  hasOnlineDailyLimit?: boolean | null,
  onlineLimitPerDaily?: number | null,
  hasWeeklyLimit?: boolean | null,
  limitPerWeekly?: number | null,
  hasOnlineWeeklyLimit?: boolean | null,
  onlineLimitPerWeekly?: number | null,
  lastUsedAt?: string | null,
  lastOnlineUsedAt?: string | null,
  purchasedAt?: string | null,
  expireAt?: string | null,
  isAvailable?: boolean | null,
  histories?: Array< TicketHistoryInput | null > | null,
};

export type TicketHistoryInput = {
  title?: string | null,
  operation?: TicketOperation | null,
  createdAt?: number | null,
};

export type ModelCustomerConditionInput = {
  stripeId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  lineId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelCustomerConditionInput | null > | null,
  or?: Array< ModelCustomerConditionInput | null > | null,
  not?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerInput = {
  shopId: string,
  id: string,
  stripeId?: string | null,
  name?: string | null,
  lineId?: string | null,
  email?: string | null,
  purchasedTickets?: Array< PurchasedTicketInput | null > | null,
};

export type DeleteCustomerInput = {
  shopId: string,
  id: string,
};

export type CreateMasterTicketInput = {
  id?: string | null,
  shopId: string,
  stripeProductId?: string | null,
  stripePriceId?: string | null,
  name: string,
  description?: string | null,
  maxCount: number,
  price: number,
  type: TicketType,
  deadline?: DeadlineInput | null,
  hasDailyLimit?: boolean | null,
  limitPerDaily?: number | null,
  hasOnlineDailyLimit?: boolean | null,
  onlineLimitPerDaily?: number | null,
  hasWeeklyLimit?: boolean | null,
  limitPerWeekly?: number | null,
  hasOnlineWeeklyLimit?: boolean | null,
  onlineLimitPerWeekly?: number | null,
  isPrivate?: boolean | null,
  privateScopedCustomerId?: Array< string | null > | null,
  isAvailable: boolean,
};

export type ModelMasterTicketConditionInput = {
  shopId?: ModelIDInput | null,
  stripeProductId?: ModelIDInput | null,
  stripePriceId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  maxCount?: ModelIntInput | null,
  price?: ModelIntInput | null,
  type?: ModelTicketTypeInput | null,
  hasDailyLimit?: ModelBooleanInput | null,
  limitPerDaily?: ModelIntInput | null,
  hasOnlineDailyLimit?: ModelBooleanInput | null,
  onlineLimitPerDaily?: ModelIntInput | null,
  hasWeeklyLimit?: ModelBooleanInput | null,
  limitPerWeekly?: ModelIntInput | null,
  hasOnlineWeeklyLimit?: ModelBooleanInput | null,
  onlineLimitPerWeekly?: ModelIntInput | null,
  isPrivate?: ModelBooleanInput | null,
  privateScopedCustomerId?: ModelIDInput | null,
  isAvailable?: ModelBooleanInput | null,
  and?: Array< ModelMasterTicketConditionInput | null > | null,
  or?: Array< ModelMasterTicketConditionInput | null > | null,
  not?: ModelMasterTicketConditionInput | null,
};

export type ModelTicketTypeInput = {
  eq?: TicketType | null,
  ne?: TicketType | null,
};

export type UpdateMasterTicketInput = {
  id: string,
  shopId?: string | null,
  stripeProductId?: string | null,
  stripePriceId?: string | null,
  name?: string | null,
  description?: string | null,
  maxCount?: number | null,
  price?: number | null,
  type?: TicketType | null,
  deadline?: DeadlineInput | null,
  hasDailyLimit?: boolean | null,
  limitPerDaily?: number | null,
  hasOnlineDailyLimit?: boolean | null,
  onlineLimitPerDaily?: number | null,
  hasWeeklyLimit?: boolean | null,
  limitPerWeekly?: number | null,
  hasOnlineWeeklyLimit?: boolean | null,
  onlineLimitPerWeekly?: number | null,
  isPrivate?: boolean | null,
  privateScopedCustomerId?: Array< string | null > | null,
  isAvailable?: boolean | null,
};

export type DeleteMasterTicketInput = {
  id: string,
};

export type CreateLessonInput = {
  id?: string | null,
  shopId: string,
  name: string,
  thumbnail?: string | null,
  description?: string | null,
  duration?: number | null,
  style: LessonStyle,
  canSingle: boolean,
  singlePrice?: number | null,
  hasTrial: boolean,
  trialPrice?: number | null,
  hasTicket: boolean,
  canPublish: boolean,
  studio_capacity?: number | null,
  online_capacity?: number | null,
  requiredTime?: LessonRequiredTimeInput | null,
  staff: StaffInput,
  color?: string | null,
  order?: number | null,
  cancelTimeoutLimit?: number | null,
  reserveTimeoutLimit?: number | null,
};

export type LessonRequiredTimeInput = {
  timeUnit: TimeUnit,
  value: number,
};

export type ModelLessonConditionInput = {
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  description?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  style?: ModelLessonStyleInput | null,
  canSingle?: ModelBooleanInput | null,
  singlePrice?: ModelIntInput | null,
  hasTrial?: ModelBooleanInput | null,
  trialPrice?: ModelIntInput | null,
  hasTicket?: ModelBooleanInput | null,
  canPublish?: ModelBooleanInput | null,
  studio_capacity?: ModelIntInput | null,
  online_capacity?: ModelIntInput | null,
  color?: ModelStringInput | null,
  order?: ModelIntInput | null,
  cancelTimeoutLimit?: ModelIntInput | null,
  reserveTimeoutLimit?: ModelIntInput | null,
  and?: Array< ModelLessonConditionInput | null > | null,
  or?: Array< ModelLessonConditionInput | null > | null,
  not?: ModelLessonConditionInput | null,
};

export type UpdateLessonInput = {
  id: string,
  shopId?: string | null,
  name?: string | null,
  thumbnail?: string | null,
  description?: string | null,
  duration?: number | null,
  style?: LessonStyle | null,
  canSingle?: boolean | null,
  singlePrice?: number | null,
  hasTrial?: boolean | null,
  trialPrice?: number | null,
  hasTicket?: boolean | null,
  canPublish?: boolean | null,
  studio_capacity?: number | null,
  online_capacity?: number | null,
  requiredTime?: LessonRequiredTimeInput | null,
  staff?: StaffInput | null,
  color?: string | null,
  order?: number | null,
  cancelTimeoutLimit?: number | null,
  reserveTimeoutLimit?: number | null,
};

export type DeleteLessonInput = {
  id: string,
};

export type CreateLessonMasterTicketsInput = {
  id?: string | null,
  lessonId: string,
  masterTicketId: string,
};

export type ModelLessonMasterTicketsConditionInput = {
  lessonId?: ModelIDInput | null,
  masterTicketId?: ModelIDInput | null,
  and?: Array< ModelLessonMasterTicketsConditionInput | null > | null,
  or?: Array< ModelLessonMasterTicketsConditionInput | null > | null,
  not?: ModelLessonMasterTicketsConditionInput | null,
};

export type UpdateLessonMasterTicketsInput = {
  id: string,
  lessonId?: string | null,
  masterTicketId?: string | null,
};

export type DeleteLessonMasterTicketsInput = {
  id: string,
};

export type ModelShopFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  stripePubKey?: ModelStringInput | null,
  stripeSecKey?: ModelStringInput | null,
  mode?: ModelModeInput | null,
  dayoff?: ModelIntInput | null,
  and?: Array< ModelShopFilterInput | null > | null,
  or?: Array< ModelShopFilterInput | null > | null,
  not?: ModelShopFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelShopConnection = {
  __typename: "ModelShopConnection",
  items:  Array<Shop | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelScheduleFilterInput = {
  shopId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  lessonId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  expiredAt?: ModelStringInput | null,
  zoomMeetingId?: ModelStringInput | null,
  link?: ModelStringInput | null,
  pass?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  studio_capacity?: ModelIntInput | null,
  online_capacity?: ModelIntInput | null,
  acceptableStudioNumber?: ModelIntInput | null,
  acceptableOnlineNumber?: ModelIntInput | null,
  and?: Array< ModelScheduleFilterInput | null > | null,
  or?: Array< ModelScheduleFilterInput | null > | null,
  not?: ModelScheduleFilterInput | null,
};

export type ModelExpiredScheduleFilterInput = {
  shopId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  lessonId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  zoomMeetingId?: ModelStringInput | null,
  link?: ModelStringInput | null,
  pass?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  studio_capacity?: ModelIntInput | null,
  online_capacity?: ModelIntInput | null,
  acceptableStudioNumber?: ModelIntInput | null,
  acceptableOnlineNumber?: ModelIntInput | null,
  and?: Array< ModelExpiredScheduleFilterInput | null > | null,
  or?: Array< ModelExpiredScheduleFilterInput | null > | null,
  not?: ModelExpiredScheduleFilterInput | null,
};

export type ModelExpiredScheduleConnection = {
  __typename: "ModelExpiredScheduleConnection",
  items:  Array<ExpiredSchedule | null >,
  nextToken?: string | null,
};

export type ModelReservationFilterInput = {
  shopId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  scheduleId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  lessonStyle?: ModelLessonStyleInput | null,
  status?: ModelReservationStatusInput | null,
  transactionId?: ModelIDInput | null,
  isTrial?: ModelBooleanInput | null,
  consumedTicketId?: ModelIDInput | null,
  expiredAt?: ModelStringInput | null,
  and?: Array< ModelReservationFilterInput | null > | null,
  or?: Array< ModelReservationFilterInput | null > | null,
  not?: ModelReservationFilterInput | null,
};

export type ModelReservationTransactionFilterInput = {
  reservationId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelReservationTransactionFilterInput | null > | null,
  or?: Array< ModelReservationTransactionFilterInput | null > | null,
  not?: ModelReservationTransactionFilterInput | null,
};

export type ModelReservationTransactionConnection = {
  __typename: "ModelReservationTransactionConnection",
  items:  Array<ReservationTransaction | null >,
  nextToken?: string | null,
};

export type ModelExpiredReservationFilterInput = {
  shopId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  expiredScheduleId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  lessonStyle?: ModelLessonStyleInput | null,
  status?: ModelReservationStatusInput | null,
  transactionId?: ModelIDInput | null,
  isTrial?: ModelBooleanInput | null,
  consumedTicketId?: ModelIDInput | null,
  and?: Array< ModelExpiredReservationFilterInput | null > | null,
  or?: Array< ModelExpiredReservationFilterInput | null > | null,
  not?: ModelExpiredReservationFilterInput | null,
};

export type ModelCustomerFilterInput = {
  shopId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  stripeId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  lineId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelCustomerFilterInput | null > | null,
  or?: Array< ModelCustomerFilterInput | null > | null,
  not?: ModelCustomerFilterInput | null,
};

export type ModelMasterTicketFilterInput = {
  id?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  stripeProductId?: ModelIDInput | null,
  stripePriceId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  maxCount?: ModelIntInput | null,
  price?: ModelIntInput | null,
  type?: ModelTicketTypeInput | null,
  hasDailyLimit?: ModelBooleanInput | null,
  limitPerDaily?: ModelIntInput | null,
  hasOnlineDailyLimit?: ModelBooleanInput | null,
  onlineLimitPerDaily?: ModelIntInput | null,
  hasWeeklyLimit?: ModelBooleanInput | null,
  limitPerWeekly?: ModelIntInput | null,
  hasOnlineWeeklyLimit?: ModelBooleanInput | null,
  onlineLimitPerWeekly?: ModelIntInput | null,
  isPrivate?: ModelBooleanInput | null,
  privateScopedCustomerId?: ModelIDInput | null,
  isAvailable?: ModelBooleanInput | null,
  and?: Array< ModelMasterTicketFilterInput | null > | null,
  or?: Array< ModelMasterTicketFilterInput | null > | null,
  not?: ModelMasterTicketFilterInput | null,
};

export type ModelLessonFilterInput = {
  id?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  description?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  style?: ModelLessonStyleInput | null,
  canSingle?: ModelBooleanInput | null,
  singlePrice?: ModelIntInput | null,
  hasTrial?: ModelBooleanInput | null,
  trialPrice?: ModelIntInput | null,
  hasTicket?: ModelBooleanInput | null,
  canPublish?: ModelBooleanInput | null,
  studio_capacity?: ModelIntInput | null,
  online_capacity?: ModelIntInput | null,
  color?: ModelStringInput | null,
  order?: ModelIntInput | null,
  cancelTimeoutLimit?: ModelIntInput | null,
  reserveTimeoutLimit?: ModelIntInput | null,
  and?: Array< ModelLessonFilterInput | null > | null,
  or?: Array< ModelLessonFilterInput | null > | null,
  not?: ModelLessonFilterInput | null,
};

export type ModelLessonMasterTicketsFilterInput = {
  id?: ModelIDInput | null,
  lessonId?: ModelIDInput | null,
  masterTicketId?: ModelIDInput | null,
  and?: Array< ModelLessonMasterTicketsFilterInput | null > | null,
  or?: Array< ModelLessonMasterTicketsFilterInput | null > | null,
  not?: ModelLessonMasterTicketsFilterInput | null,
};

export type ModelSubscriptionShopFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  tel?: ModelSubscriptionStringInput | null,
  profile?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  stripePubKey?: ModelSubscriptionStringInput | null,
  stripeSecKey?: ModelSubscriptionStringInput | null,
  mode?: ModelSubscriptionStringInput | null,
  dayoff?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionShopFilterInput | null > | null,
  or?: Array< ModelSubscriptionShopFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionScheduleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  lessonId?: ModelSubscriptionIDInput | null,
  start?: ModelSubscriptionStringInput | null,
  end?: ModelSubscriptionStringInput | null,
  expiredAt?: ModelSubscriptionStringInput | null,
  zoomMeetingId?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  pass?: ModelSubscriptionStringInput | null,
  isPublished?: ModelSubscriptionBooleanInput | null,
  studio_capacity?: ModelSubscriptionIntInput | null,
  online_capacity?: ModelSubscriptionIntInput | null,
  acceptableStudioNumber?: ModelSubscriptionIntInput | null,
  acceptableOnlineNumber?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionScheduleFilterInput | null > | null,
  or?: Array< ModelSubscriptionScheduleFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionExpiredScheduleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  lessonId?: ModelSubscriptionIDInput | null,
  start?: ModelSubscriptionStringInput | null,
  end?: ModelSubscriptionStringInput | null,
  zoomMeetingId?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  pass?: ModelSubscriptionStringInput | null,
  isPublished?: ModelSubscriptionBooleanInput | null,
  studio_capacity?: ModelSubscriptionIntInput | null,
  online_capacity?: ModelSubscriptionIntInput | null,
  acceptableStudioNumber?: ModelSubscriptionIntInput | null,
  acceptableOnlineNumber?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionExpiredScheduleFilterInput | null > | null,
  or?: Array< ModelSubscriptionExpiredScheduleFilterInput | null > | null,
};

export type ModelSubscriptionReservationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  scheduleId?: ModelSubscriptionIDInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  lessonStyle?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  transactionId?: ModelSubscriptionIDInput | null,
  isTrial?: ModelSubscriptionBooleanInput | null,
  consumedTicketId?: ModelSubscriptionIDInput | null,
  expiredAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReservationFilterInput | null > | null,
  or?: Array< ModelSubscriptionReservationFilterInput | null > | null,
};

export type ModelSubscriptionReservationTransactionFilterInput = {
  reservationId?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionReservationTransactionFilterInput | null > | null,
  or?: Array< ModelSubscriptionReservationTransactionFilterInput | null > | null,
};

export type ModelSubscriptionExpiredReservationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  expiredScheduleId?: ModelSubscriptionIDInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  lessonStyle?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  transactionId?: ModelSubscriptionIDInput | null,
  isTrial?: ModelSubscriptionBooleanInput | null,
  consumedTicketId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionExpiredReservationFilterInput | null > | null,
  or?: Array< ModelSubscriptionExpiredReservationFilterInput | null > | null,
};

export type ModelSubscriptionCustomerFilterInput = {
  shopId?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  stripeId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  lineId?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
};

export type ModelSubscriptionMasterTicketFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  stripeProductId?: ModelSubscriptionIDInput | null,
  stripePriceId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  maxCount?: ModelSubscriptionIntInput | null,
  price?: ModelSubscriptionIntInput | null,
  type?: ModelSubscriptionStringInput | null,
  hasDailyLimit?: ModelSubscriptionBooleanInput | null,
  limitPerDaily?: ModelSubscriptionIntInput | null,
  hasOnlineDailyLimit?: ModelSubscriptionBooleanInput | null,
  onlineLimitPerDaily?: ModelSubscriptionIntInput | null,
  hasWeeklyLimit?: ModelSubscriptionBooleanInput | null,
  limitPerWeekly?: ModelSubscriptionIntInput | null,
  hasOnlineWeeklyLimit?: ModelSubscriptionBooleanInput | null,
  onlineLimitPerWeekly?: ModelSubscriptionIntInput | null,
  isPrivate?: ModelSubscriptionBooleanInput | null,
  privateScopedCustomerId?: ModelSubscriptionIDInput | null,
  isAvailable?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionMasterTicketFilterInput | null > | null,
  or?: Array< ModelSubscriptionMasterTicketFilterInput | null > | null,
};

export type ModelSubscriptionLessonFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  thumbnail?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionIntInput | null,
  style?: ModelSubscriptionStringInput | null,
  canSingle?: ModelSubscriptionBooleanInput | null,
  singlePrice?: ModelSubscriptionIntInput | null,
  hasTrial?: ModelSubscriptionBooleanInput | null,
  trialPrice?: ModelSubscriptionIntInput | null,
  hasTicket?: ModelSubscriptionBooleanInput | null,
  canPublish?: ModelSubscriptionBooleanInput | null,
  studio_capacity?: ModelSubscriptionIntInput | null,
  online_capacity?: ModelSubscriptionIntInput | null,
  color?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionIntInput | null,
  cancelTimeoutLimit?: ModelSubscriptionIntInput | null,
  reserveTimeoutLimit?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionLessonFilterInput | null > | null,
  or?: Array< ModelSubscriptionLessonFilterInput | null > | null,
};

export type ModelSubscriptionLessonMasterTicketsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  lessonId?: ModelSubscriptionIDInput | null,
  masterTicketId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLessonMasterTicketsFilterInput | null > | null,
  or?: Array< ModelSubscriptionLessonMasterTicketsFilterInput | null > | null,
};

export type BatchAddLessonMasterTicketsMutationVariables = {
  lessonMasterTicketInputs?: Array< LessonMasterTicketInput | null > | null,
};

export type BatchAddLessonMasterTicketsMutation = {
  batchAddLessonMasterTickets?:  Array< {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null > | null,
};

export type BatchDeleteLessonMasterTicketsMutationVariables = {
  ids?: Array< string | null > | null,
};

export type BatchDeleteLessonMasterTicketsMutation = {
  batchDeleteLessonMasterTickets?:  Array< {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null > | null,
};

export type BatchAddSchedulesMutationVariables = {
  batchAddScheduleInput?: BatchAddScheduleInput | null,
};

export type BatchAddSchedulesMutation = {
  batchAddSchedules?:  {
    __typename: "BatchAddSchedulesResult",
    shopId: string,
    data?:  Array< {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
  } | null,
};

export type CreatePreReservationMutationVariables = {
  preReservationInput?: CreatePreReservationInput | null,
  reserveUsingTicketInput?: ReserveUsingTicketInput | null,
};

export type CreatePreReservationMutation = {
  createPreReservation?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTicketWithStripeMutationVariables = {
  ticketInput?: CreateTicketWithStripeInput | null,
};

export type CreateTicketWithStripeMutation = {
  createTicketWithStripe?:  {
    __typename: "CreateTicketOnStripeResult",
    stripeProductId?: string | null,
    stripePriceId?: string | null,
  } | null,
};

export type CreateCustomerAccountMutationVariables = {
  createCustomerAccountInput?: CreateCustomerAccountInput | null,
};

export type CreateCustomerAccountMutation = {
  createCustomerAccount?:  {
    __typename: "CustomerAccount",
    serviceCustomerInfo?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerInfo?: string | null,
    stripePaymentMethodInfo?: string | null,
  } | null,
};

export type CreateSetupIntentMutationVariables = {
  createSetupIntentInput?: CreateSetupIntentInput | null,
};

export type CreateSetupIntentMutation = {
  createSetupIntent?:  {
    __typename: "CreateSetupIntentResult",
    clientSecret: string,
  } | null,
};

export type CreateTicketPaymentIntentMutationVariables = {
  createTicketPaymentIntentInput?: CreateTicketPaymentIntentInput | null,
};

export type CreateTicketPaymentIntentMutation = {
  createTicketPaymentIntent?:  {
    __typename: "CreateTicketPaymentIntentResult",
    isError: boolean,
    errMessage?: string | null,
    paymentIntentData?: string | null,
    ticket?:  {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type CreateSinglePaymentIntentMutationVariables = {
  createSinglePaymentIntentInput?: CreateSinglePaymentIntentInput | null,
};

export type CreateSinglePaymentIntentMutation = {
  createSinglePaymentIntent?:  {
    __typename: "CreateTicketPaymentIntentResult",
    isError: boolean,
    errMessage?: string | null,
    paymentIntentData?: string | null,
    ticket?:  {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type CreateSubscriptionMutationVariables = {
  createSubscriptionInput?: CreateSubscriptionInput | null,
};

export type CreateSubscriptionMutation = {
  createSubscription?:  {
    __typename: "CreateSubscriptionResult",
    isError: boolean,
    errMessage?: string | null,
    paymentIntentData?: string | null,
    ticket?:  {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type ConsumePurchasedTicketMutationVariables = {
  consumePurchasedTicketInput?: ConsumePurchasedTicketInput | null,
};

export type ConsumePurchasedTicketMutation = {
  consumePurchasedTicket?:  {
    __typename: "UpdatePurchasedTicketCountResult",
    shopId: string,
    customerId: string,
    purchasedTicketId: string,
  } | null,
};

export type RecoverPurchasedTicketMutationVariables = {
  recoverPurchasedTicketInput?: RecoverPurchasedTicketInput | null,
};

export type RecoverPurchasedTicketMutation = {
  recoverPurchasedTicket?:  {
    __typename: "UpdatePurchasedTicketCountResult",
    shopId: string,
    customerId: string,
    purchasedTicketId: string,
  } | null,
};

export type SetZoomAuthTokenMutationVariables = {
  setZoomAuthTokenInput?: SetZoomAuthTokenInput | null,
};

export type SetZoomAuthTokenMutation = {
  setZoomAuthToken?:  {
    __typename: "SetZoomAuthTokenResult",
    accountEmail?: string | null,
    accessToken?: string | null,
    tokenType?: string | null,
    refreshToken?: string | null,
    expiresIn?: number | null,
    scope?: string | null,
    updatedAt?: string | null,
    dailyCreateMeetingThrottling?: number | null,
  } | null,
};

export type CreateZoomMeetingsMutationVariables = {
  createZoomMeetingInput?: CreateZoomMeetingInput | null,
};

export type CreateZoomMeetingsMutation = {
  createZoomMeetings?:  {
    __typename: "CreateZoomMeetingsResult",
    zoomInfo:  Array< {
      __typename: "ZoomMeetingInfo",
      scheduleId?: string | null,
      zoomMeetingId: string,
      start_url: string,
      join_url: string,
      password: string,
    } >,
  } | null,
};

export type CreateShopMutationVariables = {
  input: CreateShopInput,
  condition?: ModelShopConditionInput | null,
};

export type CreateShopMutation = {
  createShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    profile?: string | null,
    icon?: string | null,
    stripePubKey?: string | null,
    stripeSecKey?: string | null,
    mode?: Mode | null,
    stripe?:  {
      __typename: "Stripe",
      dev?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
      prod?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      accountEmail?: string | null,
      accessToken?: string | null,
      tokenType?: string | null,
      refreshToken?: string | null,
      expiresIn?: number | null,
      scope?: string | null,
      updatedAt?: string | null,
      dailyCreateMeetingThrottling?: number | null,
    } | null,
    dayoff?: Array< number | null > | null,
    staffs?:  Array< {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    } | null > | null,
    schedules?:  {
      __typename: "ModelScheduleConnection",
      items:  Array< {
        __typename: "Schedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        expiredAt?: string | null,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelMasterTicketConnection",
      items:  Array< {
        __typename: "MasterTicket",
        id: string,
        shopId: string,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        isPrivate?: boolean | null,
        privateScopedCustomerId?: Array< string | null > | null,
        isAvailable: boolean,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lessons?:  {
      __typename: "ModelLessonConnection",
      items:  Array< {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateShopMutationVariables = {
  input: UpdateShopInput,
  condition?: ModelShopConditionInput | null,
};

export type UpdateShopMutation = {
  updateShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    profile?: string | null,
    icon?: string | null,
    stripePubKey?: string | null,
    stripeSecKey?: string | null,
    mode?: Mode | null,
    stripe?:  {
      __typename: "Stripe",
      dev?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
      prod?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      accountEmail?: string | null,
      accessToken?: string | null,
      tokenType?: string | null,
      refreshToken?: string | null,
      expiresIn?: number | null,
      scope?: string | null,
      updatedAt?: string | null,
      dailyCreateMeetingThrottling?: number | null,
    } | null,
    dayoff?: Array< number | null > | null,
    staffs?:  Array< {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    } | null > | null,
    schedules?:  {
      __typename: "ModelScheduleConnection",
      items:  Array< {
        __typename: "Schedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        expiredAt?: string | null,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelMasterTicketConnection",
      items:  Array< {
        __typename: "MasterTicket",
        id: string,
        shopId: string,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        isPrivate?: boolean | null,
        privateScopedCustomerId?: Array< string | null > | null,
        isAvailable: boolean,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lessons?:  {
      __typename: "ModelLessonConnection",
      items:  Array< {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteShopMutationVariables = {
  input: DeleteShopInput,
  condition?: ModelShopConditionInput | null,
};

export type DeleteShopMutation = {
  deleteShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    profile?: string | null,
    icon?: string | null,
    stripePubKey?: string | null,
    stripeSecKey?: string | null,
    mode?: Mode | null,
    stripe?:  {
      __typename: "Stripe",
      dev?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
      prod?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      accountEmail?: string | null,
      accessToken?: string | null,
      tokenType?: string | null,
      refreshToken?: string | null,
      expiresIn?: number | null,
      scope?: string | null,
      updatedAt?: string | null,
      dailyCreateMeetingThrottling?: number | null,
    } | null,
    dayoff?: Array< number | null > | null,
    staffs?:  Array< {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    } | null > | null,
    schedules?:  {
      __typename: "ModelScheduleConnection",
      items:  Array< {
        __typename: "Schedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        expiredAt?: string | null,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelMasterTicketConnection",
      items:  Array< {
        __typename: "MasterTicket",
        id: string,
        shopId: string,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        isPrivate?: boolean | null,
        privateScopedCustomerId?: Array< string | null > | null,
        isAvailable: boolean,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lessons?:  {
      __typename: "ModelLessonConnection",
      items:  Array< {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateScheduleMutationVariables = {
  input: CreateScheduleInput,
  condition?: ModelScheduleConditionInput | null,
};

export type CreateScheduleMutation = {
  createSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    expiredAt?: string | null,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateScheduleMutationVariables = {
  input: UpdateScheduleInput,
  condition?: ModelScheduleConditionInput | null,
};

export type UpdateScheduleMutation = {
  updateSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    expiredAt?: string | null,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteScheduleMutationVariables = {
  input: DeleteScheduleInput,
  condition?: ModelScheduleConditionInput | null,
};

export type DeleteScheduleMutation = {
  deleteSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    expiredAt?: string | null,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateExpiredScheduleMutationVariables = {
  input: CreateExpiredScheduleInput,
  condition?: ModelExpiredScheduleConditionInput | null,
};

export type CreateExpiredScheduleMutation = {
  createExpiredSchedule?:  {
    __typename: "ExpiredSchedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateExpiredScheduleMutationVariables = {
  input: UpdateExpiredScheduleInput,
  condition?: ModelExpiredScheduleConditionInput | null,
};

export type UpdateExpiredScheduleMutation = {
  updateExpiredSchedule?:  {
    __typename: "ExpiredSchedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteExpiredScheduleMutationVariables = {
  input: DeleteExpiredScheduleInput,
  condition?: ModelExpiredScheduleConditionInput | null,
};

export type DeleteExpiredScheduleMutation = {
  deleteExpiredSchedule?:  {
    __typename: "ExpiredSchedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReservationMutationVariables = {
  input: CreateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type CreateReservationMutation = {
  createReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    expiredAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReservationMutationVariables = {
  input: UpdateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type UpdateReservationMutation = {
  updateReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    expiredAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReservationMutationVariables = {
  input: DeleteReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type DeleteReservationMutation = {
  deleteReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    expiredAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReservationTransactionMutationVariables = {
  input: CreateReservationTransactionInput,
  condition?: ModelReservationTransactionConditionInput | null,
};

export type CreateReservationTransactionMutation = {
  createReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReservationTransactionMutationVariables = {
  input: UpdateReservationTransactionInput,
  condition?: ModelReservationTransactionConditionInput | null,
};

export type UpdateReservationTransactionMutation = {
  updateReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReservationTransactionMutationVariables = {
  input: DeleteReservationTransactionInput,
  condition?: ModelReservationTransactionConditionInput | null,
};

export type DeleteReservationTransactionMutation = {
  deleteReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateExpiredReservationMutationVariables = {
  input: CreateExpiredReservationInput,
  condition?: ModelExpiredReservationConditionInput | null,
};

export type CreateExpiredReservationMutation = {
  createExpiredReservation?:  {
    __typename: "ExpiredReservation",
    shopId: string,
    id: string,
    expiredScheduleId: string,
    expiredSchedule:  {
      __typename: "ExpiredSchedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateExpiredReservationMutationVariables = {
  input: UpdateExpiredReservationInput,
  condition?: ModelExpiredReservationConditionInput | null,
};

export type UpdateExpiredReservationMutation = {
  updateExpiredReservation?:  {
    __typename: "ExpiredReservation",
    shopId: string,
    id: string,
    expiredScheduleId: string,
    expiredSchedule:  {
      __typename: "ExpiredSchedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteExpiredReservationMutationVariables = {
  input: DeleteExpiredReservationInput,
  condition?: ModelExpiredReservationConditionInput | null,
};

export type DeleteExpiredReservationMutation = {
  deleteExpiredReservation?:  {
    __typename: "ExpiredReservation",
    shopId: string,
    id: string,
    expiredScheduleId: string,
    expiredSchedule:  {
      __typename: "ExpiredSchedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    stripeId?: string | null,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    purchasedTickets?:  Array< {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null > | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    stripeId?: string | null,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    purchasedTickets?:  Array< {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null > | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    stripeId?: string | null,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    purchasedTickets?:  Array< {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null > | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMasterTicketMutationVariables = {
  input: CreateMasterTicketInput,
  condition?: ModelMasterTicketConditionInput | null,
};

export type CreateMasterTicketMutation = {
  createMasterTicket?:  {
    __typename: "MasterTicket",
    id: string,
    shopId: string,
    stripeProductId?: string | null,
    stripePriceId?: string | null,
    name: string,
    description?: string | null,
    maxCount: number,
    price: number,
    type: TicketType,
    deadline?:  {
      __typename: "Deadline",
      type: DeadlineType,
      num: number,
    } | null,
    hasDailyLimit?: boolean | null,
    limitPerDaily?: number | null,
    hasOnlineDailyLimit?: boolean | null,
    onlineLimitPerDaily?: number | null,
    hasWeeklyLimit?: boolean | null,
    limitPerWeekly?: number | null,
    hasOnlineWeeklyLimit?: boolean | null,
    onlineLimitPerWeekly?: number | null,
    isPrivate?: boolean | null,
    privateScopedCustomerId?: Array< string | null > | null,
    lessons?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    isAvailable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMasterTicketMutationVariables = {
  input: UpdateMasterTicketInput,
  condition?: ModelMasterTicketConditionInput | null,
};

export type UpdateMasterTicketMutation = {
  updateMasterTicket?:  {
    __typename: "MasterTicket",
    id: string,
    shopId: string,
    stripeProductId?: string | null,
    stripePriceId?: string | null,
    name: string,
    description?: string | null,
    maxCount: number,
    price: number,
    type: TicketType,
    deadline?:  {
      __typename: "Deadline",
      type: DeadlineType,
      num: number,
    } | null,
    hasDailyLimit?: boolean | null,
    limitPerDaily?: number | null,
    hasOnlineDailyLimit?: boolean | null,
    onlineLimitPerDaily?: number | null,
    hasWeeklyLimit?: boolean | null,
    limitPerWeekly?: number | null,
    hasOnlineWeeklyLimit?: boolean | null,
    onlineLimitPerWeekly?: number | null,
    isPrivate?: boolean | null,
    privateScopedCustomerId?: Array< string | null > | null,
    lessons?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    isAvailable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMasterTicketMutationVariables = {
  input: DeleteMasterTicketInput,
  condition?: ModelMasterTicketConditionInput | null,
};

export type DeleteMasterTicketMutation = {
  deleteMasterTicket?:  {
    __typename: "MasterTicket",
    id: string,
    shopId: string,
    stripeProductId?: string | null,
    stripePriceId?: string | null,
    name: string,
    description?: string | null,
    maxCount: number,
    price: number,
    type: TicketType,
    deadline?:  {
      __typename: "Deadline",
      type: DeadlineType,
      num: number,
    } | null,
    hasDailyLimit?: boolean | null,
    limitPerDaily?: number | null,
    hasOnlineDailyLimit?: boolean | null,
    onlineLimitPerDaily?: number | null,
    hasWeeklyLimit?: boolean | null,
    limitPerWeekly?: number | null,
    hasOnlineWeeklyLimit?: boolean | null,
    onlineLimitPerWeekly?: number | null,
    isPrivate?: boolean | null,
    privateScopedCustomerId?: Array< string | null > | null,
    lessons?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    isAvailable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLessonMutationVariables = {
  input: CreateLessonInput,
  condition?: ModelLessonConditionInput | null,
};

export type CreateLessonMutation = {
  createLesson?:  {
    __typename: "Lesson",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    style: LessonStyle,
    canSingle: boolean,
    singlePrice?: number | null,
    hasTrial: boolean,
    trialPrice?: number | null,
    hasTicket: boolean,
    masterTickets?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    canPublish: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    requiredTime?:  {
      __typename: "LessonRequiredTime",
      timeUnit: TimeUnit,
      value: number,
    } | null,
    staff:  {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    },
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLessonMutationVariables = {
  input: UpdateLessonInput,
  condition?: ModelLessonConditionInput | null,
};

export type UpdateLessonMutation = {
  updateLesson?:  {
    __typename: "Lesson",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    style: LessonStyle,
    canSingle: boolean,
    singlePrice?: number | null,
    hasTrial: boolean,
    trialPrice?: number | null,
    hasTicket: boolean,
    masterTickets?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    canPublish: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    requiredTime?:  {
      __typename: "LessonRequiredTime",
      timeUnit: TimeUnit,
      value: number,
    } | null,
    staff:  {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    },
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLessonMutationVariables = {
  input: DeleteLessonInput,
  condition?: ModelLessonConditionInput | null,
};

export type DeleteLessonMutation = {
  deleteLesson?:  {
    __typename: "Lesson",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    style: LessonStyle,
    canSingle: boolean,
    singlePrice?: number | null,
    hasTrial: boolean,
    trialPrice?: number | null,
    hasTicket: boolean,
    masterTickets?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    canPublish: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    requiredTime?:  {
      __typename: "LessonRequiredTime",
      timeUnit: TimeUnit,
      value: number,
    } | null,
    staff:  {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    },
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLessonMasterTicketsMutationVariables = {
  input: CreateLessonMasterTicketsInput,
  condition?: ModelLessonMasterTicketsConditionInput | null,
};

export type CreateLessonMasterTicketsMutation = {
  createLessonMasterTickets?:  {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateLessonMasterTicketsMutationVariables = {
  input: UpdateLessonMasterTicketsInput,
  condition?: ModelLessonMasterTicketsConditionInput | null,
};

export type UpdateLessonMasterTicketsMutation = {
  updateLessonMasterTickets?:  {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteLessonMasterTicketsMutationVariables = {
  input: DeleteLessonMasterTicketsInput,
  condition?: ModelLessonMasterTicketsConditionInput | null,
};

export type DeleteLessonMasterTicketsMutation = {
  deleteLessonMasterTickets?:  {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type GetCustomerAccountQueryVariables = {
  shopId?: string | null,
  lineId?: string | null,
};

export type GetCustomerAccountQuery = {
  getCustomerAccount?:  {
    __typename: "CustomerAccount",
    serviceCustomerInfo?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerInfo?: string | null,
    stripePaymentMethodInfo?: string | null,
  } | null,
};

export type GetShopQueryVariables = {
  id: string,
};

export type GetShopQuery = {
  getShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    profile?: string | null,
    icon?: string | null,
    stripePubKey?: string | null,
    stripeSecKey?: string | null,
    mode?: Mode | null,
    stripe?:  {
      __typename: "Stripe",
      dev?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
      prod?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      accountEmail?: string | null,
      accessToken?: string | null,
      tokenType?: string | null,
      refreshToken?: string | null,
      expiresIn?: number | null,
      scope?: string | null,
      updatedAt?: string | null,
      dailyCreateMeetingThrottling?: number | null,
    } | null,
    dayoff?: Array< number | null > | null,
    staffs?:  Array< {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    } | null > | null,
    schedules?:  {
      __typename: "ModelScheduleConnection",
      items:  Array< {
        __typename: "Schedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        expiredAt?: string | null,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelMasterTicketConnection",
      items:  Array< {
        __typename: "MasterTicket",
        id: string,
        shopId: string,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        isPrivate?: boolean | null,
        privateScopedCustomerId?: Array< string | null > | null,
        isAvailable: boolean,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lessons?:  {
      __typename: "ModelLessonConnection",
      items:  Array< {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListShopsQueryVariables = {
  id?: string | null,
  filter?: ModelShopFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListShopsQuery = {
  listShops?:  {
    __typename: "ModelShopConnection",
    items:  Array< {
      __typename: "Shop",
      id: string,
      name: string,
      address?: string | null,
      email?: string | null,
      tel?: string | null,
      profile?: string | null,
      icon?: string | null,
      stripePubKey?: string | null,
      stripeSecKey?: string | null,
      mode?: Mode | null,
      zoom?:  {
        __typename: "Zoom",
        accountEmail?: string | null,
        accessToken?: string | null,
        tokenType?: string | null,
        refreshToken?: string | null,
        expiresIn?: number | null,
        scope?: string | null,
        updatedAt?: string | null,
        dailyCreateMeetingThrottling?: number | null,
      } | null,
      dayoff?: Array< number | null > | null,
      staffs?:  Array< {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      } | null > | null,
      schedules?:  {
        __typename: "ModelScheduleConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelMasterTicketConnection",
        nextToken?: string | null,
      } | null,
      lessons?:  {
        __typename: "ModelLessonConnection",
        nextToken?: string | null,
      } | null,
      customers?:  {
        __typename: "ModelCustomerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetScheduleQueryVariables = {
  shopId: string,
  id: string,
};

export type GetScheduleQuery = {
  getSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    expiredAt?: string | null,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSchedulesQueryVariables = {
  shopId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSchedulesQuery = {
  listSchedules?:  {
    __typename: "ModelScheduleConnection",
    items:  Array< {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExpiredScheduleQueryVariables = {
  shopId: string,
  id: string,
};

export type GetExpiredScheduleQuery = {
  getExpiredSchedule?:  {
    __typename: "ExpiredSchedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListExpiredSchedulesQueryVariables = {
  shopId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelExpiredScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListExpiredSchedulesQuery = {
  listExpiredSchedules?:  {
    __typename: "ModelExpiredScheduleConnection",
    items:  Array< {
      __typename: "ExpiredSchedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReservationQueryVariables = {
  shopId: string,
  id: string,
};

export type GetReservationQuery = {
  getReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    expiredAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReservationsQueryVariables = {
  shopId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReservationsQuery = {
  listReservations?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      shopId: string,
      id: string,
      scheduleId: string,
      schedule?:  {
        __typename: "Schedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        expiredAt?: string | null,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      customerId: string,
      customer:  {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      lessonStyle?: LessonStyle | null,
      status?: ReservationStatus | null,
      transactionId: string,
      transaction?:  {
        __typename: "ReservationTransaction",
        reservationId: string,
        id: string,
        customerId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      isTrial?: boolean | null,
      consumedTicketId?: string | null,
      expiredAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReservationsByExpiredAtQueryVariables = {
  expiredAt: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReservationsByExpiredAtQuery = {
  reservationsByExpiredAt?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      shopId: string,
      id: string,
      scheduleId: string,
      schedule?:  {
        __typename: "Schedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        expiredAt?: string | null,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      customerId: string,
      customer:  {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      lessonStyle?: LessonStyle | null,
      status?: ReservationStatus | null,
      transactionId: string,
      transaction?:  {
        __typename: "ReservationTransaction",
        reservationId: string,
        id: string,
        customerId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      isTrial?: boolean | null,
      consumedTicketId?: string | null,
      expiredAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReservationTransactionQueryVariables = {
  reservationId: string,
  id: string,
};

export type GetReservationTransactionQuery = {
  getReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReservationTransactionsQueryVariables = {
  reservationId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelReservationTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReservationTransactionsQuery = {
  listReservationTransactions?:  {
    __typename: "ModelReservationTransactionConnection",
    items:  Array< {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExpiredReservationQueryVariables = {
  shopId: string,
  id: string,
};

export type GetExpiredReservationQuery = {
  getExpiredReservation?:  {
    __typename: "ExpiredReservation",
    shopId: string,
    id: string,
    expiredScheduleId: string,
    expiredSchedule:  {
      __typename: "ExpiredSchedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListExpiredReservationsQueryVariables = {
  shopId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelExpiredReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListExpiredReservationsQuery = {
  listExpiredReservations?:  {
    __typename: "ModelExpiredReservationConnection",
    items:  Array< {
      __typename: "ExpiredReservation",
      shopId: string,
      id: string,
      expiredScheduleId: string,
      expiredSchedule:  {
        __typename: "ExpiredSchedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      customerId: string,
      customer:  {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      lessonStyle?: LessonStyle | null,
      status?: ReservationStatus | null,
      transactionId: string,
      transaction?:  {
        __typename: "ReservationTransaction",
        reservationId: string,
        id: string,
        customerId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      isTrial?: boolean | null,
      consumedTicketId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  shopId: string,
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    stripeId?: string | null,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    purchasedTickets?:  Array< {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null > | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomersQueryVariables = {
  shopId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomerByLineIdQueryVariables = {
  lineId: string,
  shopId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByLineIdQuery = {
  customerByLineId?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMasterTicketQueryVariables = {
  id: string,
};

export type GetMasterTicketQuery = {
  getMasterTicket?:  {
    __typename: "MasterTicket",
    id: string,
    shopId: string,
    stripeProductId?: string | null,
    stripePriceId?: string | null,
    name: string,
    description?: string | null,
    maxCount: number,
    price: number,
    type: TicketType,
    deadline?:  {
      __typename: "Deadline",
      type: DeadlineType,
      num: number,
    } | null,
    hasDailyLimit?: boolean | null,
    limitPerDaily?: number | null,
    hasOnlineDailyLimit?: boolean | null,
    onlineLimitPerDaily?: number | null,
    hasWeeklyLimit?: boolean | null,
    limitPerWeekly?: number | null,
    hasOnlineWeeklyLimit?: boolean | null,
    onlineLimitPerWeekly?: number | null,
    isPrivate?: boolean | null,
    privateScopedCustomerId?: Array< string | null > | null,
    lessons?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    isAvailable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMasterTicketsQueryVariables = {
  id?: string | null,
  filter?: ModelMasterTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMasterTicketsQuery = {
  listMasterTickets?:  {
    __typename: "ModelMasterTicketConnection",
    items:  Array< {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLessonQueryVariables = {
  id: string,
};

export type GetLessonQuery = {
  getLesson?:  {
    __typename: "Lesson",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    style: LessonStyle,
    canSingle: boolean,
    singlePrice?: number | null,
    hasTrial: boolean,
    trialPrice?: number | null,
    hasTicket: boolean,
    masterTickets?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    canPublish: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    requiredTime?:  {
      __typename: "LessonRequiredTime",
      timeUnit: TimeUnit,
      value: number,
    } | null,
    staff:  {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    },
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLessonsQueryVariables = {
  id?: string | null,
  filter?: ModelLessonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLessonsQuery = {
  listLessons?:  {
    __typename: "ModelLessonConnection",
    items:  Array< {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLessonMasterTicketsQueryVariables = {
  id: string,
};

export type GetLessonMasterTicketsQuery = {
  getLessonMasterTickets?:  {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListLessonMasterTicketsQueryVariables = {
  filter?: ModelLessonMasterTicketsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLessonMasterTicketsQuery = {
  listLessonMasterTickets?:  {
    __typename: "ModelLessonMasterTicketsConnection",
    items:  Array< {
      __typename: "LessonMasterTickets",
      id: string,
      lessonId: string,
      lesson?:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      masterTicketId: string,
      masterTicket?:  {
        __typename: "MasterTicket",
        id: string,
        shopId: string,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        isPrivate?: boolean | null,
        privateScopedCustomerId?: Array< string | null > | null,
        isAvailable: boolean,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnBatchAddLessonMasterTicketsSubscription = {
  onBatchAddLessonMasterTickets?:  Array< {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null > | null,
};

export type OnBatchRemoveLessonMasterTicketsSubscription = {
  onBatchRemoveLessonMasterTickets?:  Array< {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null > | null,
};

export type OnBatchAddSchedulesSubscriptionVariables = {
  shopId: string,
};

export type OnBatchAddSchedulesSubscription = {
  onBatchAddSchedules?:  {
    __typename: "BatchAddSchedulesResult",
    shopId: string,
    data?:  Array< {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
  } | null,
};

export type OnCreateCustomerAccountSubscriptionVariables = {
  id: string,
};

export type OnCreateCustomerAccountSubscription = {
  onCreateCustomerAccount?:  {
    __typename: "CustomerAccount",
    serviceCustomerInfo?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerInfo?: string | null,
    stripePaymentMethodInfo?: string | null,
  } | null,
};

export type OnUpdateReservationByCustomerIdSubscriptionVariables = {
  customerId: string,
};

export type OnUpdateReservationByCustomerIdSubscription = {
  onUpdateReservationByCustomerId?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    expiredAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateShopSubscriptionVariables = {
  filter?: ModelSubscriptionShopFilterInput | null,
  owner?: string | null,
};

export type OnCreateShopSubscription = {
  onCreateShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    profile?: string | null,
    icon?: string | null,
    stripePubKey?: string | null,
    stripeSecKey?: string | null,
    mode?: Mode | null,
    stripe?:  {
      __typename: "Stripe",
      dev?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
      prod?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      accountEmail?: string | null,
      accessToken?: string | null,
      tokenType?: string | null,
      refreshToken?: string | null,
      expiresIn?: number | null,
      scope?: string | null,
      updatedAt?: string | null,
      dailyCreateMeetingThrottling?: number | null,
    } | null,
    dayoff?: Array< number | null > | null,
    staffs?:  Array< {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    } | null > | null,
    schedules?:  {
      __typename: "ModelScheduleConnection",
      items:  Array< {
        __typename: "Schedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        expiredAt?: string | null,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelMasterTicketConnection",
      items:  Array< {
        __typename: "MasterTicket",
        id: string,
        shopId: string,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        isPrivate?: boolean | null,
        privateScopedCustomerId?: Array< string | null > | null,
        isAvailable: boolean,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lessons?:  {
      __typename: "ModelLessonConnection",
      items:  Array< {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateShopSubscriptionVariables = {
  filter?: ModelSubscriptionShopFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShopSubscription = {
  onUpdateShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    profile?: string | null,
    icon?: string | null,
    stripePubKey?: string | null,
    stripeSecKey?: string | null,
    mode?: Mode | null,
    stripe?:  {
      __typename: "Stripe",
      dev?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
      prod?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      accountEmail?: string | null,
      accessToken?: string | null,
      tokenType?: string | null,
      refreshToken?: string | null,
      expiresIn?: number | null,
      scope?: string | null,
      updatedAt?: string | null,
      dailyCreateMeetingThrottling?: number | null,
    } | null,
    dayoff?: Array< number | null > | null,
    staffs?:  Array< {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    } | null > | null,
    schedules?:  {
      __typename: "ModelScheduleConnection",
      items:  Array< {
        __typename: "Schedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        expiredAt?: string | null,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelMasterTicketConnection",
      items:  Array< {
        __typename: "MasterTicket",
        id: string,
        shopId: string,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        isPrivate?: boolean | null,
        privateScopedCustomerId?: Array< string | null > | null,
        isAvailable: boolean,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lessons?:  {
      __typename: "ModelLessonConnection",
      items:  Array< {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteShopSubscriptionVariables = {
  filter?: ModelSubscriptionShopFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShopSubscription = {
  onDeleteShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    profile?: string | null,
    icon?: string | null,
    stripePubKey?: string | null,
    stripeSecKey?: string | null,
    mode?: Mode | null,
    stripe?:  {
      __typename: "Stripe",
      dev?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
      prod?:  {
        __typename: "StripeKey",
        pubKey?: string | null,
        secKey?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      accountEmail?: string | null,
      accessToken?: string | null,
      tokenType?: string | null,
      refreshToken?: string | null,
      expiresIn?: number | null,
      scope?: string | null,
      updatedAt?: string | null,
      dailyCreateMeetingThrottling?: number | null,
    } | null,
    dayoff?: Array< number | null > | null,
    staffs?:  Array< {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    } | null > | null,
    schedules?:  {
      __typename: "ModelScheduleConnection",
      items:  Array< {
        __typename: "Schedule",
        shopId: string,
        id: string,
        lessonId: string,
        start: string,
        end: string,
        expiredAt?: string | null,
        zoomMeetingId?: string | null,
        link?: string | null,
        pass?: string | null,
        isPublished: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        acceptableStudioNumber?: number | null,
        acceptableOnlineNumber?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelMasterTicketConnection",
      items:  Array< {
        __typename: "MasterTicket",
        id: string,
        shopId: string,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        isPrivate?: boolean | null,
        privateScopedCustomerId?: Array< string | null > | null,
        isAvailable: boolean,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lessons?:  {
      __typename: "ModelLessonConnection",
      items:  Array< {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        shopId: string,
        id: string,
        stripeId?: string | null,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateScheduleSubscriptionVariables = {
  filter?: ModelSubscriptionScheduleFilterInput | null,
  shopId?: string | null,
};

export type OnCreateScheduleSubscription = {
  onCreateSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    expiredAt?: string | null,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateScheduleSubscriptionVariables = {
  filter?: ModelSubscriptionScheduleFilterInput | null,
  shopId?: string | null,
};

export type OnUpdateScheduleSubscription = {
  onUpdateSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    expiredAt?: string | null,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteScheduleSubscriptionVariables = {
  filter?: ModelSubscriptionScheduleFilterInput | null,
  shopId?: string | null,
};

export type OnDeleteScheduleSubscription = {
  onDeleteSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    expiredAt?: string | null,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateExpiredScheduleSubscriptionVariables = {
  filter?: ModelSubscriptionExpiredScheduleFilterInput | null,
  shopId?: string | null,
};

export type OnCreateExpiredScheduleSubscription = {
  onCreateExpiredSchedule?:  {
    __typename: "ExpiredSchedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateExpiredScheduleSubscriptionVariables = {
  filter?: ModelSubscriptionExpiredScheduleFilterInput | null,
  shopId?: string | null,
};

export type OnUpdateExpiredScheduleSubscription = {
  onUpdateExpiredSchedule?:  {
    __typename: "ExpiredSchedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteExpiredScheduleSubscriptionVariables = {
  filter?: ModelSubscriptionExpiredScheduleFilterInput | null,
  shopId?: string | null,
};

export type OnDeleteExpiredScheduleSubscription = {
  onDeleteExpiredSchedule?:  {
    __typename: "ExpiredSchedule",
    shopId: string,
    id: string,
    lessonId: string,
    lesson:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    start: string,
    end: string,
    zoomMeetingId?: string | null,
    link?: string | null,
    pass?: string | null,
    isPublished: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    acceptableStudioNumber?: number | null,
    acceptableOnlineNumber?: number | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReservationSubscriptionVariables = {
  filter?: ModelSubscriptionReservationFilterInput | null,
  shopId?: string | null,
};

export type OnCreateReservationSubscription = {
  onCreateReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    expiredAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReservationSubscriptionVariables = {
  filter?: ModelSubscriptionReservationFilterInput | null,
  shopId?: string | null,
};

export type OnUpdateReservationSubscription = {
  onUpdateReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    expiredAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReservationSubscriptionVariables = {
  filter?: ModelSubscriptionReservationFilterInput | null,
  shopId?: string | null,
};

export type OnDeleteReservationSubscription = {
  onDeleteReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      expiredAt?: string | null,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    expiredAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReservationTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionReservationTransactionFilterInput | null,
};

export type OnCreateReservationTransactionSubscription = {
  onCreateReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReservationTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionReservationTransactionFilterInput | null,
};

export type OnUpdateReservationTransactionSubscription = {
  onUpdateReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReservationTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionReservationTransactionFilterInput | null,
};

export type OnDeleteReservationTransactionSubscription = {
  onDeleteReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateExpiredReservationSubscriptionVariables = {
  filter?: ModelSubscriptionExpiredReservationFilterInput | null,
  shopId?: string | null,
};

export type OnCreateExpiredReservationSubscription = {
  onCreateExpiredReservation?:  {
    __typename: "ExpiredReservation",
    shopId: string,
    id: string,
    expiredScheduleId: string,
    expiredSchedule:  {
      __typename: "ExpiredSchedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateExpiredReservationSubscriptionVariables = {
  filter?: ModelSubscriptionExpiredReservationFilterInput | null,
  shopId?: string | null,
};

export type OnUpdateExpiredReservationSubscription = {
  onUpdateExpiredReservation?:  {
    __typename: "ExpiredReservation",
    shopId: string,
    id: string,
    expiredScheduleId: string,
    expiredSchedule:  {
      __typename: "ExpiredSchedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteExpiredReservationSubscriptionVariables = {
  filter?: ModelSubscriptionExpiredReservationFilterInput | null,
  shopId?: string | null,
};

export type OnDeleteExpiredReservationSubscription = {
  onDeleteExpiredReservation?:  {
    __typename: "ExpiredReservation",
    shopId: string,
    id: string,
    expiredScheduleId: string,
    expiredSchedule:  {
      __typename: "ExpiredSchedule",
      shopId: string,
      id: string,
      lessonId: string,
      lesson:  {
        __typename: "Lesson",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        style: LessonStyle,
        canSingle: boolean,
        singlePrice?: number | null,
        hasTrial: boolean,
        trialPrice?: number | null,
        hasTicket: boolean,
        canPublish: boolean,
        studio_capacity?: number | null,
        online_capacity?: number | null,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      start: string,
      end: string,
      zoomMeetingId?: string | null,
      link?: string | null,
      pass?: string | null,
      isPublished: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      acceptableStudioNumber?: number | null,
      acceptableOnlineNumber?: number | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    customerId: string,
    customer:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      stripeId?: string | null,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      purchasedTickets?:  Array< {
        __typename: "PurchasedTicket",
        shopId: string,
        id: string,
        masterTicketId: string,
        paymentIntentId?: string | null,
        stripeProductId?: string | null,
        stripePriceId?: string | null,
        name: string,
        description?: string | null,
        maxCount: number,
        currentCount: number,
        price: number,
        type: TicketType,
        hasDailyLimit?: boolean | null,
        limitPerDaily?: number | null,
        hasOnlineDailyLimit?: boolean | null,
        onlineLimitPerDaily?: number | null,
        hasWeeklyLimit?: boolean | null,
        limitPerWeekly?: number | null,
        hasOnlineWeeklyLimit?: boolean | null,
        onlineLimitPerWeekly?: number | null,
        lastUsedAt?: string | null,
        lastOnlineUsedAt?: string | null,
        purchasedAt?: string | null,
        expireAt?: string | null,
        isAvailable?: boolean | null,
      } | null > | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      expiredReservations?:  {
        __typename: "ModelExpiredReservationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    lessonStyle?: LessonStyle | null,
    status?: ReservationStatus | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isTrial?: boolean | null,
    consumedTicketId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    stripeId?: string | null,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    purchasedTickets?:  Array< {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null > | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    stripeId?: string | null,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    purchasedTickets?:  Array< {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null > | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    stripeId?: string | null,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    purchasedTickets?:  Array< {
      __typename: "PurchasedTicket",
      shopId: string,
      id: string,
      masterTicketId: string,
      paymentIntentId?: string | null,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      currentCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      lastUsedAt?: string | null,
      lastOnlineUsedAt?: string | null,
      purchasedAt?: string | null,
      expireAt?: string | null,
      isAvailable?: boolean | null,
      histories?:  Array< {
        __typename: "TicketHistory",
        title?: string | null,
        operation?: TicketOperation | null,
        createdAt?: number | null,
      } | null > | null,
    } | null > | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        scheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        expiredAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expiredReservations?:  {
      __typename: "ModelExpiredReservationConnection",
      items:  Array< {
        __typename: "ExpiredReservation",
        shopId: string,
        id: string,
        expiredScheduleId: string,
        customerId: string,
        lessonStyle?: LessonStyle | null,
        status?: ReservationStatus | null,
        transactionId: string,
        isTrial?: boolean | null,
        consumedTicketId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMasterTicketSubscriptionVariables = {
  filter?: ModelSubscriptionMasterTicketFilterInput | null,
  shopId?: string | null,
};

export type OnCreateMasterTicketSubscription = {
  onCreateMasterTicket?:  {
    __typename: "MasterTicket",
    id: string,
    shopId: string,
    stripeProductId?: string | null,
    stripePriceId?: string | null,
    name: string,
    description?: string | null,
    maxCount: number,
    price: number,
    type: TicketType,
    deadline?:  {
      __typename: "Deadline",
      type: DeadlineType,
      num: number,
    } | null,
    hasDailyLimit?: boolean | null,
    limitPerDaily?: number | null,
    hasOnlineDailyLimit?: boolean | null,
    onlineLimitPerDaily?: number | null,
    hasWeeklyLimit?: boolean | null,
    limitPerWeekly?: number | null,
    hasOnlineWeeklyLimit?: boolean | null,
    onlineLimitPerWeekly?: number | null,
    isPrivate?: boolean | null,
    privateScopedCustomerId?: Array< string | null > | null,
    lessons?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    isAvailable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMasterTicketSubscriptionVariables = {
  filter?: ModelSubscriptionMasterTicketFilterInput | null,
  shopId?: string | null,
};

export type OnUpdateMasterTicketSubscription = {
  onUpdateMasterTicket?:  {
    __typename: "MasterTicket",
    id: string,
    shopId: string,
    stripeProductId?: string | null,
    stripePriceId?: string | null,
    name: string,
    description?: string | null,
    maxCount: number,
    price: number,
    type: TicketType,
    deadline?:  {
      __typename: "Deadline",
      type: DeadlineType,
      num: number,
    } | null,
    hasDailyLimit?: boolean | null,
    limitPerDaily?: number | null,
    hasOnlineDailyLimit?: boolean | null,
    onlineLimitPerDaily?: number | null,
    hasWeeklyLimit?: boolean | null,
    limitPerWeekly?: number | null,
    hasOnlineWeeklyLimit?: boolean | null,
    onlineLimitPerWeekly?: number | null,
    isPrivate?: boolean | null,
    privateScopedCustomerId?: Array< string | null > | null,
    lessons?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    isAvailable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMasterTicketSubscriptionVariables = {
  filter?: ModelSubscriptionMasterTicketFilterInput | null,
  shopId?: string | null,
};

export type OnDeleteMasterTicketSubscription = {
  onDeleteMasterTicket?:  {
    __typename: "MasterTicket",
    id: string,
    shopId: string,
    stripeProductId?: string | null,
    stripePriceId?: string | null,
    name: string,
    description?: string | null,
    maxCount: number,
    price: number,
    type: TicketType,
    deadline?:  {
      __typename: "Deadline",
      type: DeadlineType,
      num: number,
    } | null,
    hasDailyLimit?: boolean | null,
    limitPerDaily?: number | null,
    hasOnlineDailyLimit?: boolean | null,
    onlineLimitPerDaily?: number | null,
    hasWeeklyLimit?: boolean | null,
    limitPerWeekly?: number | null,
    hasOnlineWeeklyLimit?: boolean | null,
    onlineLimitPerWeekly?: number | null,
    isPrivate?: boolean | null,
    privateScopedCustomerId?: Array< string | null > | null,
    lessons?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    isAvailable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLessonSubscriptionVariables = {
  filter?: ModelSubscriptionLessonFilterInput | null,
  shopId?: string | null,
};

export type OnCreateLessonSubscription = {
  onCreateLesson?:  {
    __typename: "Lesson",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    style: LessonStyle,
    canSingle: boolean,
    singlePrice?: number | null,
    hasTrial: boolean,
    trialPrice?: number | null,
    hasTicket: boolean,
    masterTickets?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    canPublish: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    requiredTime?:  {
      __typename: "LessonRequiredTime",
      timeUnit: TimeUnit,
      value: number,
    } | null,
    staff:  {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    },
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLessonSubscriptionVariables = {
  filter?: ModelSubscriptionLessonFilterInput | null,
  shopId?: string | null,
};

export type OnUpdateLessonSubscription = {
  onUpdateLesson?:  {
    __typename: "Lesson",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    style: LessonStyle,
    canSingle: boolean,
    singlePrice?: number | null,
    hasTrial: boolean,
    trialPrice?: number | null,
    hasTicket: boolean,
    masterTickets?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    canPublish: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    requiredTime?:  {
      __typename: "LessonRequiredTime",
      timeUnit: TimeUnit,
      value: number,
    } | null,
    staff:  {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    },
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLessonSubscriptionVariables = {
  filter?: ModelSubscriptionLessonFilterInput | null,
  shopId?: string | null,
};

export type OnDeleteLessonSubscription = {
  onDeleteLesson?:  {
    __typename: "Lesson",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    style: LessonStyle,
    canSingle: boolean,
    singlePrice?: number | null,
    hasTrial: boolean,
    trialPrice?: number | null,
    hasTicket: boolean,
    masterTickets?:  {
      __typename: "ModelLessonMasterTicketsConnection",
      items:  Array< {
        __typename: "LessonMasterTickets",
        id: string,
        lessonId: string,
        masterTicketId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    canPublish: boolean,
    studio_capacity?: number | null,
    online_capacity?: number | null,
    requiredTime?:  {
      __typename: "LessonRequiredTime",
      timeUnit: TimeUnit,
      value: number,
    } | null,
    staff:  {
      __typename: "Staff",
      shopId: string,
      id: string,
      name: string,
    },
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLessonMasterTicketsSubscriptionVariables = {
  filter?: ModelSubscriptionLessonMasterTicketsFilterInput | null,
  owner?: string | null,
};

export type OnCreateLessonMasterTicketsSubscription = {
  onCreateLessonMasterTickets?:  {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateLessonMasterTicketsSubscriptionVariables = {
  filter?: ModelSubscriptionLessonMasterTicketsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateLessonMasterTicketsSubscription = {
  onUpdateLessonMasterTickets?:  {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteLessonMasterTicketsSubscriptionVariables = {
  filter?: ModelSubscriptionLessonMasterTicketsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteLessonMasterTicketsSubscription = {
  onDeleteLessonMasterTickets?:  {
    __typename: "LessonMasterTickets",
    id: string,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      style: LessonStyle,
      canSingle: boolean,
      singlePrice?: number | null,
      hasTrial: boolean,
      trialPrice?: number | null,
      hasTicket: boolean,
      masterTickets?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      canPublish: boolean,
      studio_capacity?: number | null,
      online_capacity?: number | null,
      requiredTime?:  {
        __typename: "LessonRequiredTime",
        timeUnit: TimeUnit,
        value: number,
      } | null,
      staff:  {
        __typename: "Staff",
        shopId: string,
        id: string,
        name: string,
      },
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    masterTicketId: string,
    masterTicket?:  {
      __typename: "MasterTicket",
      id: string,
      shopId: string,
      stripeProductId?: string | null,
      stripePriceId?: string | null,
      name: string,
      description?: string | null,
      maxCount: number,
      price: number,
      type: TicketType,
      deadline?:  {
        __typename: "Deadline",
        type: DeadlineType,
        num: number,
      } | null,
      hasDailyLimit?: boolean | null,
      limitPerDaily?: number | null,
      hasOnlineDailyLimit?: boolean | null,
      onlineLimitPerDaily?: number | null,
      hasWeeklyLimit?: boolean | null,
      limitPerWeekly?: number | null,
      hasOnlineWeeklyLimit?: boolean | null,
      onlineLimitPerWeekly?: number | null,
      isPrivate?: boolean | null,
      privateScopedCustomerId?: Array< string | null > | null,
      lessons?:  {
        __typename: "ModelLessonMasterTicketsConnection",
        nextToken?: string | null,
      } | null,
      isAvailable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
