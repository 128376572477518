import liff from "@line/liff";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ApplicationStoreProvider } from "./provider/ApplicationStoreProvider";


liff
  .init({ liffId: "1656981168-EjLNwYJP" })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <ApplicationStoreProvider>
          <App />
        </ApplicationStoreProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch((e) => {
    alert("このアプリケーションはラインブラウザ専用です。");
  });
