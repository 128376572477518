import { formatDate, formatRange } from "@fullcalendar/react";
import { Box, Typography, Divider, Card, Stack } from "@mui/material";
import React from "react";
import { PurchasedTicket, TicketType } from "../../DAL/API";

type Props = {
  ticket: PurchasedTicket;
  type: string;
};
export const ComfirmPurchasedTicketPanel: React.FC<Props> = React.memo(
  ({ ticket, type }) => {
    return (
      <Box>
        <Box>
          <Typography fontWeight={600}>
            {type === "COUPON" ? "使用する回数券" : "加入している月謝"}
          </Typography>
          <Divider sx={{ margin: "6px 0 12px" }} />
        </Box>
        <Card variant="outlined" elevation={0}>
          <Stack spacing={1} p={1.5}>
            <Stack spacing={0.5}>
              <Typography variant="h4" fontWeight="bold">
                {ticket.name}
              </Typography>
              <Divider />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>ご利用可能な回数</Typography>
              <Typography>{ticket?.currentCount}回</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>利用期限</Typography>
              <Typography>
                {formatDate(
                  new Date(Number(ticket?.expireAt)).toISOString().toString(),
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    locale: "ja",
                  }
                )}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Box>
    );
  }
);
