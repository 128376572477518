import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Box, Button, CircularProgress, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import liff from "@line/liff";
import { Circle } from "@mui/icons-material";

export const CardFrom = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isWaiting, setWaiting] = useState<boolean>(false);
  const { store, dispatch } = useApplicationStore();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);
    await stripe
      .confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
      })
      .then((result) => {
        if("error" in result){
          setLoading(false);
        }else{
          setWaiting(true)
          dispatch({
            type: "START_FETCH_STRIPE_CUSTOMER",
            payload: {
              input: {
                shopId: store.customer?.serviceAccount.shopId!,
                customerId: store.customer?.serviceAccount?.id!,
              },
              lineAccessToken: liff.getAccessToken(),
            },
          });
          setLoading(false);
        }
        //update customer default source
      })
      .catch((error) => {
        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          setErrorMessage(error.message);
          setLoading(false);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      });
  };

  return !isWaiting ? (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <PaymentElement />
        <LoadingButton
          disabled={!stripe}
          loading={isLoading}
          variant="contained"
          disableElevation
          type="submit"
        >
          このカードを使用する
        </LoadingButton>
        {errorMessage && (
          <Typography variant="error" textAlign="center">
            {errorMessage}
          </Typography>
        )}
      </Stack>
    </form>
  ) : (
    <Paper>
      <Stack p={.7} direction="row" justifyContent="center"><CircularProgress size={14}/></Stack>
    </Paper>
  );
};
