import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { useCallback, useEffect, useState } from "react";
import { LessonStyle } from "../../DAL/API";
import {
  TopPageOperation,
  useTopPageContext,
} from "../../provider/TopPageContextProvider";

const TopPageScheduleSortPanel = React.memo(() => {
  const { topPageState, topPageDispatch } = useTopPageContext();
  const [style, setStyle] = useState<LessonStyle>(LessonStyle.STUDIO);
  const handleSelect = (
    event: React.MouseEvent<HTMLElement>,
    newStyle: LessonStyle | null
  ) => {
    if (newStyle !== null) {
      setStyle(newStyle!);
      topPageDispatch({
        type: TopPageOperation.SORT_SCHEDULE,
        payload: newStyle!,
      });
    }
  };

  useEffect(() => {
    setStyle(topPageState.sortedLessonType);
  }, []);
  return (
    <Stack alignItems="center">
      <ToggleButtonGroup
        color="primary"
        value={style}
        onChange={handleSelect}
        exclusive
      >
        <ToggleButton value={LessonStyle.STUDIO}>スタジオ</ToggleButton>
        <ToggleButton value={LessonStyle.ONLINE}>オンライン</ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
});

export default TopPageScheduleSortPanel;
