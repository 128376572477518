import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography, Paper, Box } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { CreateTicketPaymentIntentInput, MasterTicket } from "../../DAL/API";
import { UserAccount } from "../../DAL/schema";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { usePaymentComfirmationContext } from "../../provider/PaymentComfirmationContextProvider";
import { LoadingCard } from "../parts/card";
import { PaymentMethodComfirmationPanel } from "./PaymentMethodComfirmationPanel";
import { TicketInfoComfirmationPanel } from "./TicketInfoComfirmationPanel";
import { UserInfoComfirmationPanel } from "./UserInfoComfirmationPanel";
import * as yup from "yup";
import liff from "@line/liff";

type Props = {
  ticket: MasterTicket | undefined | null;
  customer: UserAccount | undefined | null;
  stripePubKey: string | undefined | null;
};

const formSchema = yup.object({
  shopId: yup.string().required(),
  systemCustomerId: yup.string().required(),
  stripeCustomerId: yup.string().required(),
  priceId: yup.string().required("決済連動の準備が完了していません。"),
  quantity: yup.number().required(),
  masterTicketId: yup.string().required(),
  masterTicketName: yup.string().required(),
  paymentMethodId: yup.string().required("使用するクレジットカードを選択してください。"),
});

export const ComfirmTicketPanel: React.FC<Props> = React.memo(
  ({ ticket, customer, stripePubKey }) => {
    const { dispatch } = useApplicationStore();
    const { shopId, masterTicketId } = useParams();
    const navigate = useNavigate();
    const { comfirmationValue } = usePaymentComfirmationContext();

    const input: CreateTicketPaymentIntentInput = {
      shopId: customer?.serviceAccount.shopId!,
      systemCustomerId: customer?.serviceAccount.id!,
      stripeCustomerId: customer?.serviceAccount.stripeId!,
      priceId: ticket?.stripePriceId!,
      quantity: 1,
      masterTicketId: ticket?.id!,
      masterTicketName: ticket?.name!,
      paymentMethodId: "",
    };

    const {
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<CreateTicketPaymentIntentInput>({
      resolver: yupResolver(formSchema),
      defaultValues: input,
    });

    const onSubmit: SubmitHandler<CreateTicketPaymentIntentInput> = (data) => {
      dispatch({
        type: 'START_COUPON_TRANSACTION',
        payload: {
          input: data,
          lineAccessToken: liff.getAccessToken()
        },
      });
      navigate(`/s/${shopId}/ticket/${masterTicketId}/purchased`)
    };
  

    useEffect(() => {  
      if(comfirmationValue.paymentMethodId !== null){
        setValue("paymentMethodId",comfirmationValue.paymentMethodId)
      }else{
        setValue("paymentMethodId","")
      }
    }, [comfirmationValue]);
    
    return (
      <Box>
        <Paper
          variant="outlined"
          sx={{ margin: "12px 0 32px", padding: "12px" }}
        >
          <Stack spacing={3} px={1} py={1.5}>
            {ticket ? (
              <TicketInfoComfirmationPanel ticket={ticket} />
            ) : (
              <LoadingCard />
            )}
            {customer ? (
              <UserInfoComfirmationPanel user={customer?.serviceAccount} />
            ) : (
              <LoadingCard />
            )}
            {customer !== null && stripePubKey !== null ? (
              <PaymentMethodComfirmationPanel
                user={customer!}
                stripePubKey={stripePubKey}
                error={"paymentMethodId" in errors}
                helperText={errors.paymentMethodId?.message}
              />
            ) : (
              <LoadingCard />
            )}
          </Stack>
        </Paper>
        <Stack spacing={1.7}>
          <LoadingButton
            variant="contained"
            disableElevation
            onClick={handleSubmit(onSubmit)}
            color="primary"
          >
            確定して購入する
          </LoadingButton>
          
          {errors ? (
            <Typography variant="error" textAlign="center">
              {errors.paymentMethodId?.message}
            </Typography>
          ) : null}
        </Stack>
      </Box>
    );
  }
);
