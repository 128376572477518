import { Stack, Box, Typography, Divider, Paper } from "@mui/material";
import React from "react";
import { DeadlineType, MasterTicket } from "../../DAL/API";
type Props = {
  ticket: MasterTicket | undefined | null;
};
export const TicketInfoComfirmationPanel = React.memo<Props>(({ ticket }) => {
  return (
    <Stack spacing={1}>
      <Box>
        <Typography variant="h5" fontWeight={600}>
          回数券購入確認
        </Typography>
        <Divider sx={{ margin: "6px 0 0" }} />
      </Box>
      <Paper variant="outlined">
        <Stack p={1.5} spacing={1}>
          <Box>
            <Typography variant="h5" fontWeight={600}>
              {ticket?.name}
            </Typography>
            <Divider sx={{ margin: "6px 0 0" }} />
          </Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">金額:</Typography>
            <Typography variant="h5">
              {ticket?.price.toLocaleString()}円
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">一ヵ月の利用回数:</Typography>
            <Typography variant="h5">{ticket?.maxCount}回</Typography>
          </Stack>
          {ticket?.hasDailyLimit ? (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">1日あたりの利用可能回数:</Typography>
              <Typography variant="h5">{ticket?.limitPerDaily}回</Typography>
            </Stack>
          ) : null}
          {ticket?.hasWeeklyLimit ? (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">週あたりの利用可能回数:</Typography>
              <Typography variant="h5">{ticket?.limitPerWeekly}回</Typography>
            </Stack>
          ) : null}

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">有効期限:</Typography>
            <Typography variant="h5">{`${ticket?.deadline?.num}${
              ticket?.deadline?.type === DeadlineType.MONTHLY
                ? "ヵ月"
                : ticket?.deadline?.type === DeadlineType.WEEKLY
                ? "週間"
                : "日"
            }`}</Typography>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
});
